import React from 'react'
import * as Validator from 'yup'
import { Modal, TextField as TextFieldBase } from '@vega/components'
import { Formik, Form } from 'formik'
import { s, styled } from '@vega/styled/v2'
import { formatDateString } from 'utils/formatters'
import { ReactComponent as XBase } from '@vega/components/src/assets/images/x.svg'
import WarehouseStatus from 'features/warehouse/WarehouseStatus'
import { isNilOrEmpty } from '@neo/ramda-extra'
import { undoPendingState } from 'modules/warehouse'
import { useSelector, useDispatch } from 'react-redux'
import { fetchUsers, selectAllUsers } from 'modules/user'

const Container = styled.div(
  s('p-6 bg-white', {
    borderRadius: 12,
    boxShadow: '0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)',
    maxWidth: 703,
  })
)

const Header = styled.div(s('flex justify-between'))

const Title = styled.div(
  s('text-xl text-grey-900 font-semibold', {
    letterSpacing: '-0.01em',
    lineHeight: 1.2,
    marginBottom: 28,
  })
)

const X = styled(XBase)(s('text-primary', { cursor: 'pointer' }))

const TextContainer = styled.div(s('mb-4 bg-grey-100', { padding: 6 }))
const Text = styled.div(
  s('tet-sm text-grey-900 font-normal', { letterSpacing: '0.01em', lineHeight: 1.5 })
)

const BoldText = styled.span(s('font-semibold'))
const TextField = styled(TextFieldBase)(s('mb-8', { minWidth: 430 }))
const Button = styled.button(
  s(
    'flex justify-center w-full border-0 bg-secondary text-primary text-base font-semibold',
    {
      borderRadius: 6,
      cursor: 'pointer',
      letterSpacing: '0.01em',
      lineHeight: 1.5,
      paddingBottom: 10,
      paddingTop: 10,
    }
  ),
  ({ isDisabled }) => isDisabled && s('bg-grey-200 text-grey-600')
)

const { object, string } = Validator

const validationSchema = (confirmation) =>
  object({
    confirmation: string().oneOf([confirmation]).required('Confirmation is required'),
  })

const UndoPendingWarehouseActionModal = ({ isOpen, setIsOpen, warehouse }) => {
  const dispatch = useDispatch()
  const users = useSelector(selectAllUsers) ?? []
  const close = () => setIsOpen(false)

  const requester =
    users.find(
      (user) => user.id === warehouse.pendingState?.changeInitiatedBy
    )?.fullName || '-'

  React.useEffect(() => {
    dispatch(fetchUsers({searchParams: {}}))
  }, [dispatch])

  const handleSubmit = async () => {
    close()
    await dispatch(undoPendingState({ warehouseId: warehouse._id }))
  }

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <Container>
        <Header>
          <Title>Undo pending warehouse action: {warehouse.name}</Title>
          <X onClick={close} />
        </Header>

        <div style={s('flex items-center mb-4')}>
          <WarehouseStatus warehouse={warehouse} />
        </div>

        <TextContainer>
          <Text>
            {`Pending status of ${warehouse.pendingState?.changeTo} `}
            {`requested by ${requester} `}
            {`on ${formatDateString(warehouse.pendingState?.changeInitiatedAt)}`}
          </Text>

          <br />

          <Text>
            Undo this pending action will leave the warehouse with a
            status of {warehouse.currentState.state}.
          </Text>

          <br />

          <Text>
            Are you sure you want to undo the pending status
            of this warehouse?
          </Text>
        </TextContainer>

        <Formik
          initialValues={{ confirmation: '' }}
          validationSchema={validationSchema(warehouse.name)}
          onSubmit={handleSubmit}
        >
          {({ isValid, values }) => {
            const shouldDisableSubmitBtn =
              !isValid || isNilOrEmpty(values?.confirmation)

            return (
              <Form>
                <Text style={s('mb-1')}>
                  Confirm by typing <BoldText>the warehouse name</BoldText> below
                </Text>
                <TextField name="confirmation" placeholder={warehouse.name} />

                <Button
                  type="submit"
                  style={s('mb-6')}
                  isDisabled={shouldDisableSubmitBtn}
                >
                  Undo pending warehouse action
                </Button>
              </Form>
            )
          }}
          </Formik>
      </Container>
    </Modal>
  )
}

export default UndoPendingWarehouseActionModal
