import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { s } from '@vega/styled/v2'

import Overview from './Overview'
import PoolCut from './PoolCut'
import Parameters from './Parameters'
import { ActivityLog } from './Overview/activityLog'

import WarehouseTab from './WarehouseTab'

const WarehouseTabs = ({ warehouse }) => {
  return (
    <div>
      <div
        style={s('bg-white rounded-lg p-4 flex', {
          columnGap: '1rem',
          width: 'fit-content',
        })}
      >
        <WarehouseTab id={warehouse._id} subroute={'overview'} title={'Overview'} />
        <WarehouseTab
          id={warehouse._id}
          subroute={'funder-overview'}
          title={'Funders'}
        />
        <WarehouseTab id={warehouse._id} subroute={'parameters'} title={'Parameters'} />
        <WarehouseTab
          id={warehouse._id}
          subroute={'activity-log'}
          title={'Activity Log'}
        />
      </div>

      <Switch>
        <Route exact path="/warehouses/:id">
          <Overview warehouse={warehouse} />
        </Route>

        <Route exact path="/warehouses/:id/overview">
          <Overview warehouse={warehouse} />
        </Route>

        <Route exact path="/warehouses/:id/funder-overview">
          <PoolCut warehouse={warehouse} />
        </Route>

        <Route exact path="/warehouses/:id/parameters">
          <Parameters warehouse={warehouse} />
        </Route>

        <Route exact path="/warehouses/:id/activity-log">
          <ActivityLog warehouseId={warehouse._id} />
        </Route>
      </Switch>
    </div>
  )
}

export default WarehouseTabs
