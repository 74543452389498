import React from 'react'

import { Formik, Form } from 'formik'
import { Col, Row } from '@vega/styled'
import { s } from '@vega/styled/v2'
import { Button, SelectField } from '@vega/components'
import {
  ruleTypes,
  loanSizeByGeographyInputs,
  numericValueInputs,
  booleanValueInputs,
  verificationValueInputs,
  Label,
} from './common'

export const EligibilityCriteriaForm = ({
  closeForm,
  onSubmit,
  isCreateParametersPending,
  eligibilityVerifications,
}) => {
  const initialValues = {
    ruleType: 'loanSizeByGeographyValue',
    inputs: {
      geography: 'metro',
      direction: 'max',
      loanSize: 0,
      value: 0,
      numberField: 'borrowerExposure',
      booleanField: 'fullDocumentation',
      expectedValue: true,
    },
  }

  const formatValuesThenSubmit = (data) => {
    if (data.ruleType === 'loanSizeByGeographyValue') {
      onSubmit({
        ruleType: data.ruleType,
        inputs: {
          geography: data.inputs.geography,
          direction: data.inputs.direction,
          loanSize: data.inputs.loanSize,
        },
      })
    }

    if (data.ruleType === 'numericValue') {
      onSubmit({
        ruleType: data.ruleType,
        inputs: {
          numberField: data.inputs.numberField,
          direction: data.inputs.direction,
          value: data.inputs.value,
        },
      })
    }

    if (data.ruleType === 'booleanValue') {
      onSubmit({
        ruleType: data.ruleType,
        inputs: {
          booleanField: data.inputs.booleanField,
          expectedValue: data.inputs.expectedValue,
        },
      })
    }

    if (data.ruleType === 'verificationValue') {
      onSubmit({
        ruleType: data.ruleType,
        inputs: {
          verificationId: data.inputs.verificationId,
          expectedValue: data.inputs.expectedValue,
        },
      })
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={formatValuesThenSubmit}>
      {({ values, handleChange, handleBlur, setFieldValue, ...props }) => (
        <Form onSubmit={props.handleSubmit}>
          <h4>Add criteria</h4>

          <Row style={s('mb-4')}>
            <Col span={8}>
              <Label>Criteria type</Label>
              <SelectField name="ruleType" options={ruleTypes} />
            </Col>
          </Row>

          {values.ruleType === 'loanSizeByGeographyValue' && loanSizeByGeographyInputs}
          {values.ruleType === 'numericValue' && numericValueInputs}
          {values.ruleType === 'booleanValue' && booleanValueInputs}
          {values.ruleType === 'verificationValue' &&
            verificationValueInputs(eligibilityVerifications, values)}

          <div style={s('flex', { flexDirection: 'row-reverse' })}>
            <Button type="submit" style={s('ml-2')} loading={isCreateParametersPending}>
              Save
            </Button>

            <Button variant="outlined" onClick={() => closeForm()}>
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
