import { configureStore } from '@reduxjs/toolkit'
import { asyncStateReducer } from '@vega/redux.async'
import { uiReducer } from 'modules/ui'
import { profileReducer } from '@vega/redux.profile'
import { warehouseReducer } from 'modules/warehouse'
import { funderReducer } from 'modules/funder'
import { activityLogsReducer } from 'modules/activityLog'
import { eligibilityVerificationReducer } from 'modules/eligibilityVerifications'
import { warehouseTransfersReducer } from 'modules/warehouseTransfers'
import { userReducer } from 'modules/user'

export default configureStore({
  reducer: {
    asyncState: asyncStateReducer,
    profile: profileReducer,
    ui: uiReducer,
    warehouse: warehouseReducer,
    funder: funderReducer,
    activityLog: activityLogsReducer,
    eligibilityVerification: eligibilityVerificationReducer,
    warehouseTransfers: warehouseTransfersReducer,
    user: userReducer,
  },
})
