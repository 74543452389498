/* eslint-disable complexity */
import React from 'react'

import { s } from '@vega/styled/v2'

const TransferStatus = ({ status }) => {
  let statusColor = ''

  switch (status) {
    case 'SETTLED/COMPLETE':
      statusColor = 'bg-secondary'
      break
    case 'IN_FLIGHT':
      statusColor = 'bg-yellow'
      break
    case 'PENDING_APPROVAL':
      statusColor = 'bg-grey-300'
      break
    case 'APPROVED':
      statusColor = 'bg-green-100'
      break
    case 'CANCELLED':
      statusColor = 'bg-accent-red'
      break
    default:
      statusColor = 'bg-accent-pink'
      break
  }

  return (
    <div style={s('flex items-center')}>
      <span
        style={s(
          `rounded-2xl px-3 py-1 h-2 flex items-center justify-center font-semibold ${statusColor}`
        )}
      >
        {status}
      </span>
    </div>
  )
}

export default TransferStatus
