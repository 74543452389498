import React from 'react'
import { forEach, prop } from '@neo/ramda-extra'
import { s } from '@vega/styled'
import { Pie } from '@visx/shape'
import { Text } from '@visx/text'
import PropTypes from 'prop-types'
import { Group } from '@visx/group'
import { scaleOrdinal } from '@visx/scale'

const { number, string } = PropTypes

const pickPropFromObjectArray = (keyToBePicked, objArray = []) => {
  const newObjArray = []

  forEach((obj) => {
    const picked = prop(keyToBePicked, obj)
    newObjArray.push(picked)
  }, objArray)

  return newObjArray
}

const calculatePieShapeValues = (width, height, margin) => {
  const innerWidth = width - margin.left - margin.right
  const innerHeight = height - margin.top - margin.bottom
  const centerY = innerHeight
  const centerX = innerWidth / 2
  const radius = Math.min(innerWidth, innerHeight) / 2

  const top = centerY + margin.top
  const left = centerX + margin.left

  const groupPositioning = { top, left }

  return { innerWidth, innerHeight, centerY, centerX, radius, groupPositioning }
}

const getArcColorByLabel = (data, arcColors) =>
  scaleOrdinal({
    domain: data.map((l) => l.label),
    range: arcColors,
  })

const frequencySelector = (d) => d.frequency
const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 }

function GaugeChart({
  width,
  height,
  amount,
  amountString,
  label,
  margin = defaultMargin,
}) {
  const data = [
    { label: 'amount', color: '#490B2D', frequency: amount },
    { label: 'remaining', color: '#490B2D30', frequency: (100 - amount) }
  ]

  const arcColors = pickPropFromObjectArray('color', data)

  const { groupPositioning } = calculatePieShapeValues(
    width,
    height,
    margin
  )

  const { top, left } = groupPositioning

  const pieSortValues = (a, b) => b - a

  return (
    <div style={s('relative inline-block')}>
      <svg width={width} height={height}>
        <Group top={top} left={left}>
          <Pie
            data={data}
            pieValue={frequencySelector}
            pieSortValues={pieSortValues}
            outerRadius={width / 2.5}
            innerRadius={width / 2}
            startAngle={-(Math.PI / 2)}
            endAngle={Math.PI / 2}
            cornerRadius={5}
          >
            {(pie) => {
              return pie.arcs.map((arc, index) => {
                const { label } = arc.data
                const arcPath = pie.path(arc)
                const arcFill = getArcColorByLabel(data, arcColors)(label)

                return (
                  <g key={`arc-${label}-${index}`}>
                    <path d={arcPath} fill={arcFill} />
                  </g>
                )
              })
            }}
          </Pie>

          <Text
            textAnchor='middle'
            fontWeight={500}
            fontSize='2rem'
            dy='-1.5rem'
          >
            {amountString}
          </Text>

          <Text
            textAnchor='middle'
            fontWeight={300}
          >
            {label}
          </Text>
        </Group>
      </svg>
    </div>
  )
}

GaugeChart.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  amount: number.isRequired,
  amountString: string.isRequired,
  label: string.isRequired,
  margin: PropTypes.shape({
    top: number,
    right: number,
    bottom: number,
    left: number,
  }),
}

export { GaugeChart }
