/* eslint-disable guard-for-in */
import React from 'react'
import { s } from '@vega/styled/v2'
import { Formik, Form } from 'formik'
import { Modal, Button, NumberField } from '@vega/components'
import PoolParameterFilters from './PoolParameterFilters'
import { Col, Row } from '@vega/styled'
import { Label, Container, Header, Title, X } from '../styles'
import { formatStringToNumber } from 'utils/formatters'

const poolParameterNames = {
  averageLoanSize: {
    label: 'Average Loan Size',
    prefix: '$',
    suffix: '',
    thousandSeparator: true,
  },
  percentageOfLoans: {
    label: 'Percentage of Loans',
    prefix: '',
    suffix: '%',
    thousandSeparator: false,
  },
}

const EditPoolParameterModal = ({ isOpen, setIsOpen, poolParameter, handleSubmit }) => {
  const close = () => setIsOpen(false)
  const handleSubmitAndClose = (data) => {
    data.lowerLimit =
      typeof data.lowerLimit === 'string'
        ? formatStringToNumber(data.lowerLimit)
        : data.lowerLimit
    data.upperLimit =
      typeof data.upperLimit === 'string'
        ? formatStringToNumber(data.upperLimit)
        : data.upperLimit

    data.filters.forEach((filter) => {
      if (typeof filter.filterInputs.value === 'string') {
        filter.filterInputs.value = formatStringToNumber(filter.filterInputs.value)
      }
    })

    handleSubmit(data)
    close()
  }

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <Container>
        <Header>
          <Title>Edit pool parameter</Title>
          <X onClick={close} />
        </Header>

        <Formik initialValues={poolParameter} onSubmit={handleSubmitAndClose}>
          {({ values, handleChange, handleBlur }) => (
            <Form>
              <h4>{poolParameterNames[poolParameter.numberField].label}</h4>
              <Row style={s('mb-4')}>
                <Col span={8}>
                  <Label>Lower Limit</Label>
                  <NumberField
                    name="lowerLimit"
                    placeholder="Lower limit"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lowerLimits}
                    prefix={poolParameterNames[poolParameter.numberField].prefix}
                    suffix={poolParameterNames[poolParameter.numberField].suffix}
                    thousandSeparator={
                      poolParameterNames[poolParameter.numberField].thousandSeparator
                    }
                  />
                </Col>

                <Col span={8}>
                  <Label>Upper Limit</Label>
                  <NumberField
                    name="upperLimit"
                    placeholder="Upper limit"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.upperLimit}
                    prefix={poolParameterNames[poolParameter.numberField].prefix}
                    suffix={poolParameterNames[poolParameter.numberField].suffix}
                    thousandSeparator={
                      poolParameterNames[poolParameter.numberField].thousandSeparator
                    }
                  />
                </Col>
              </Row>
              <PoolParameterFilters values={values} />
              <div style={s('flex', { flexDirection: 'row-reverse' })}>
                <Button type="submit" style={s('ml-2')} loading={false}>
                  Save
                </Button>

                <Button variant="outlined" onClick={() => close()}>
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </Modal>
  )
}

export default EditPoolParameterModal
