import React from 'react'
import { s } from '@vega/styled/v2'
import { formatCurrency } from 'utils/formatter'
import { GaugeChart } from 'components/GaugeChart'

const WarehouseCapacity = ({ warehouse }) => {
  const drawnDownPercentage =
    ((warehouse.warehouseCapacity - warehouse.remainingCapacity) /
      warehouse.warehouseCapacity) *
    100

  const drawnDownAmount = warehouse.warehouseCapacity - warehouse.remainingCapacity
  const capacityPercentage =
    (warehouse.remainingCapacity / warehouse.warehouseCapacity) * 100

  return (
    <div style={s('bg-white rounded-lg p-4 shadow-lg', { flexGrow: 1 })}>
      <div style={s('flex')}>
        <div style={{ flexGrow: 1 }}>
          <h3>Drawn Down</h3>
          <span>{formatCurrency(drawnDownAmount)}</span>
        </div>

        <div style={s('text-right')}>
          <h3>Warehouse size</h3>
          <span>{formatCurrency(warehouse.warehouseCapacity)}</span>
        </div>
      </div>

      <hr />

      <div style={s('text-center')}>
        <GaugeChart
          amount={capacityPercentage}
          amountString={`${Math.round(drawnDownPercentage)}%`}
          label="Drawn Down"
          width={300}
          height={170}
        />
      </div>
    </div>
  )
}

export default WarehouseCapacity
