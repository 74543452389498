import React from 'react'
import { Button } from '@vega/components'
import { ContentCard, Table } from '@vega/components/src/v2'
import { s, styled } from '@vega/styled/v2'
import { Row, Col } from '@vega/styled'
import { formatDateString } from 'utils/date'
import { formatCurrency } from 'utils/formatter'
import userGroup from '@vega/components/src/assets/images/tick.svg'
import { Columns } from './TableFormat'

const Title = styled.h3(s('p-0 m-0 font-semibold text-2xl'))

const Label = styled.div(
  s('text-sm font-normal', {
    color: '#515856',
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)

const Value = styled.div(
  s('text-base font-semibold', {
    color: '#282C2B',
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)

const CircleContainer = styled.div(
  s('bg-secondary justify-center flex', {
    width: 36,
    height: 36,
    borderRadius: 50,
  })
)

const Img = styled.img(
  s('ml-a mr-a block w-1/2 h-1/2 self-center ', {
    display: 'block',
  })
)

const LoansList = ({
  loanGroups,
  selectedLoans,
  toggleLoans
}) => {
  if (loanGroups.length === 0) {
    return (
      <ContentCard style={s('my-4 p-2 px-4')}>
        <h3>No eligibile loans to transfer</h3>
      </ContentCard>
    )
  }

  const toggleLoanIds = (loanGroup, selected) => {
    toggleLoans(loanGroup.loans.map((loan) => loan.loanId), !selected)
  }

  const columns = Columns()

  return loanGroups.map((loanGroup) => {
    const isSelected = loanGroup.loans.every((loan) => selectedLoans.has(loan.loanId))
    const totalLoansBalance = loanGroup.loans.reduce((acc, curr) => acc + curr.loanBalance, 0)

    const cardStyle = isSelected ? 'bg-green-lightest my-4 p-2 px-4' : 'my-4 p-2 px-4'

    return (
      <ContentCard key={loanGroup.id} style={s(cardStyle)}>
        <div style={s('flex justify-between my-2')}>

          <Title className="sohne" style={s('flex')}>
            { isSelected && (
              <CircleContainer style={s('mr-2')}>
                <Img src={userGroup} />
              </CircleContainer>
            )}
            Loan group: {loanGroup.loanGroupNumber}
          </Title>

          <Button
            onClick={() => { toggleLoanIds(loanGroup, isSelected) }}
            variant={isSelected ? 'outlined' : 'contained'}
          >
            { isSelected ? 'Remove from transfer' : 'Add to transfer' }
          </Button>
        </div>

        <Row>
          <Col span={4}>
            <div style={s('flex justify-between')}>
              <Label>Originated</Label>
              <Value>
                {formatDateString(loanGroup.originatedAt)}
              </Value>
            </div>

            <div style={s('flex justify-between')}>
              <Label>Balance</Label>
              <Value>{formatCurrency(totalLoansBalance)}</Value>
            </div>

            <div style={s('flex justify-between')}>
              <Label>LVR</Label>
              <Value>{Math.round(loanGroup.lvr)}%</Value>
            </div>
          </Col>

          <Col span={2} />

          <Col span={18}>
            <Table
              columns={columns}
              data={loanGroup.loans}
              containerStyle={s('rounded-lg flex-1')}
              style={s('pb-0 m-0 bg-grey-lightest rounded-lg')}
              tableHeaderRowStyles={s(
                'border-solid border-0 border-b-1 border-grey-200 bg-grey-lightest'
              )}
            />
          </Col>
        </Row>
      </ContentCard>
    )
  })
}

export default LoansList
