export const searchOptions = [
  {
    key: 'organisationName',
    label: 'Organisation',
    searchLabel: 'Search organisations',
  },
  {
    key: 'funderContact',
    label: 'Funder Contact',
    searchLabel: 'Search funders contacts',
  },
]

export const loanOptions = [
  {
    key: '',
    label: 'All',
  },
  {
    key: 'ACTIVE',
    label: 'Active',
  },
  {
    key: 'ARCHIVED',
    label: 'Archived',
  },
]
