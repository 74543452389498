import React from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { createWarehouse } from 'modules/warehouse'

import WarehouseForm from 'components/WarehouseForm'

const CreateWarehouse = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleSubmit = async (data) => {
    await dispatch(createWarehouse(data))
    history.push('/warehouses')
  }

  return (
    <WarehouseForm
      formTitle="Create a warehouse"
      initialValues={{
        name: '',
        tranches: [
          {
            funder: '',
            capacity: '',
            costOfFunds: '',
          }
        ]
      }}
      onSubmit={handleSubmit}
      submitTitle="Create"
      cancelUrl="/warehouses"
    />
  )
}

export default CreateWarehouse
