import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ContentCard, SectionTitle as SectionTitleBase } from '@vega/components/src/v2'
import { Loading } from '@vega/components'
import { s } from '@vega/styled/v2'

import {
  validatePoolParameters,
  selectIsValidatePoolParametersPending,
  selectIsFetchWarehousePending,
} from 'modules/warehouse'

import { poolParametersTypes } from '../Parameters/poolParameters/utils'
import ParameterBulletGraph from '../../common/ParameterBulletGraph'

export const PoolParametersGraph = ({ warehouse }) => {
  const dispatch = useDispatch()
  const [parameterBulletGraphValues, setParameterBulletGraphValues] = useState([])
  const isValidatingParemetersPending = useSelector(
    selectIsValidatePoolParametersPending
  )
  const isFetchingWarehouse = useSelector(selectIsFetchWarehousePending)

  const onValidatationParameters = useCallback(
    async (warehouse) => {
      const loanIds = warehouse?.loans?.flatMap((loanGroup) =>
        loanGroup?.loans?.map((loan) => loan?.loanId)
      )

      const currentPoolParemetersValidation = await dispatch(
        validatePoolParameters({
          warehouseId: warehouse?._id,
          loanIds,
        })
      )

      if (currentPoolParemetersValidation.payload.error) {
        setParameterBulletGraphValues({
          error: currentPoolParemetersValidation.payload.error,
        })
      } else
        setParameterBulletGraphValues(
          currentPoolParemetersValidation?.payload?.poolParameterSummaries?.map(
            (parameter) => {
              return {
                ...parameter,
                newValue: 0,
              }
            }
          )
        )
    },
    [dispatch]
  )

  useEffect(() => {
    if (warehouse) {
      onValidatationParameters(warehouse)
    }
  }, [dispatch, onValidatationParameters, warehouse])
  const isPoolParameterPercentage = (valueToCheck) => {
    return poolParametersTypes
      .filter((item) => item.value === valueToCheck)
      .some((item) => item.suffix === '%')
  }

  return (
    <div>
      <h2>Pool Parameters</h2>
      <ContentCard style={s('mb-4')}>
        <SectionTitleBase title="Pool Parameters Preview" style={s('mb-4')} />
        {isValidatingParemetersPending || isFetchingWarehouse ? (
          <div style={s('mt-12 mb-12')}>
            <Loading />
          </div>
        ) : (
          <div>
            {Array.isArray(parameterBulletGraphValues) ? (
              parameterBulletGraphValues?.length > 0 &&
              parameterBulletGraphValues?.map((parameter) => (
                <ParameterBulletGraph
                  key={parameter.label}
                  label={parameter.filtersDescription}
                  lowerLimit={
                    isPoolParameterPercentage(parameter.parameterTypeDescription)
                      ? 0
                      : parameter.lowerLimit
                  }
                  upperLimit={parameter.upperLimit}
                  currentValue={parameter.value}
                  newValue={parameter.newValue}
                  paremeterTypeDescripition={parameter.parameterTypeDescription}
                />
              ))
            ) : (
              <div style={s('', { color: '#F35A56' })}>
                Error: {parameterBulletGraphValues.error}
              </div>
            )}
          </div>
        )}
      </ContentCard>
    </div>
  )
}
