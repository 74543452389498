/* eslint-disable complexity */
import React, { useState } from 'react'
import { statusForWarehouse } from './utils'
import { useDispatch } from 'react-redux'
import {
  activateWarehouse,
  onHoldWarehouse,
  archiveWarehouse,
  setDefaultWarehouse,
} from 'modules/warehouse'
import { Link } from 'react-router-dom'
import Button from 'components/Button'
import { s } from '@vega/styled/v2'

import ApproveWarehouseActionModal from './ApproveWarehouseActionModal'
import UndoPendingWarehouseActionModal from './UndoPendingWarehouseActionModal'

const WarehouseActions = ({ warehouse }) => {
  const dispatch = useDispatch()
  const [isActionModalOpen, setIsActionModalOpen] = useState(false)
  const [isUndoPendingActionModalOpen, setIsUndoPendingActionModalOpen] = useState(false)
  const [isAction, setIsAction] = useState('')

  const id = warehouse._id
  const warehouseStatus = statusForWarehouse(warehouse)

  const activate = () => {
    dispatch(activateWarehouse({ warehouseId: id }))
  }

  const archive = () => {
    dispatch(archiveWarehouse({ warehouseId: id }))
  }

  const putOnHold = () => {
    dispatch(onHoldWarehouse({ warehouseId: id }))
  }

  const approveAction = (action = '') => {
    setIsActionModalOpen(true)
    setIsAction(action)
  }

  const undoPendingAction = () => {
    setIsUndoPendingActionModalOpen(true)
  }

  const setDefault = () => {
    dispatch(setDefaultWarehouse({ warehouseId: id }))
  }

  return (
    <>
      <ApproveWarehouseActionModal
        isOpen={isActionModalOpen}
        setIsOpen={setIsActionModalOpen}
        warehouse={warehouse}
        action={isAction}
      />

      <UndoPendingWarehouseActionModal
        isOpen={isUndoPendingActionModalOpen}
        setIsOpen={setIsUndoPendingActionModalOpen}
        warehouse={warehouse}
        action={isAction}
      />

      {warehouseStatus === 'DRAFT' && (
        <>
          <Link to={`/warehouses/${id}/edit`} style={s('no-decoration')}>
            <Button>Edit warehouse</Button>
          </Link>

          {warehouse?.pendingState && warehouse?.currentState?.state === 'DRAFT' ? (
            <Button onClick={() => approveAction('ACTIVE')}>Approve activation</Button>
          ) : (
            <Button onClick={activate}>Activate warehouse</Button>
          )}
        </>
      )}

      {warehouseStatus === 'ACTIVE' && (
        <>
          {!warehouse?.default.isDefault && (
            <>
              {warehouse?.default?.isPending ? (
                <Button onClick={() => approveAction('DEFAULT')}>
                  Approve Default
                </Button>
              ) : (
                <Button onClick={setDefault}>Set Default</Button>
              )}

              {warehouse?.pendingState?.changeTo === 'ARCHIVED' &&
              warehouse?.currentState?.state === 'ACTIVE' ? (
                <Button onClick={() => approveAction('ARCHIVED')}>
                  Approve Archive
                </Button>
              ) : (
                <Button onClick={archive}>Archive warehouse</Button>
              )}

              {warehouse?.pendingState?.changeTo === 'ON_HOLD' &&
              warehouse?.currentState?.state === 'ACTIVE' ? (
                <>
                  <Button onClick={() => approveAction('ON_HOLD')}>
                    Approve on hold
                  </Button>
                  <Button onClick={() => undoPendingAction()}>
                    Cancel on hold
                  </Button>
                </>
              ) : (
                <Button onClick={putOnHold}>Put on hold</Button>
              )}

              <Button>Securitise warehouse</Button>
            </>
          )}
        </>
      )}

      {warehouseStatus === 'ON_HOLD' && (
        <>
          <Link to={`/warehouses/${id}/edit`} style={s('no-decoration')}>
            <Button>Edit warehouse</Button>
          </Link>
          {warehouse?.pendingState?.changeTo === 'ACTIVE' &&
          warehouse?.currentState?.state === 'ON_HOLD' ? (
            <Button onClick={() => approveAction('ACTIVE')}>
              Approve Reactivation
            </Button>
          ) : (
            <Button onClick={activate}>Reactivate</Button>
          )}
          {warehouse?.pendingState?.changeTo === 'ARCHIVED' &&
          warehouse?.currentState?.state === 'ON_HOLD' ? (
            <Button onClick={() => approveAction('ARCHIVED')}>Approve Archive</Button>
          ) : (
            <Button onClick={archive}>Archive warehouse</Button>
          )}
        </>
      )}
    </>
  )
}

export default WarehouseActions
