import React, { useState, useEffect } from 'react'
import { Button } from '@vega/components'
import { s } from '@vega/styled/v2'
import { EligibilityCriteriaTable } from './eligibilityCriteria/EligibilityCriteriaTable'
import { PoolParametersTable } from './poolParameters/PoolParametersTable'
import { ParametersForm } from './poolParameters/AddPoolParameterForm'
import { EligibilityCriteriaForm } from './eligibilityCriteria/AddEligibilityCriteriaForm'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchVerifications,
  selectVerifications,
} from 'modules/eligibilityVerifications'
import {
  createPoolParameters,
  createEligibilityCriteria,
  selectIsCreatePoolParametersPending,
  selectIsCreateEligibilityCriteria,
} from 'modules/warehouse'
import { formatStringToNumber } from 'utils/formatters'

const initialShowAddForms = {
  poolParameter: false,
  eligibityCriteria: false,
}

/* eslint-disable complexity */
const Parameters = ({ warehouse }) => {
  const dispatch = useDispatch()
  const [showAddForms, setShowAddForms] = useState(initialShowAddForms)

  const eligibilityVerifications = useSelector(selectVerifications)

  useEffect(() => {
    dispatch(fetchVerifications())
  }, [dispatch])

  const isEditable = warehouse.currentState.state !== 'ACTIVE'

  const { poolParameters, eligibilityCriteria } = warehouse
  const isCreatePoolParametersPending = useSelector(selectIsCreatePoolParametersPending)

  const isCreateEligibilityCriteria = useSelector(selectIsCreateEligibilityCriteria)

  const poolParameterhandleSubmit = async (data) => {
    data.lowerLimit =
      typeof data.lowerLimit === 'string'
        ? formatStringToNumber(data.lowerLimit)
        : data.lowerLimit
    data.upperLimit =
      typeof data.upperLimit === 'string'
        ? formatStringToNumber(data.upperLimit)
        : data.upperLimit

    data.filters.forEach((filter) => {
      filter.filterInputs.value =
        typeof filter.filterInputs.value === 'string'
          ? formatStringToNumber(filter.filterInputs.value)
          : filter.filterInputs.value
    })

    await dispatch(
      createPoolParameters({ warehouseId: warehouse._id, payload: [data] })
    )
    setShowAddForms((prevState) => ({
      ...prevState,
      poolParameter: false,
    }))
  }

  const criteriaEligibityhandleSubmit = async (data) => {
    await dispatch(
      createEligibilityCriteria({ warehouseId: warehouse._id, payload: [data] })
    )
    setShowAddForms((prevState) => ({
      ...prevState,
      eligibityCriteria: false,
    }))
  }

  return (
    <>
      {!isEditable && (
        <div style={s('bg-yellow-light rounded-lg px-8 py-1 my-8 shadow-lg')}>
          <h3 style={s('text-grey-600 mb-0')}>
            Cannot edit parameters for an active warehouse
          </h3>
          <p style={s('mt-0')}>
            To add or update parameters place the warehouse on hold and then re activate
            it.
          </p>
        </div>
      )}

      <h2>Eligibility criteria</h2>
      <EligibilityCriteriaTable
        eligibilityCriteria={eligibilityCriteria}
        eligibilityVerifications={eligibilityVerifications}
        isEditable={isEditable}
      />

      {showAddForms.eligibityCriteria && (
        <div style={s('bg-white rounded-lg p-8  mb-8')}>
          <EligibilityCriteriaForm
            eligibilityVerifications={eligibilityVerifications}
            onSubmit={criteriaEligibityhandleSubmit}
            isCreateParametersPending={isCreateEligibilityCriteria}
            closeForm={() => {
              setShowAddForms((prevState) => ({
                ...prevState,
                eligibityCriteria: !prevState.eligibityCriteria,
              }))
            }}
          />
        </div>
      )}

      {!showAddForms.eligibityCriteria && isEditable && (
        <Button
          onClick={() =>
            setShowAddForms((prevState) => ({
              ...prevState,
              eligibityCriteria: !prevState.eligibityCriteria,
            }))
          }
          style={s('mb-16')}
        >
          Add criteria
        </Button>
      )}

      <h2>Pool parameters</h2>
      <PoolParametersTable poolParameters={poolParameters} rows={[]} />

      {showAddForms.poolParameter && isEditable && (
        <div style={s('bg-white rounded-lg p-8 mb-8')}>
          <ParametersForm
            formTitle="Add Parameter"
            initialValues={{
              numberField: 'percentageOfLoans',
              lowerLimit: 0,
              upperLimit: 20,
              filters: [],
            }}
            onSubmit={poolParameterhandleSubmit}
            submitTitle="Create"
            parameters={poolParameters}
            closeForm={() => {
              setShowAddForms((prevState) => ({
                ...prevState,
                poolParameter: !prevState.poolParameter,
              }))
            }}
            isCreateParametersPending={isCreatePoolParametersPending}
          />
        </div>
      )}

      {!showAddForms.poolParameter && isEditable && (
        <Button
          onClick={() =>
            setShowAddForms((prevState) => ({
              ...prevState,
              poolParameter: !prevState.poolParameter,
            }))
          }
          style={s('mb-16')}
        >
          Add parameter
        </Button>
      )}
    </>
  )
}

export default Parameters
