import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { selectFunderById, fetchFunder, updateFunder } from 'modules/funder'
import FunderForm from 'components/FunderForm'

export default function Edit() {
  const { id } = useParams()
  const funder = useSelector(selectFunderById(id))

  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(fetchFunder(id))
  }, [dispatch, id])

  const handleSubmit = (data) => {
    dispatch(updateFunder({ funderId: id, data }))
    history.push(`/funders/${id}`)
  }

  if (funder) {
    return (
      <FunderForm
        formTitle={`Edit ${funder?.organisationName}`}
        initialValues={funder}
        onSubmit={handleSubmit}
        submitTitle="Update"
        cancelUrl={`/funders/${id}`}
      />
    )
  }

  return <p>TODO: error?</p>
}
