import React from 'react'
import { Col, Row } from '@vega/styled'
import { SelectField, CheckboxField, NumberField } from '@vega/components'
import { s, styled } from '@vega/styled/v2'
import { formatCurrency } from 'utils'
import { formatPercentage, twoDecimalPointsIfNeeded } from 'utils/formatter'

// TODO: should this live within @vega/components
export const Label = styled.span(
  s('inline-block text-sm font-normal text-grey-800 font-normal mb-2', {
    letterSpacing: '0.015em',
    lineHeight: 1.5,
  })
)

export const ruleTypes = [
  { label: 'Loan size by geography', value: 'loanSizeByGeographyValue' },
  { label: 'Number', value: 'numericValue' },
  { label: 'Yes or no', value: 'booleanValue' },
  { label: 'Manual verification', value: 'verificationValue' },
]

const geographies = [
  { label: 'Metro', value: 'metro' },
  { label: 'Non metro', value: 'nonMetro' },
  { label: 'Auckland', value: 'auckland' },
]

const directions = [
  { label: 'Minimum', value: 'min' },
  { label: 'Maximum', value: 'max' },
]

const numberFields = [
  { label: 'Total loan amount', value: 'totalLoanAmount' },
  { label: 'Years principal and interest', value: 'yearsPrincipalAndInterest' },
  {
    label: 'Years interest only (owner occupied)',
    value: 'yearsInterestOnlyOwnerOccupied',
  },
  { label: 'Years interest only (investment)', value: 'yearsInterestOnlyInvestment' },
  { label: 'LVR', value: 'lvr' },
  { label: 'eValuation', value: 'eValuation' },
  { label: 'Number of properties', value: 'numberOfProperties' },
  { label: 'Number of borrowers', value: 'numberOfBorrowers' },
]

// const numberFields = [
//   { label: 'Borrower exposure', value: 'borrowerExposure' },
//   { label: 'Interest only period (years)', value: 'interestOnlyPeriodInYears' },
//   { label: 'Investor properties', value: 'investorProperties' },
//   { label: 'Rural residential loans', value: 'ruralResidentialLoans' },
//   { label: 'Bridging loans', value: 'bridgingLoans' },
//   { label: 'Cash out', value: 'cashOut' },
// ]

const booleanFields = [
  { label: 'Is 18 or over', value: 'minimumAge' },
  { label: 'Only one company or less', value: 'oneCompanyOrLess' },
  { label: 'Only one trust or less', value: 'oneTrustOrLess' },
  { label: 'Has valid residency status', value: 'validResidencyStatus' },
]

// const booleanFields = [
//   { label: 'Full documentation', value: 'fullDocumentation' },
//   { label: 'Full valuation', value: 'fullValuation' },
//   { label: 'Hardship loan', value: 'hardshipLoan' },
//   { label: 'Restructured loan', value: 'restructuredLoan' },
//   { label: 'Loans reallocated from other trusts', value: 'loansReallocatedFromOtherTrusts' },
//   { label: 'Constructions loan', value: 'constructionsLoan' },
//   { label: 'Partially completedDwelling', value: 'partiallyCompletedDwelling' },
//   { label: 'Vacant land', value: 'vacantLand' },
//   { label: 'Line of credit loan', value: 'lineOfCreditLoan' },
//   { label: 'Developer residual stock', value: 'developerResidualStock' },
//   { label: 'Serviced apartment', value: 'servicedApartment' },
//   { label: 'Min appartment size', value: 'minAppartmentSize' },
//   { label: 'Commercial loan', value: 'commercialLoan' },
//   { label: 'Second mortage', value: 'secondMortage' },
//   { label: 'SPPI receivable', value: 'sppiReceivable' },
//   { label: 'Bullet loan', value: 'bulletLoan' },
//   { label: 'SMSF loan', value: 'smsfLoan' },
// ]

const labelForValue = (list, value) => {
  const match = list.find((item) => item.value === value)
  return match ? match.label : value
}

export const formattedRuleType = (ruleType) => labelForValue(ruleTypes, ruleType)

/* eslint-disable complexity */
export const descriptionForCriterion = (criterion, eligibilityVerifications) => {
  const { inputs } = criterion

  switch (criterion.ruleType) {
    case 'loanSizeByGeography': {
      const geography = labelForValue(geographies, inputs.geography)
      const direction = labelForValue(directions, inputs.direction).toLowerCase()
      return `Properties in ${geography} must have a ${direction} loan size of ${formatCurrency(
        inputs.loanSize
      )}`
    }

    case 'numericValue': {
      const numberField = labelForValue(numberFields, inputs.numberField)
      const direction = labelForValue(directions, inputs.direction).toLowerCase()

      const currencyFields = ['totalLoanAmount', 'eValuation']
      const percentFields = ['lvr', ]
      const yearFields = [
        'yearsPrincipalAndInterest',
        'yearsInterestOnlyOwnerOccupied',
        'yearsInterestOnlyInvestment',
      ]

      let { value } = inputs
      switch (true) {
        case currencyFields.includes(inputs.numberField):
          value = formatCurrency(inputs.value)
          break

        case percentFields.includes(inputs.numberField):
          value = formatPercentage(twoDecimalPointsIfNeeded(inputs.value))
          break

        case yearFields.includes(inputs.numberField):
          value = `${inputs.value} ${inputs.value > 1 ? 'years': 'year'}`
          break

        default:
          value = inputs.value
      }

      return `${numberField} must have a ${direction} value of ${value}`
    }

    case 'booleanValue': {
      const booleanField = labelForValue(booleanFields, inputs.booleanField)
      return `${booleanField} must be ${inputs.expectedValue ? 'true' : 'false'}`
    }

    case 'verificationValue': {
      const matchingVerification = eligibilityVerifications.find(
        (verification) => verification._id === inputs.verificationId
      )
      const name = matchingVerification ? matchingVerification.title : '-'

      return `${name} must be manually set to ${inputs.expectedValue}`
    }

    default:
      return '-'
  }
}

export const loanSizeByGeographyInputs = (
  <Row style={s('mb-4')}>
    <Col span={8}>
      <Label>Geography</Label>
      <SelectField name="inputs.geography" options={geographies} />
    </Col>

    <Col span={8}>
      <Label>Min or max</Label>
      <SelectField name="inputs.direction" options={directions} />
    </Col>

    <Col span={8}>
      <Label>Loan size</Label>
      <NumberField name="inputs.loanSize" />
    </Col>
  </Row>
)

export const numericValueInputs = (
  <Row style={s('mb-4')}>
    <Col span={8}>
      <Label>Field</Label>
      <SelectField name="inputs.numberField" options={numberFields} />
    </Col>

    <Col span={8}>
      <Label>Min or max</Label>
      <SelectField name="inputs.direction" options={directions} />
    </Col>

    <Col span={8}>
      <Label>Value</Label>
      <NumberField name="inputs.value" />
    </Col>
  </Row>
)

export const booleanValueInputs = (
  <Row style={s('mb-4')}>
    <Col span={8}>
      <Label>Field</Label>
      <SelectField name="inputs.booleanField" options={booleanFields} />
    </Col>

    <Col span={8}>
      <Label>Expected value</Label>
      <CheckboxField name="inputs.expectedValue" />
    </Col>
  </Row>
)

export const verificationValueInputs = (eligibilityVerifications, values) => {
  const selectedVerification = eligibilityVerifications.find(
    (verification) => verification._id === values.inputs.verificationId
  )

  const options = eligibilityVerifications.map((verification) => ({
    label: verification.title,
    value: verification._id,
  }))

  return (
    <>
      <Row style={s('mb-4')}>
        <Col span={8}>
          <Label>Manual verification</Label>
          <SelectField
            name="inputs.verificationId"
            options={options}
            selectMenuStyles={{
              overflowY: 'scroll',
              scrollbarWidth: 'thin',
              height: '200px',
            }}
          />
        </Col>

        <Col span={8}>
          <Label>Expected value</Label>
          <SelectField
            name="inputs.expectedValue"
            options={[
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' },
            ]}
          />
        </Col>
      </Row>
      {
        selectedVerification && (
          <Row style={s('mb-4')}>
            <Col>
              <Label>Description</Label>
              <div>{selectedVerification.description}</div>
            </Col>
          </Row>
        )
      }
    </>
  )
}
