import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { Col, Row } from '@vega/styled'
import { Button, SelectField, NumberField } from '@vega/components'
import { useFormikContext } from 'formik'

const Label = styled.p(s('m-0 mb-1'))

const NumberValueFilterRow = ({ index, remove }) => {
  const { setFieldValue, values } = useFormikContext()

  const poolParameterFilterTypes = [
    { label: 'Loans with number', value: 'numberValueFilter' },
    { label: 'Loans with yes or no', value: 'booleanValueFilter' },
    { label: 'Loans with geography', value: 'geographyValueFilter' },
  ]

  const numberValues = [
    {
      label: 'Loan size',
      value: 'loanSize',
      prefix: '$',
      suffix: '',
      thousandSeparator: true,
    },
    { label: 'LVR', value: 'lvr', prefix: '', suffix: '%', thousandSeparator: false },
    {
      label: 'Interest Only Period',
      value: 'interestOnlyPeriod',
      prefix: '',
      suffix: '',
      thousandSeparator: false,
    },
  ]

  const directions = [
    { label: 'Minimum', value: 'min' },
    { label: 'Maximum', value: 'max' },
  ]

  return (
    <Row style={s('bg-grey-lightest p-4 rounded-lg mb-4')}>
      <Col span="5">
        <SelectField
          name={`filters.${index}.filterType`}
          label="Filter type"
          options={poolParameterFilterTypes}
          style={s('bg-white')}
          labelContainerStyle={s('bg-grey-lightest')}
        />
      </Col>

      <Col span="5">
        <SelectField
          name={`filters.${index}.filterInputs.numberField`}
          label="Field"
          options={numberValues}
          style={s('bg-white')}
          labelContainerStyle={s('bg-grey-lightest')}
          onSelectionChange={(selectedOption) => {
            const selectedParameterOption = numberValues.find(
              (option) => option.value === selectedOption
            )
            setFieldValue(`filters.${index}.filterInputs.numberField`, selectedOption)
            setFieldValue(
              `filters.${index}.filterInputs.value`,
              selectedParameterOption.label
            )
          }}
        />
      </Col>

      <Col span="5">
        <SelectField
          name={`filters.${index}.filterInputs.direction`}
          label="Min or max"
          options={directions}
          style={s('bg-white')}
          labelContainerStyle={s('bg-grey-lightest')}
        />
      </Col>

      <Col span="5">
        <Label>Value</Label>
        <NumberField
          name={`filters.${index}.filterInputs.value`}
          key={`filters-${index}`}
          placeholder="Value"
          style={s('bg-white')}
          prefix={
            numberValues.find(
              (numberValue) =>
                numberValue.value === values?.filters[index].filterInputs.numberField
            )?.prefix
          }
          suffix={
            numberValues.find(
              (numberValue) =>
                numberValue.value === values?.filters[index].filterInputs.numberField
            )?.suffix
          }
          thousandSeparator={
            numberValues.find(
              (numberValue) =>
                numberValue.value === values?.filters[index].filterInputs.numberField
            )?.thousandSeparator
          }
        />
      </Col>

      <Col span="4" style={s('flex justify-end')}>
        <Button variant="outlined" onClick={() => remove(index)} style={s('mt-8')}>
          Remove filter
        </Button>
      </Col>
    </Row>
  )
}

export default NumberValueFilterRow
