import { prop } from '@neo/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'
import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'
import { warehouseTransfersAdapter } from './module'

const selectAllTransfers = prop('warehouseTransfers')
export const selectTotal = createSelector(selectAllTransfers, prop('total'))

export const { selectIsPending: selectIsUpdatingPending } = createAsyncThunkSelectors(
  'warehouseTransfers/updateTransfer'
)

export const {
  selectIsPending: selectIsUpdatingStatusPending,
} = createAsyncThunkSelectors('warehouseTransfers/updateTransferStatus')

export const {
  selectIsPending: selectIsFetchFunderPending,
  selectHasFulfilled: selectHasFetchFunderFulfilled,
} = createAsyncThunkSelectors('funders/getFunder')

export const {
  selectAll: selectTransfers,
  selectById: selectTransfer,
} = warehouseTransfersAdapter.getSelectors((s) => s.warehouseTransfers)

export const selectTransferById = (id) => (state) => selectTransfer(state, id)
