import React from 'react'
import { Button } from '@vega/components'

const AddFilterButton = ({ arrayHelpers }) => (
  <Button
    variant="outlined"
    type="button"
    onClick={() => arrayHelpers.push(
    {
      filterType: 'numberValueFilter',
      filterInputs: {
        numberField: 'loanSize',
        value: 0,
        direction: 'min'
      }
    }
  )}>
    Add filter
  </Button>
)

export default AddFilterButton
