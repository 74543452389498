import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import { thunkErrorProcessor } from '@vega/error-standardizer'
import { normalizeError } from '@vega/services'

import { warehouseService } from 'apiService'

export const fetchWarehouse = createAsyncThunk(
  'warehouse/getWarehouse',
  async (id, { rejectWithValue }) => {
    try {
      const warehouse = await warehouseService.getWarehouse(id)
      return warehouse
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const createWarehouse = createAsyncThunk(
  'warehouse/createWarehouse',
  async (payload, { rejectWithValue }) => {
    try {
      return await warehouseService.createWarehouse(payload)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const fetchWarehouses = createAsyncThunk(
  'warehouse/getWarehouses',
  async ({ searchParams, pageIndex }, { rejectWithValue, signal }) => {
    try {
      const { searchTerm: q, filters = {}, sorting = {}, limit = 20 } = searchParams
      return await warehouseService.getWarehouses(
        {
          q,
          filters,
          limit,
          sorting,
          start: limit * pageIndex,
        },
        signal
      )
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const updateWarehouse = createAsyncThunk(
  'warehouse/update',
  async ({ warehouseId, data }, { rejectWithValue }) => {
    try {
      const updatedWarehouse = await warehouseService.updateWarehouse(warehouseId, data)

      return updatedWarehouse
    } catch (err) {
      const error = await thunkErrorProcessor(err)
      return rejectWithValue(error)
    }
  }
)

export const activateWarehouse = createAsyncThunk(
  'warehouse/activate',
  async ({ warehouseId, status = 'ACTIVE' }, { rejectWithValue }) => {
    try {
      const activatedWarehouse = await warehouseService.updateWarehouseStatus(
        warehouseId,
        status
      )

      return activatedWarehouse
    } catch (err) {
      const error = await thunkErrorProcessor(err)
      return rejectWithValue(error)
    }
  }
)

export const archiveWarehouse = createAsyncThunk(
  'warehouse/archive',
  async ({ warehouseId, status = 'ARCHIVE' }, { rejectWithValue }) => {
    try {
      const archivedWarehouse = await warehouseService.updateWarehouseStatus(
        warehouseId,
        status
      )

      return archivedWarehouse
    } catch (err) {
      const error = await thunkErrorProcessor(err)
      return rejectWithValue(error)
    }
  }
)

export const onHoldWarehouse = createAsyncThunk(
  'warehouse/onHold',
  async ({ warehouseId, status = 'ONHOLD' }, { rejectWithValue }) => {
    try {
      const onHoldWarehouse = await warehouseService.updateWarehouseStatus(
        warehouseId,
        status
      )

      return onHoldWarehouse
    } catch (err) {
      const error = await thunkErrorProcessor(err)
      return rejectWithValue(error)
    }
  }
)

export const setDefaultWarehouse = createAsyncThunk(
  'warehouse/setDefault',
  async ({ warehouseId, status = 'ACTIVE' }, { rejectWithValue }) => {
    try {
      const defaultWarehouse = await warehouseService.setDefaultWarehouse(
        warehouseId,
        status
      )

      return defaultWarehouse
    } catch (err) {
      const error = await thunkErrorProcessor(err)
      return rejectWithValue(error)
    }
  }
)

export const createPoolParameters = createAsyncThunk(
  'warehouse/createPoolParameters',
  async ({ warehouseId, payload }, { rejectWithValue }) => {
    try {
      return await warehouseService.createPoolParameters(warehouseId, payload)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const readPoolParameters = createAsyncThunk(
  'warehouse/readPoolParameters',
  async ({ warehouseId }, { rejectWithValue }) => {
    try {
      return await warehouseService.readPoolParameters(warehouseId)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const deletePoolParameter = createAsyncThunk(
  'warehouse/deletePoolParameter',
  async ({ warehouseId, paremeterId }, { rejectWithValue }) => {
    try {
      return await warehouseService.deletePoolParameter(warehouseId, paremeterId)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const updatePoolParameters = createAsyncThunk(
  'warehouse/updatePoolParameters',
  async ({ warehouseId, parameterId, payload }, { rejectWithValue }) => {
    try {
      return await warehouseService.updatePoolParameters(
        warehouseId,
        parameterId,
        payload
      )
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const createEligibilityCriteria = createAsyncThunk(
  'warehouse/createEligibilityCriteria',
  async ({ warehouseId, payload }, { rejectWithValue }) => {
    try {
      return await warehouseService.createEligibilityCriteria(warehouseId, payload)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const deleteEligibilityCriteria = createAsyncThunk(
  'warehouse/deleteEligibilityCriteria',
  async ({ warehouseId, paremeterId }, { rejectWithValue }) => {
    try {
      return await warehouseService.deleteEligibilityCriteria(warehouseId, paremeterId)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const updateEligibilityCriteria = createAsyncThunk(
  'warehouse/updateEligibilityCriteria',
  async ({ warehouseId, parameterId, payload }, { rejectWithValue }) => {
    try {
      return await warehouseService.updateEligibilityCriteria(
        warehouseId,
        parameterId,
        payload
      )
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const updateFunderDrawDowns = createAsyncThunk(
  'warehouse/updateFunderDrawDowns',
  async ({ warehouseId, drawDowns }, { rejectWithValue }) => {
    try {
      return await warehouseService.updateFunderDrawDowns(warehouseId, drawDowns)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const validatePoolParameters = createAsyncThunk(
  'warehouse/validatePoolParameters',
  async ({ warehouseId, loanIds }, { rejectWithValue }) => {
    try {
      return await warehouseService.validatePoolParameters(warehouseId, loanIds)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const undoPendingState = createAsyncThunk(
  'warehouse/undoPendingState',
  async ({ warehouseId }, { rejectWithValue }) => {
    try {
      return await warehouseService.undoPendingState(warehouseId)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const warehouseAdapter = createEntityAdapter({
  selectId: (warehouse) => warehouse._id,
})

const initialState = warehouseAdapter.getInitialState()

const warehouseSlice = createSlice({
  name: 'warehouse',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWarehouse.fulfilled, (state, action) => {
        warehouseAdapter.upsertOne(state, action.payload)
      })

      .addCase(fetchWarehouses.fulfilled, (state, action) => {
        const { items: warehouses, pagination } = action.payload

        warehouseAdapter.setAll(state, warehouses)
        state.total = pagination.total
      })

      .addCase(createWarehouse.fulfilled, (state, action) => {
        warehouseAdapter.upsertOne(state, action.payload)
      })

      .addCase(updateWarehouse.fulfilled, (state, action) => {
        warehouseAdapter.upsertOne(state, action.payload)
      })

      .addCase(activateWarehouse.fulfilled, (state, action) => {
        const { _id } = action.payload
        state.entities[_id] = { ...state.entities[_id], pendingState: undefined }
        warehouseAdapter.upsertOne(state, action.payload)
      })

      .addCase(archiveWarehouse.fulfilled, (state, action) => {
        const { _id } = action.payload
        state.entities[_id] = { ...state.entities[_id], pendingState: undefined }
        warehouseAdapter.upsertOne(state, action.payload)
      })

      .addCase(onHoldWarehouse.fulfilled, (state, action) => {
        const { _id } = action.payload
        state.entities[_id] = { ...state.entities[_id], pendingState: undefined }
        warehouseAdapter.upsertOne(state, action.payload)
      })

      .addCase(createPoolParameters.fulfilled, (state, action) => {
        warehouseAdapter.upsertOne(state, action.payload)
      })

      .addCase(deletePoolParameter.fulfilled, (state, action) => {
        warehouseAdapter.upsertOne(state, action.payload)
      })

      .addCase(createEligibilityCriteria.fulfilled, (state, action) => {
        warehouseAdapter.upsertOne(state, action.payload)
      })

      .addCase(updateEligibilityCriteria.fulfilled, (state, action) => {
        warehouseAdapter.upsertOne(state, action.payload)
      })

      .addCase(deleteEligibilityCriteria.fulfilled, (state, action) => {
        warehouseAdapter.upsertOne(state, action.payload)
      })

      .addCase(setDefaultWarehouse.fulfilled, (state, action) => {
        warehouseAdapter.upsertOne(state, action.payload)
      })

      .addCase(updateFunderDrawDowns.fulfilled, (state, action) => {
        warehouseAdapter.upsertOne(state, action.payload)
      })

      .addCase(updatePoolParameters.fulfilled, (state, action) => {
        warehouseAdapter.upsertOne(state, action.payload)
      })

      .addCase(undoPendingState.fulfilled, (state, action) => {
        const { _id } = action.payload
        state.entities[_id] = { ...state.entities[_id], pendingState: undefined }
        warehouseAdapter.upsertOne(state, action.payload)
      })
  },
})

const { reducer: warehouseReducer } = warehouseSlice

export { warehouseReducer }
