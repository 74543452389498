import React from 'react'
import { ReactComponent as XBase } from '@vega/components/src/assets/images/x.svg'
import { s, styled } from '@vega/styled/v2'
import { Modal, NumberField, Button } from '@vega/components'
import { Formik, Form, FieldArray } from 'formik'
import { Col, Row } from '@vega/styled'

// TODO: should this live within @vega/components
export const Label = styled.span(
  s('inline-block text-sm font-normal text-grey-800 font-normal mb-2', {
    letterSpacing: '0.015em',
    lineHeight: 1.5,
  })
)

const X = styled(XBase)(s('text-primary', { cursor: 'pointer' }))

const Container = styled.div(
  s('p-6 bg-white', {
    borderRadius: 12,
    boxShadow: '0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)',
    width: '44rem',
  })
)

const Header = styled.div(s('flex justify-between'))

const Title = styled.div(
  s('text-xl text-grey-900 font-semibold', {
    letterSpacing: '-0.01em',
    lineHeight: 1.2,
    marginBottom: 28,
  })
)

const EditFundersCapacityModal = ({
  isOpen,
  setIsOpen,
  handleSubmit,
  tranches
}) => {
  const close = () => setIsOpen(false)

  const handleSubmitAndClose = (data) => {
    handleSubmit(data)
    close()
  }

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <Container>
        <Header>
          <Title>
            Edit funder draw downs
          </Title>
          <X onClick={close} />
        </Header>

        <Formik initialValues={{tranches}} onSubmit={handleSubmitAndClose}>
          {({ values }) =>
            (
              <Form>
                <FieldArray
                  name="tranches"
                  render={() => (
                    <>
                      {values.tranches.map((tranche, index) => (
                        <Row key={index} style={s('mb-4')}>
                          <Col span={24}>
                            <Label>{tranche.funder.organisationName}</Label>
                            <NumberField
                              name={`tranches.${index}.drawnDown`}
                              prefix="$"
                              thousandSeparator
                            />
                          </Col>
                        </Row>
                      ))}
                    </>
                  )}
                />

                <div style={s('flex', { flexDirection: 'row-reverse' })}>
                  <Button type="submit" style={s('ml-2')}>
                    Update draw downs
                  </Button>

                  <Button variant="outlined" onClick={() => close()}>
                    Cancel
                  </Button>
                </div>
              </Form>
            )
          }
        </Formik>
      </Container>
    </Modal>
  )
}

export default EditFundersCapacityModal
