import React from 'react'
import { s } from '@vega/styled/v2'
import { formatPercentage } from 'utils/formatter'
import { PieChart } from '@vega/components'
import { graphColors } from 'utils/graphColors'

const FundersDistribution = ({ warehouse }) => {
  const totalCapacity = warehouse.tranches
    .reduce((acc, curr) => acc + curr.capacity, 0)

  const distrubtions = warehouse.tranches.map((tranche, index) => {
    const split = (tranche.capacity / totalCapacity) * 100

    return (
      <div key={tranche._id} style={s('flex items-center py-1')}>
        <div style={s('h-1 w-2 rounded-full mx-2', { background: graphColors[index] })} />
        <div style={{ flexGrow: 1 }}>{tranche.funder.organisationName}</div>
        <div style={s('px-4')}>{formatPercentage(Math.round(split))}</div>
      </div>
    )
  })

  const distributionChartData = warehouse.tranches.map((tranche, index) => ({
    label: tranche.funder.organisationName,
    color: graphColors[index],
    frequency: (tranche.capacity / totalCapacity) * 100
  }))

  return (
    <div style={s('bg-white rounded-lg p-4 shadow-lg', { flexGrow: 1 })}>
      {distrubtions}
      <div style={s('text-center')}>
        <PieChart
          width={200}
          height={200}
          data={distributionChartData}
          showPieLabel={false}
        />
      </div>
    </div>
  )
}

export default FundersDistribution
