import React from 'react'
import { Formik as FormProvider, Form } from 'formik'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { s, styled } from '@vega/styled/v2'

import { TextField } from '@vega/components'

import Button from './Button'

const Label = styled.span(
  s('inline-block text-sm font-normal text-grey-800 font-normal mb-2', {
    letterSpacing: '0.015em',
    lineHeight: 1.5,
  })
)

const funderValadiationSchema = Yup.object().shape({
  organisationName: Yup.string().required('Organisation Name is required'),
  contactFirstName: Yup.string().required('First Name is required'),
  contactLastName: Yup.string().required('First Name is required'),
  contactEmail: Yup.string().email().required('Email is required'),
  contactPhone: Yup.number().required('Concact Phone is required'),
})

const FormTextField = styled(TextField)(s('mb-6'))

const FunderForm = ({ formTitle, initialValues, onSubmit, submitTitle, cancelUrl }) => {
  return (
    <FormProvider
      initialValues={initialValues}
      validationSchema={funderValadiationSchema}
      onSubmit={onSubmit}
    >
      {({ values, handleChange, handleBlur }) => (
        <Form style={s('p-12 bg-white rounded-lg')}>
          <h1>{formTitle}</h1>

          <Label>Organisation name</Label>
          <FormTextField
            name="organisationName"
            placeholder="Organisation name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.organisationName}
          />
          <Label>First name</Label>
          <FormTextField
            name="contactFirstName"
            placeholder="First name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.contactFirstName}
          />
          <Label>Last name</Label>
          <FormTextField
            name="contactLastName"
            placeholder="Last name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.contactLastName}
          />
          <Label>Email</Label>
          <FormTextField
            name="contactEmail"
            placeholder="Email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.contactEmail}
          />
          <Label>Phone number</Label>
          <FormTextField
            name="contactPhone"
            placeholder="Phone Number"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.contactPhone}
          />

          <div style={s('flex my-2', { gap: '0.5rem' })}>
            <Button type="submit">{submitTitle}</Button>

            <Link to={cancelUrl} style={s('no-decoration')}>
              <Button type="cancel">Cancel</Button>
            </Link>
          </div>
        </Form>
      )}
    </FormProvider>
  )
}

export default FunderForm
