/* eslint-disable complexity */
import React from 'react'
import * as Validator from 'yup'
import { Formik, Form } from 'formik'
import { s, styled } from '@vega/styled/v2'
import { Modal, TextField as TextFieldBase } from '@vega/components'
import { ReactComponent as XBase } from '@vega/components/src/assets/images/x.svg'
import { isNilOrEmpty } from '@neo/ramda-extra'
import WarehouseStatus from 'features/warehouse/WarehouseStatus'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  activateWarehouse,
  onHoldWarehouse,
  archiveWarehouse,
  setDefaultWarehouse,
  fetchWarehouse,
} from 'modules/warehouse'
import WarehouseDefaultStatus from './WarehouseDefaultStatus'
import { selectProfile } from '@vega/redux.profile'

const Container = styled.div(
  s('p-6 bg-white', {
    borderRadius: 12,
    boxShadow: '0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)',
    maxWidth: 703,
  })
)
const Header = styled.div(s('flex justify-between'))
const Title = styled.div(
  s('text-xl text-grey-900 font-semibold', {
    letterSpacing: '-0.01em',
    lineHeight: 1.2,
    marginBottom: 28,
  })
)
const X = styled(XBase)(s('text-primary', { cursor: 'pointer' }))
const TextContainer = styled.div(s('mb-4 bg-grey-100', { padding: 6 }))
const Text = styled.div(
  s('tet-sm text-grey-900 font-normal', { letterSpacing: '0.01em', lineHeight: 1.5 })
)
const List = styled.ul(s('mt-0'))
const ListItem = styled.li(
  s('text-base text-grey-900 font-normal', { lineHeight: 1.5 })
)
const BoldText = styled.span(s('font-semibold'))
const TextField = styled(TextFieldBase)(s('mb-8', { minWidth: 430 }))
const Button = styled.button(
  s(
    'flex justify-center w-full border-0 bg-secondary text-primary text-base font-semibold',
    {
      borderRadius: 6,
      cursor: 'pointer',
      letterSpacing: '0.01em',
      lineHeight: 1.5,
      paddingBottom: 10,
      paddingTop: 10,
    }
  ),
  ({ isDisabled }) => isDisabled && s('bg-grey-200 text-grey-600')
)

const { object, string } = Validator

const validationSchema = (confirmation) =>
  object({
    confirmation: string().oneOf([confirmation]).required('Confirmation is required'),
  })

const ApproveWarehouseActionModal = ({ isOpen, setIsOpen, warehouse, action = '' }) => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { id: profileId } = useSelector(selectProfile)

  const close = () => setIsOpen(false)

  const handleSubmit = async () => {
    if (warehouse?.default?.isPending && action === 'DEFAULT') {
      await dispatch(setDefaultWarehouse({ warehouseId: id }))
      close()
    } else if (warehouse?.pendingState?.changeTo === 'ACTIVE' && action === 'ACTIVE') {
      await dispatch(activateWarehouse({ warehouseId: id }))
      close()
    } else if (
      warehouse?.pendingState?.changeTo === 'ON_HOLD' &&
      action === 'ON_HOLD'
    ) {
      await dispatch(onHoldWarehouse({ warehouseId: id }))
      close()
    } else if (
      warehouse?.pendingState?.changeTo === 'ARCHIVED' &&
      action === 'ARCHIVED'
    ) {
      await dispatch(archiveWarehouse({ warehouseId: id }))
      close()
    }
  }

  const rejectAction = async () => {
    if (warehouse.default.isPending && action === 'DEFAULT') {
      await dispatch(setDefaultWarehouse({ warehouseId: id, status: 'REJECT' }))
      close()
    }
    if (warehouse?.pendingState?.changeTo === 'ACTIVE' && action === 'ACTIVE') {
      await dispatch(activateWarehouse({ warehouseId: id, status: 'REJECT' }))
      close()
    }
    if (warehouse?.pendingState?.changeTo === 'ON_HOLD' && action === 'ON_HOLD') {
      await dispatch(onHoldWarehouse({ warehouseId: id, status: 'REJECT' }))
      close()
    }
    if (warehouse?.pendingState?.changeTo === 'ARCHIVED' && action === 'ARCHIVED') {
      await dispatch(archiveWarehouse({ warehouseId: id, status: 'REJECT' }))
      close()
    }
    await dispatch(fetchWarehouse(id))
  }

  const isApproverSame = profileId === warehouse?.pendingState?.changeInitiatedBy
  const isDefaultApproverSame = profileId === warehouse?.default?.pendingBy

  return (
    <Modal isOpen={isOpen} onClose={close}>
      {isApproverSame || isDefaultApproverSame ? (
        <Container>
          <Header>
            <Title style={s('text-red')}>
              Cannot approve warehouse action: {warehouse.name}
            </Title>
            <X onClick={close} />
          </Header>
          <TextContainer>
            <Text>
              The <span style={s('font-bold')}>APPROVER</span> must be a different user
              than the <span style={s('font-bold')}>REQUESTER</span>.
            </Text>
          </TextContainer>
        </Container>
      ) : (
        <Container>
          <Header>
            <Title>Approve warehouse action: {warehouse.name}</Title>
            <X onClick={close} />
          </Header>

          <div style={s('flex items-center mb-4')}>
            <WarehouseStatus warehouse={warehouse} />
            {(warehouse.default.isDefault || warehouse.default.isPending) && (
              <WarehouseDefaultStatus warehouse={warehouse} />
            )}
          </div>

          <TextContainer>
            <Text>
              Before approving this warehouse action, please review a few items:
            </Text>
            <List>
              <ListItem>Have you found out what these items should be?</ListItem>
            </List>
            <Text>
              If not, please come back later. You <BoldText>CANNOT</BoldText> undo this
              task.
            </Text>
          </TextContainer>

          <p>Warehouse structure</p>
          {warehouse.tranches.map((tranche) => (
            <div key={tranche._id}>
              {tranche.funder.organisationName}
              {tranche.capacity}
            </div>
          ))}

          <Formik
            initialValues={{ confirmation: '' }}
            validationSchema={validationSchema(warehouse.name)}
            onSubmit={handleSubmit}
          >
            {({ isValid, values }) => {
              const shouldDisableSubmitBtn =
                !isValid || isNilOrEmpty(values?.confirmation)

              return (
                <Form>
                  <Text style={s('mb-1')}>
                    Confirm by typing <BoldText>the warehouse name</BoldText> below
                  </Text>
                  <TextField name="confirmation" placeholder={warehouse.name} />

                  <Button
                    type="submit"
                    style={s('mb-6')}
                    isDisabled={shouldDisableSubmitBtn}
                  >
                    Approve warehouse action
                  </Button>
                  <Button onClick={rejectAction} isDisabled={!shouldDisableSubmitBtn}>
                    Reject warehouse action
                  </Button>
                </Form>
              )
            }}
          </Formik>
        </Container>
      )}
    </Modal>
  )
}

export default ApproveWarehouseActionModal
