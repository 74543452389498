import React from 'react'
import { ReactComponent as ChevronLeft } from '@vega/components/src/assets/images/chevron-left.svg'
import { s, styled } from '@vega/styled/v2'
import { Button } from '@vega/components'
import { useHistory } from 'react-router-dom'
import { SectionTitle as SectionTitleBase } from '@vega/components/src/v2'

const BackButton = styled(Button)(
  s('border-transparent bg-transparent p-0 flex flex-row')
)

const BackButtonText = styled(SectionTitleBase)(s('text-sm'))

const BackLink = ({ toLink }) => {
  const history = useHistory()

  return (
    <BackButton
      variant="button"
      onClick={() => (toLink ? history.push(toLink) : history.goBack())}
    >
      <ChevronLeft />
      <BackButtonText title="Back" style={s('mt-1')} />
    </BackButton>
  )
}

export default BackLink
