import React from 'react'

import { s } from '@vega/styled/v2'

import { statusForFunder } from 'features/funder/utils'

const FunderStatus = ({ funder }) => {
  const funderStatus = statusForFunder(funder)
  const color = funderStatus === 'ACTIVE' ? 'bg-green-100' : 'bg-accent-red'

  return (
    <span
      style={s(
        `rounded-2xl px-3 py-1 h-2 flex items-center justify-center font-semibold ${color}`
      )}
    >
      {funderStatus}
    </span>
  )
}

export default FunderStatus
