import React from 'react'
import { Col, Row } from '@vega/styled'
import { Button, NumberField, SelectField } from '@vega/components'
import { Formik, Form } from 'formik'
import { s } from '@vega/styled/v2'
import PoolParameterFilters from './PoolParameterFilters'
import { Label } from '../styles'
import { poolParametersTypes } from './utils'

export const ParametersForm = ({
  closeForm,
  initialValues,
  onSubmit,
  isCreateParametersPending,
}) => {
  const updatedInitialValues = {
    ...initialValues,
    selectedParameter: poolParametersTypes[0],
  }

  return (
    <Formik initialValues={updatedInitialValues} onSubmit={onSubmit}>
      {({ values, handleChange, handleBlur, setFieldValue, ...props }) => (
        <Form onSubmit={props.handleSubmit}>
          <h4>Add pool parameter</h4>

          <Row style={s('mb-4')}>
            <Col span={8}>
              <Label>Parameter</Label>
              <SelectField
                name="numberField"
                onSelectionChange={(selectedOption) => {
                  const selectedParameterOption = poolParametersTypes.find(
                    (option) => option.value === selectedOption
                  )
                  setFieldValue('numberField', selectedOption)
                  setFieldValue('selectedParameter', selectedParameterOption)
                }}
                options={poolParametersTypes}
              />
            </Col>

            <Col span={8}>
              <Label>Lower Limit</Label>
              <NumberField
                name="lowerLimit"
                key={`lowerLimit-${values.selectedParameter?.value}`}
                placeholder="Lower limit"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lowerLimits}
                prefix={values?.selectedParameter?.prefix}
                suffix={values?.selectedParameter?.suffix}
                thousandSeparator={values?.selectedParameter?.thousandSeparator}
              />
            </Col>

            <Col span={8}>
              <Label>Upper Limit</Label>
              <NumberField
                name="upperLimit"
                key={`upperLimit-${values.selectedParameter?.value}`}
                placeholder="Upper limit"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.upperLimit}
                prefix={values.selectedParameter.prefix}
                suffix={values.selectedParameter.suffix}
                thousandSeparator={values?.selectedParameter?.thousandSeparator}
              />
            </Col>
          </Row>

          <PoolParameterFilters values={values} />

          <div style={s('flex', { flexDirection: 'row-reverse' })}>
            <Button type="submit" style={s('ml-2')} loading={isCreateParametersPending}>
              Save
            </Button>

            <Button variant="outlined" onClick={() => closeForm()}>
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
