import BaseService from '../baseService'
import { QueryBuilder } from '../utils'

export class FunderService extends BaseService {
  getFunders({ q, start, limit, filters = {} }, signal) {
    const searchParams = QueryBuilder()
      .setQ(q)
      .setPaging({ start, limit })
      .setFilters(filters)
      .build()
    return this.client.get(`funders?${searchParams}`, { signal }).json()
  }

  createFunder(payload) {
    return this.client.post('funders', { json: payload }).json()
  }

  getFunder(id) {
    return this.client.get(`funders/${id}`).json()
  }

  updateFunder(id, payload) {
    return this.client.put(`funders/${id}`, { json: payload }).json()
  }

  archiveFunder(id) {
    return this.client.put(`funders/${id}/status?status=archive`).json()
  }
}
