/* eslint-disable complexity */
import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { Modal } from '@vega/components'
import { ReactComponent as XBase } from '@vega/components/src/assets/images/x.svg'

const Container = styled.div(
  s('p-6 bg-white', {
    borderRadius: 12,
    boxShadow: '0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)',
    maxWidth: 703,
  })
)
const Header = styled.div(s('flex justify-between'))
const Title = styled.div(
  s('text-xl text-grey-900 font-semibold', {
    letterSpacing: '-0.01em',
    lineHeight: 1.2,
    marginBottom: 28,
  })
)
const X = styled(XBase)(s('text-primary', { cursor: 'pointer' }))
const TextContainer = styled.div(s('mb-4 bg-grey-100', { padding: 6 }))
const Text = styled.div(
  s('tet-sm text-grey-900 font-normal', { letterSpacing: '0.01em', lineHeight: 1.5 })
)

const ErrorModal = ({ isOpen, setIsOpen, errorMsg = '' }) => {
  const close = () => setIsOpen(false)

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <Container>
        <Header>
          <Title style={s('text-red')}>An error has ocurred</Title>
          <X onClick={close} />
        </Header>
        <TextContainer>
          <Text>{errorMsg}</Text>
        </TextContainer>
      </Container>
    </Modal>
  )
}

export default ErrorModal
