import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { normalizeError } from '@vega/services'

import { eligibilityVerificationService } from 'apiService'

export const fetchVerifications = createAsyncThunk(
  'eligibilityVerification/getEligibilityVerifications',
  async (_, { rejectWithValue }) => {
    try {
      return await eligibilityVerificationService.getEligibilityVerifications()
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const eligibilityVerificationAdapter = createEntityAdapter({
  selectId: (eligibilityVerification) => eligibilityVerification._id,
})

const initialState = eligibilityVerificationAdapter.getInitialState()

const eligibilityVerificationSlice = createSlice({
  name: 'eligibilityVerification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVerifications.fulfilled, (state, action) => {
        eligibilityVerificationAdapter.setAll(state, action.payload)
      })
  },
})

const { reducer: eligibilityVerificationReducer } = eligibilityVerificationSlice

export { eligibilityVerificationReducer }
