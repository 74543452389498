import React from 'react'
import { Col, Row } from '@vega/styled'
import { s } from '@vega/styled/v2'
import { Button, SelectField } from '@vega/components'

const GeographyValueFilterRow = ({ index, remove }) => {
  const poolParameterFilterTypes = [
    { label: 'Loans with number', value: 'numberValueFilter' },
    { label: 'Loans with yes or no', value: 'booleanValueFilter' },
    { label: 'Loans with geography', value: 'geographyValueFilter' },
  ]

  const geographies = [
    { label: 'Metro', value: 'metro' },
    { label: 'Non metro', value: 'nonMetro' },
    { label: 'Auckland', value: 'auckland' },
    { label: 'South island', value: 'southIsland' },
  ]

  return (
    <Row style={s('bg-grey-lightest p-4 rounded-lg mb-4')}>
      <Col span="5">
        <SelectField
          name={`filters.${index}.filterType`}
          label="Filter type"
          options={poolParameterFilterTypes}
          style={s('bg-white')}
          labelContainerStyle={s('bg-grey-lightest')}
        />
      </Col>

      <Col span="5">
        <SelectField
          name={`filters.${index}.filterInputs.geography`}
          label="Geography"
          options={geographies}
          style={s('bg-white')}
          labelContainerStyle={s('bg-grey-lightest')}
        />
      </Col>

      <Col span="14" style={s('flex')}>
        <Button
          variant="outlined"
          onClick={() => remove(index)}
          style={s('mt-8 ml-16')}
        >
          Remove filter
        </Button>
      </Col>
    </Row>
  )
}

export default GeographyValueFilterRow
