import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { s, styled } from '@vega/styled/v2'
import { keyframes } from '@vega/styled'
import { graphColors } from 'utils/graphColors'
import EditFundersCapacityModal from './EditFundersCapacityModal'
import { updateFunderDrawDowns } from 'modules/warehouse'
import { Button } from '@vega/components'
import { formatStringToNumber } from 'utils/formatters'
import { twoDecimalPointsIfNeeded } from 'utils/formatter'

const toMillionLabel = (amount) =>
  `${twoDecimalPointsIfNeeded(amount / 1000000)}m`

const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`

const PercentageBar = styled.div(
  s('relative w-full rounded-lg'),
  ({ filledPercentage, color }) => ({
    height: 10,
    backgroundColor: '#F5F5F5',
    overflow: 'hidden',

    // Filled percentage bar
    '&::before': s('absolute h-full rounded-sm', {
      content: '""',
      backgroundColor: color,
      transform: 'translateX(-100%)',
      animation: `800ms ${slideInFromLeft} cubic-bezier(0.66, 0, 0.2, 0.94) forwards`,
      width: `${filledPercentage}%`,
    }),
  })
)

const FundersCapacity = ({ warehouse }) => {
  const dispatch = useDispatch()
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  const handleEditSubmission = (data) => {
    const drawDowns = data.tranches.map((updatedTranche) => ({
      funder: updatedTranche.funder._id,
      drawnDown: formatStringToNumber(updatedTranche.drawnDown.toString()),
    }))

    dispatch(
      updateFunderDrawDowns({
        warehouseId: warehouse._id,
        drawDowns,
      })
    )
  }

  const capacities = warehouse.tranches.map((tranche, index) => {
    const percentage = (tranche.drawnDown / tranche.capacity) * 100

    return (
      <div key={tranche._id} style={s('flex mb-4')}>
        <div
          style={s(
            'border-solid border-2 border-grey-400 flex items-center justify-center rounded-lg w-4 h-4 text-center font-semibold align-items mr-4'
          )}
        >
          {toMillionLabel(tranche.capacity)}
        </div>

        <div style={s('p-2', { flexGrow: 1 })}>
          <PercentageBar filledPercentage={percentage} color={graphColors[index]} />

          <div style={s('flex w-full py-2')}>
            <div style={s('font-semibold', { flexGrow: 1 })}>
              {tranche.funder.organisationName}
            </div>

            <div style={s('font-bold ml-2 text-grey-600')}>
              {Math.round(percentage)}%
            </div>

            <div style={s('ml-2 text-grey-600')}>
              ({toMillionLabel(tranche.drawnDown)})
            </div>
          </div>
        </div>
      </div>
    )
  })

  return (
    <>
      <div style={s('bg-white rounded-lg p-4 shadow-lg', { flexGrow: 1 })}>
        {capacities}

        <div style={s('flex', { flexDirection: 'row-reverse' })}>
          <Button variant="outlined" onClick={() => setIsEditModalOpen(true)}>
            Edit funder draw downs
          </Button>
        </div>
      </div>

      <EditFundersCapacityModal
        isOpen={isEditModalOpen}
        setIsOpen={setIsEditModalOpen}
        handleSubmit={handleEditSubmission}
        tranches={warehouse.tranches}
      />
    </>
  )
}

export default FundersCapacity
