/* eslint-disable complexity */
import React, { useEffect, useState, useCallback } from 'react'
import { s, styled } from '@vega/styled/v2'
import { Formik, Form } from 'formik'
import {
  Button,
  NumberField,
  MenuField as MenuFieldBase,
  Menu,
  Loading,
} from '@vega/components'
import BackLink from 'components/BackLink'
import { Row, Col } from '@vega/styled'
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchWarehouse,
  fetchWarehouses,
  selectWarehouses,
  selectWarehouseById,
  validatePoolParameters,
  selectIsValidatePoolParametersPending,
  selectIsFetchWarehousePending,
} from 'modules/warehouse'
import { ContentCard } from '@vega/components/src/v2'
import userGroup from '@vega/components/src/assets/images/user-group.svg'
import clipBoard from '@vega/components/src/assets/images/clipboard.svg'
import { createTransfer } from 'modules/warehouseTransfers'
import LoansList from './LoansList'
import { formatCurrency } from 'utils/formatter'
import { formatStringToNumber } from 'utils/formatters' // TODO: WTF
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { Item } from '@react-stately/collections'
import { ReactComponent as Search } from '@vega/components/src/assets/images/search.svg'
import { ReactComponent as ResetIcon } from '@vega/components/src/assets/images/refresh.svg'
import ErrorModal from '../Details/ErrorModal'
import { SectionTitle } from 'components/SectionTitle'
import { ReactComponent as ChevronDown } from '@vega/components/src/assets/images/chevron-down.svg'
import { ReactComponent as ChevronUp } from '@vega/components/src/assets/images/chevron-up.svg'
import ParameterBulletGraph from '../../common/ParameterBulletGraph'
import { poolParametersTypes } from '../../warehouse/Parameters/poolParameters/utils'

const CustomerAndMortgageContainer = styled.div(
  s('flex justify-between bg-grey-lightest rounded-lg p-3 mt-4 w-full', {
    '@media (max-width: 1500px)': s('flex-column'),
  })
)

const ResetButton = styled(Button)(
  s(
    'bg-white border-solid border-1 border-grey-200 py-3 px-4 text-sm text-grey-600 rounded-lg'
  )
)

const MenuField = styled(MenuFieldBase, {
  shouldForwardProp: (prop) => prop !== 'side',
})(s('w-full', { minHeight: 55, marginTop: -0.5 }), ({ side }) =>
  side === 'left' ? s('mx-2') : s('mr-2')
)

const DisplayContainer = styled.div(
  s('bg-white mx-2 w-full rounded-lg p-4', {
    height: 116,
  })
)

const CircleContainer = styled.div(
  s('bg-secondary justify-center flex', {
    width: 36,
    height: 36,
    borderRadius: 50,
  })
)

const Title = styled.h3(s('p-0 m-0 font-semibold text-xl mt-0 ml-0 text-primary'))
const SubTitle = styled.h3(s('p-0 m-0 font-normal text-lg text-grey-600'))
const Img = styled.img(
  s('ml-a mr-a block w-1/2 h-1/2 self-center ', {
    display: 'block',
  })
)
const Column = styled.div(s('flex flex-column'))
const PercentageChange = styled.span(s('text-xl text-error-700 mr-1'))

const Input = styled.input(
  s(
    'pl-12 pr-4 pt-3 pb-3 w-full h-full border-solid border-1 border-grey-400 text-base rounded-lg text-grey-500',
    {
      backgroundcolor: 'white',
      '&:hover': s('border-1 border-primary', { cursor: 'pointer' }),
      ':focus-within': s('border-1 border-primary', {
        outline: '2px solid',
        outlineColor: s('text-primary').color,
      }),
    }
  )
)

const SearchBarContainer = styled.div(
  s('relative flex items-center w-full h-full rounded-lg  bg-green-50', {
    minHeight: '52px',
    whiteSpace: 'nowrap',
    outline: 'none',
  })
)

const SearchAndFiltersContainer = styled.div(
  s('flex my-4 justify-between', { gap: 16 })
)

const SearchIcon = styled(Search)(s('absolute ml-4'))

const loanBalanceOptions = [
  {
    value: '0-100',
    label: '$0 - $100k',
  },
  {
    value: '101-500',
    label: '$101k - $500k',
  },
  {
    value: '501-1000',
    label: '$501k - $1m',
  },
  {
    value: '1000+',
    label: '$1m+',
  },
]

const loanTermOptions = [
  {
    value: '1-5',
    label: '1-5 years',
  },
  {
    value: '6-10',
    label: '6-10 years',
  },
  {
    value: '11-15',
    label: '11-15 years',
  },
  {
    value: '16-20',
    label: '16-20 years',
  },
  {
    value: '21-25',
    label: ' 21-25 years',
  },
  {
    value: '26-30',
    label: '26-30 years',
  },
  {
    value: '30+',
    label: '30 years+',
  },
]

const TogglePreviewButton = styled.button(
  s(
    'bg-white border-1 border-primary rounded-lg text-primary font-semibold self-end text-center p-1 text-sm',
    { '&:hover': { cursor: 'pointer' } }
  )
)
const ChevronDownIcon = styled(ChevronDown)(
  s('ml-1', {
    cursor: 'pointer',
    path: { stroke: '#490B2D'.color },
  })
)
const ChevronUpIcon = styled(ChevronUp)(
  s('ml-1', {
    cursor: 'pointer',
    path: { stroke: '#490B2D'.color },
  })
)

function TransferNew() {
  const dispatch = useDispatch()
  const warehouses = useSelector(selectWarehouses)
  const [originatingWarehouseId, setOriginatingWarehouseId] = useState('')
  const [destinationWarehouseId, setDestinationWarehouseId] = useState('')
  const [filteredLoans, setfilteredLoans] = useState(null)
  const [filters, setFilters] = useState({
    loanBalance: null,
    product: null,
    loanTerm: null,
    searchTerm: null,
  })
  const [selectedLoans, setSelectedLoans] = useState(new Set())
  const originatingWarehouse = useSelector(selectWarehouseById(originatingWarehouseId))
  const history = useHistory()
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [isShowingPreview, setIsShowingPreview] = useState(false)
  const [parameterBulletGraphValues, setParameterBulletGraphValues] = useState([])
  const isValidatingParemetersPending = useSelector(
    selectIsValidatePoolParametersPending
  )
  const isFetchingWarehouse = useSelector(selectIsFetchWarehousePending)

  useEffect(() => {
    dispatch(fetchWarehouse(originatingWarehouseId))
  }, [dispatch, originatingWarehouseId])

  const initialValues = {
    funderAllocations: [],
    targetTransferAmount: '',
    originatingWarehouse: '',
    destinationWarehouse: '',
  }

  const checkloanBalance = (loanBalance, filter) => {
    switch (filter) {
      case '$0 - $100k':
        return loanBalance < 100000
      case '$101k - $500k':
        return loanBalance >= 100000 && loanBalance < 500000
      case '$501k - $1m':
        return loanBalance >= 500000 && loanBalance < 1000000
      case '$1m+':
        return loanBalance >= 1000000
      default:
        return false
    }
  }

  const checkLoanTerm = (loanTerm, filter) => {
    switch (filter) {
      case '1-5 years':
        return loanTerm >= 1 && loanTerm <= 5
      case '6-10 years':
        return loanTerm >= 6 && loanTerm <= 10
      case '11-15 years':
        return loanTerm >= 11 && loanTerm <= 15
      case '16-20 years':
        return loanTerm >= 16 && loanTerm <= 20
      case '21-25 years':
        return loanTerm >= 21 && loanTerm <= 25
      case '26-30 years':
        return loanTerm >= 26 && loanTerm <= 30
      case '30 years+':
        return loanTerm >= 31
      default:
        return false
    }
  }

  useEffect(() => {
    if (
      filters.loanBalance ||
      filters.product ||
      filters.loanTerm ||
      filters.searchTerm ||
      filters.searchTerm === ''
    ) {
      let filteredLoansResult = originatingWarehouse?.loans
      // Apply filter for loan balance
      if (filters.loanBalance) {
        filteredLoansResult = filteredLoansResult?.filter((loanGroup) => {
          const totalLoanBalance = loanGroup.loans.reduce(
            (sum, loan) => sum + loan.loanBalance,
            0
          )
          return checkloanBalance(totalLoanBalance, filters.loanBalance)
        })
      }
      // Apply filter for product
      if (filters.product) {
        filteredLoansResult = filteredLoansResult?.filter((loanGroup) =>
          loanGroup.loans.some((loan) => loan.productName === filters.product)
        )
      }
      // Apply filter for loan term
      if (filters.loanTerm) {
        filteredLoansResult = filteredLoansResult?.filter((loanGroup) =>
          loanGroup.loans.some((loan) => {
            return checkLoanTerm(loan.termInMonths / 12, filters.loanTerm)
          })
        )
      }
      if (filters.searchTerm) {
        filteredLoansResult = filteredLoansResult?.filter((loanGroup) => {
          return (
            loanGroup.loans.some((loan) => {
              return (
                loan.loanNumber
                  .toLowerCase()
                  .includes(filters.searchTerm.toLowerCase()) ||
                loan.city.toLowerCase().includes(filters.searchTerm.toLowerCase())
              )
            }) ||
            loanGroup.loanGroupNumber
              .toLowerCase()
              .includes(filters.searchTerm.toLowerCase())
          )
        })
      }

      setfilteredLoans(filteredLoansResult)
    }
  }, [filters, originatingWarehouse])

  const resetFilters = () => {
    setFilters({
      loanBalance: null,
      product: null,
      loanTerm: null,
      searchTerm: null,
    })
    document.getElementById('searchTerm').value = ''
    setfilteredLoans(originatingWarehouse?.loans || [])
  }

  const mortgageCount = (loans) => {
    return loans?.reduce((acc, loanGroup) => {
      return acc + loanGroup.loans.length
    }, 0)
  }

  const toggleLoans = (loanIds, shouldBeSelected) => {
    const newState = new Set(selectedLoans)

    if (shouldBeSelected) {
      loanIds.forEach((loanId) => newState.add(loanId))
    } else {
      loanIds.forEach((loanId) => newState.delete(loanId))
    }

    setSelectedLoans(newState)
  }

  const selectedLoansBalance = (originatingWarehouse?.loans || [])
    .filter((loanGroup) =>
      loanGroup.loans.every((loan) => selectedLoans.has(loan.loanId))
    )
    .reduce((runningTotal, loanGroup) => {
      const totalForLoans = loanGroup.loans.reduce(
        (acc, curr) => acc + curr.loanBalance,
        0
      )
      return runningTotal + totalForLoans
    }, 0)

  const percentageOfTarget = (target) => {
    if (!target || selectedLoansBalance === 0) {
      return 0
    }

    return Math.round((selectedLoansBalance / formatStringToNumber(target)) * 100)
  }
  const onSubmit = async (data) => {
    if (percentageOfTarget(data.targetTransferAmount) > 100) {
      setErrorMsg(
        'Balance Selected as a % of target transfer amount cannot exceed 100%'
      )
      setIsErrorModalOpen(true)
      return
    }

    if (data.originatingWarehouse === data.destinationWarehouse) {
      setErrorMsg(
        'The Originating Warehouse Must Be Different From The Destination Warehouse'
      )
      setIsErrorModalOpen(true)
      return
    }

    await dispatch(
      createTransfer({
        ...data,
        loans: Array.from(selectedLoans),
        targetTransferAmount: formatStringToNumber(data.targetTransferAmount),
      })
    )
    history.push(`/transfers/`)
  }

  const warehouseOptions = warehouses
    .filter((warehouse) => warehouse.currentState.state === 'ACTIVE')
    .map((warehouse) => ({
      value: warehouse._id,
      label: warehouse.name,
    }))

  useEffect(() => {
    dispatch(fetchWarehouses({ searchParams: {}, pageIndex: 0 }))
  }, [dispatch])

  const totalAvailableLoanBalance = (originatingWarehouse?.loans || []).reduce(
    (runningTotal, loanGroup) => {
      const totalLoansBalance = loanGroup.loans.reduce(
        (curr, acc) => curr + acc.loanBalance,
        0
      )

      return runningTotal + totalLoansBalance
    },
    0
  )

  const getProducts = (loanGroups = []) => {
    const uniqueProducts = [
      ...new Set(
        loanGroups.flatMap((loanGroup) =>
          loanGroup.loans.map((loan) => {
            return loan.productName
          })
        )
      ),
    ]

    return uniqueProducts.map((product) => ({ value: product, label: product }))
  }

  const handleFilterAction = (target) => (value) => {
    if (target === 'loanBalance') {
      const loanBalance = loanBalanceOptions.find((filter) => filter.value === value)
        .label
      setFilters({ ...filters, loanBalance })
    }

    if (originatingWarehouse?.loans && target === 'product') {
      const product = getProducts(originatingWarehouse?.loans).find(
        (filter) => filter.value === value
      )?.label
      setFilters({ ...filters, product })
    }

    if (target === 'loanTerm') {
      const loanTerm = loanTermOptions.find((filter) => filter.value === value).label
      setFilters({ ...filters, loanTerm })
    }
  }

  const onValidationPoolParemeters = useCallback(
    async (destinationWarehouseId) => {
      const { payload: destinationWarehouse } = await dispatch(
        fetchWarehouse(destinationWarehouseId)
      )
      const loanIds = destinationWarehouse?.loans?.flatMap((loanGroup) =>
        loanGroup?.loans?.map((loan) => loan?.loanId)
      )

      const { payload: currentPoolParemetersValidation } = await dispatch(
        validatePoolParameters({
          warehouseId: destinationWarehouseId,
          loanIds,
        })
      )

      let newPoolParemetersValidation = null

      if (Array.from(selectedLoans)?.length > 0) {
        const selectedLoansIds = Array.from(selectedLoans)
        newPoolParemetersValidation = await dispatch(
          validatePoolParameters({
            warehouseId: destinationWarehouseId,
            loanIds: [...selectedLoansIds, ...loanIds],
          })
        )
      }
      setParameterBulletGraphValues(
        currentPoolParemetersValidation?.poolParameterSummaries?.map((parameter) => {
          const newValue = newPoolParemetersValidation
            ? newPoolParemetersValidation?.payload?.poolParameterSummaries.find(
                (newParameter) =>
                  parameter?.poolParameterId === newParameter.poolParameterId
              )?.value
            : 0
          return {
            ...parameter,
            newValue,
          }
        })
      )
    },
    [dispatch, selectedLoans]
  )

  useEffect(() => {
    if (destinationWarehouseId) onValidationPoolParemeters(destinationWarehouseId)
  }, [dispatch, selectedLoans, destinationWarehouseId, onValidationPoolParemeters])

  const validationSchema = Yup.object().shape({
    targetTransferAmount: Yup.string().required('Target Transfer Amount is Required '),
    originatingWarehouse: Yup.string().required('Originating Warehouse is Required'),
    destinationWarehouse: Yup.string().required('Destination Warehouse is Required'),
  })

  const isPoolParameterPercentage = (valueToCheck) => {
    return poolParametersTypes
      .filter((item) => item.value === valueToCheck)
      .some((item) => item.suffix === '%')
  }

  return (
    <>
      <ErrorModal
        isOpen={isErrorModalOpen}
        setIsOpen={setIsErrorModalOpen}
        errorMsg={errorMsg}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, handleBlur, setFieldValue }) => (
          <Form>
            <BackLink toLink={'/transfers'} />
            <div style={s('flex justify-between')}>
              <h1 style={s('m-0')}>Transfer Builder</h1>

              <div style={s('flex my-2', { gap: '0.5rem' })}>
                <Button type="submit" variant="outlined">
                  Create Draft Transfer
                </Button>
              </div>
            </div>
            <Row>
              <Col span={8}>
                <NumberField
                  placeholder="Target Transfer Balance $"
                  name="targetTransferAmount"
                  prefix="$"
                  thousandSeparator
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={s('mb-6', { backgroundColor: '#F1F1E8' })}
                  inputContainerStyle={s('bg-white')}
                />
              </Col>
              <Col span={8}>
                <MenuField
                  name="originatingWarehouse"
                  placeholder="Originating Warehouse"
                  displayTextSelector={(value) => {
                    if (!value) return 'Originating Warehouse'
                    const { label } = warehouseOptions?.find(
                      (option) => option.value === value
                    )
                    return label
                  }}
                  onSelected={(value) => {
                    setFieldValue('originatingWarehouse', value)
                    setSelectedLoans(new Set())
                    setOriginatingWarehouseId(value)
                    setFilters({
                      loanBalance: null,
                      product: null,
                      loanTerm: null,
                      searchTerm: null,
                    })
                    setfilteredLoans(null)
                  }}
                >
                  {warehouseOptions.map((option) => (
                    <Item key={option.value}>{option.label}</Item>
                  ))}
                </MenuField>
              </Col>
              <Col span={8}>
                <MenuField
                  name="destinationWarehouse"
                  displayTextSelector={(value) => {
                    if (!value) return 'Destination Warehouse'
                    const { label } = warehouseOptions?.find(
                      (option) => option.value === value
                    )
                    return label
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onSelected={(value) => {
                    setFieldValue('destinationWarehouse', value)
                    setDestinationWarehouseId(value)
                  }}
                >
                  {warehouseOptions.map((option) => (
                    <Item key={option.value}>{option.label}</Item>
                  ))}
                </MenuField>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <CustomerAndMortgageContainer>
                  <DisplayContainer
                    style={s('bg-white rounded-lg p-', {
                      boxShadow:
                        '0px 2px 4px -1px rgba(21, 61, 58, 0.06), 0px 4px 6px -1px rgba(21, 61, 58, 0.1)',
                    })}
                  >
                    <Row style={s('items-start justify-between')}>
                      <Row style={s('mx-2')}>
                        <CircleContainer>
                          <Img src={userGroup} />
                        </CircleContainer>

                        <Col style={s('items-start mx-2 justify-start')}>
                          <SubTitle>Selected Mortgages</SubTitle>
                          <Title className="sohne" style={s('text-4xl')}>
                            {selectedLoans.size}
                          </Title>
                        </Col>
                      </Row>
                      <Row style={s('mx-2')}>
                        <CircleContainer>
                          <Img src={userGroup} />
                        </CircleContainer>

                        <Col style={s('items-start mx-2 justify-start')}>
                          <SubTitle>Balance Selected</SubTitle>
                          <Title className="sohne" style={s('text-4xl')}>
                            {formatCurrency(selectedLoansBalance)}
                          </Title>
                        </Col>
                      </Row>
                      <Column style={s('items-end mr-4')}>
                        <Row style={s('justify-end items-center')}>
                          <PercentageChange style={s('mb-1')}>
                            {percentageOfTarget(values.targetTransferAmount)}%
                          </PercentageChange>
                        </Row>
                      </Column>
                    </Row>
                  </DisplayContainer>

                  <DisplayContainer style={s('bg-grey-lightest')}>
                    <Row style={s('items-start justify-between')}>
                      <Row style={s('mx-2')}>
                        <CircleContainer style={s('bg-grey-200')}>
                          <Img src={clipBoard} style={s('self-center')} />
                        </CircleContainer>

                        <Col style={s('items-start mx-2 justify-between')}>
                          <SubTitle>Available Mortgages</SubTitle>
                          <Title className="sohne" style={s('text-4xl')}>
                            {mortgageCount(
                              filteredLoans || originatingWarehouse?.loans
                            ) || 0}
                          </Title>
                        </Col>
                      </Row>
                      <Row style={s('mx-2')}>
                        <CircleContainer style={s('bg-grey-200')}>
                          <Img src={clipBoard} style={s('self-center')} />
                        </CircleContainer>

                        <Col style={s('items-start mx-2 justify-between')}>
                          <SubTitle>Available Balance</SubTitle>
                          <Title className="sohne" style={s('text-4xl')}>
                            {formatCurrency(totalAvailableLoanBalance)}
                          </Title>
                        </Col>
                      </Row>
                    </Row>
                  </DisplayContainer>
                </CustomerAndMortgageContainer>
              </Col>
            </Row>
            <ContentCard style={s('mt-8')}>
              <Row>
                <Col span={24}>
                  <div style={s('flex flex-row mb-4')}>
                    {isShowingPreview ? (
                      <TogglePreviewButton
                        type="button"
                        style={s('border-transparent')}
                        onClick={() => setIsShowingPreview(!isShowingPreview)}
                      >
                        <ChevronUpIcon
                          width={20}
                          height={20}
                          style={{ marginLeft: -1, marginBottom: -5 }}
                        />
                      </TogglePreviewButton>
                    ) : (
                      <TogglePreviewButton
                        onClick={() => setIsShowingPreview(!isShowingPreview)}
                        type="button"
                      >
                        <ChevronDownIcon
                          width={20}
                          height={20}
                          style={{ marginLeft: -1, marginBottom: -5 }}
                        />
                      </TogglePreviewButton>
                    )}
                    <SectionTitle
                      title="Pool Parameters Preview"
                      style={s('mt-1 ml-2')}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  {isShowingPreview &&
                    (values.destinationWarehouse ? (
                      <>
                        {isValidatingParemetersPending || isFetchingWarehouse ? (
                          <Loading />
                        ) : (
                          <div
                            style={{
                              display: 'grid',
                              gridTemplateColumns:
                                parameterBulletGraphValues?.length > 1
                                  ? '1fr 1fr'
                                  : '1fr',
                              gap: '20px',
                            }}
                          >
                            {parameterBulletGraphValues?.length > 0 &&
                              parameterBulletGraphValues?.map((parameter) => (
                                <ParameterBulletGraph
                                  key={parameter.label}
                                  label={parameter.filtersDescription}
                                  lowerLimit={
                                    isPoolParameterPercentage(
                                      parameter.parameterTypeDescription
                                    )
                                      ? 0
                                      : parameter.lowerLimit
                                  }
                                  upperLimit={parameter.upperLimit}
                                  currentValue={parameter.value}
                                  newValue={parameter.newValue}
                                  paremeterTypeDescripition={
                                    parameter.parameterTypeDescription
                                  }
                                />
                              ))}
                          </div>
                        )}
                      </>
                    ) : (
                      <h3>Please select a destination warehouse.</h3>
                    ))}
                </Col>
              </Row>
            </ContentCard>

            <SearchAndFiltersContainer>
              <SearchBarContainer>
                <SearchIcon height={20} width={20} />
                <Input
                  name="search"
                  id="searchTerm"
                  placeholder="Search by Loan group number, Loan number or Location..."
                  onChange={(e) =>
                    setFilters({ ...filters, searchTerm: e.target.value })
                  }
                  type="search"
                />
              </SearchBarContainer>
              <Menu
                name="product"
                label={filters?.product || 'Products'}
                onAction={handleFilterAction('product')}
                style={s('bg-white rounded-lg', {
                  minWidth: '195px',
                  maxWidth: '195px',
                })}
              >
                {getProducts(originatingWarehouse?.loans).map((option) => (
                  <Item key={option.value} value={option.value}>
                    {option.label}
                  </Item>
                ))}
              </Menu>
              <Menu
                name="loanBalance"
                label={filters.loanBalance || 'Loan Balance'}
                onAction={handleFilterAction('loanBalance')}
                style={s('bg-white rounded-lg', {
                  minWidth: '195px',
                  maxWidth: '195px',
                })}
              >
                {loanBalanceOptions.map((option) => (
                  <Item key={option.value} value={option.value}>
                    {option.label}
                  </Item>
                ))}
              </Menu>
              <Menu
                name="term"
                label={filters.loanTerm || 'Term'}
                onAction={handleFilterAction('loanTerm')}
                style={s('bg-white rounded-lg', {
                  minWidth: '195px',
                  maxWidth: '195px',
                })}
              >
                {loanTermOptions.map((option) => (
                  <Item key={option.value} value={option.value}>
                    {option.label}
                  </Item>
                ))}
              </Menu>

              <ResetButton
                type="button"
                onClick={resetFilters}
                StartIcon={<ResetIcon style={s('w-1 h-1 text-grey-400 mr-1')} />}
              >
                Reset
              </ResetButton>
            </SearchAndFiltersContainer>
            <LoansList
              loanGroups={filteredLoans || originatingWarehouse?.loans || []}
              selectedLoans={selectedLoans}
              toggleLoans={toggleLoans}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

export { TransferNew }
