import React from 'react'
import { useSelector } from 'react-redux'
import { PersistentDrawer, PersistentNavItem } from 'features/common/NavigationDrawer'
import { ReactComponent as WarehouseIcon } from '@vega/components/src/assets/images/warehouse.svg'
import { ReactComponent as MoneyIcon } from '@vega/components/src/assets/images/money.svg'
import { ReactComponent as ClipboardIcon } from '@vega/components/src/assets/images/clipboard.svg'

import { selectIsPersistentNavDrawerOpen } from 'modules/ui'
import { s } from '@vega/styled/v2'

import { routes } from 'routes'
import { ReactComponent as ExitIcon } from '@vega/components/src/assets/images/exit.svg'
import { handleSignOut } from '@vega/components'

function NavigationDrawer() {
  const isNavDrawerOpen = useSelector(selectIsPersistentNavDrawerOpen)

  if (!isNavDrawerOpen) return null
  return (
    <PersistentDrawer homeRoute={routes.root} style={s('relative z-2')}>
      <PersistentNavItem
        tooltipLabel="Warehouses"
        route={'/warehouses'}
        Icon={WarehouseIcon}
      />
      <PersistentNavItem tooltipLabel="Funders" route={'/funders'} Icon={MoneyIcon} />
      <PersistentNavItem
        tooltipLabel="Transfers"
        route={'/transfers'}
        Icon={ClipboardIcon}
      />
      <PersistentNavItem
        tooltipLabel="Log Out"
        onClick={handleSignOut}
        Icon={ExitIcon}
        style={s('bg-accent-pink w-3 h-3 rounded-lg mt-a mb-16')}
      />
    </PersistentDrawer>
  )
}

export default NavigationDrawer
