import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { createFunder } from 'modules/funder'
import FunderForm from 'components/FunderForm'

export default function Create() {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleSubmit = async (data) => {
    await dispatch(createFunder(data))
    history.push('/funders')
  }

  return (
    <FunderForm
      formTitle="Create a Funder"
      initialValues={{
        organisationName: '',
        contactFirstName: '',
        contactLastName: '',
        contactEmail: '',
        contactPhone: '',
      }}
      onSubmit={handleSubmit}
      submitTitle="Create"
      cancelUrl="/funders"
    />
  )
}
