/* eslint-disable complexity */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { s, styled } from '@vega/styled/v2'
import {
  ContentCard as ContentCardBase,
  SectionTitle as SectionTitleBase,
  DataDisplay,
} from '@vega/components/src/v2'
import Button from 'components/Button'
import BackLink from 'components/BackLink'
import { Container, Row, Col } from '@vega/styled'

import { selectFunderById, fetchFunder, archiveFunder } from 'modules/funder'
import { Loading } from '@vega/components'
import FunderStatus from 'components/FunderStatus'

const ContentCard = styled(ContentCardBase)(s('mb-9 p-5'))

const SectionTitle = styled(SectionTitleBase)(s('mb-4'))

const Label = styled.div(
  s('text-sm font-normal', {
    color: '#515856',
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)

const Value = styled.div(
  s('text-base font-semibold', {
    color: '#282C2B',
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)

const FunderName = styled(SectionTitleBase)(s('text-3xl'))

export default function Details() {
  const { id } = useParams()
  const funder = useSelector(selectFunderById(id))

  const dispatch = useDispatch()
  const [hasFirstFetchFulfilled, setHasFirstFetchFulfilled] = useState(false)

  useEffect(() => {
    dispatch(fetchFunder(id))
  }, [dispatch, id])

  useEffect(() => {
    if (funder) {
      setHasFirstFetchFulfilled(true)
    }
  }, [funder])

  const isArchived = funder?.archivedAt !== undefined

  if (!hasFirstFetchFulfilled) return <Loading />

  const handleFunderArchive = () => {
    dispatch(archiveFunder(id))
  }

  return (
    <Container>
      <div style={s('mb-12')}>
        <BackLink toLink={'/funders'} />
      </div>
      <Row>
        <Col span={12}>
          <FunderName title={funder?.organisationName} />
          <div style={s('mt-2 mb-6 flex')}>
            <FunderStatus funder={funder} />
          </div>
        </Col>
        <Col span={12}>
          {!isArchived && (
            <div style={s('flex flex-row justify-end', { gap: '0.5rem' })}>
              <Link to={`/funders/${id}/edit`} style={s('no-decoration')}>
                <Button>Edit funder</Button>
              </Link>
              <Button onClick={handleFunderArchive}>Archive funder</Button>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={20}>
          <SectionTitle title="Funder Contact" />
          <ContentCard>
            <Row>
              <Col span={16}>
                <div style={s('flex flex-row')}>
                  <Label>Contact Name</Label>
                </div>
                <Value>
                  {`${funder.contactFirstName} ${funder?.contactLastName}` || '-'}
                </Value>
              </Col>
              <Col span={16}>
                <div style={s('flex flex-row')}>
                  <Label>Email Address</Label>
                </div>
                <Value>{funder.contactEmail || '-'}</Value>
              </Col>
              <Col span={16}>
                <DataDisplay
                  label="Funder Id"
                  value={funder.funderNumber || funder._id}
                />
              </Col>
              <Col span={16}>
                <div style={s('flex flex-row')}>
                  <Label>Contact Phone</Label>
                </div>
                <Value>{funder.contactPhone}</Value>
              </Col>
            </Row>
          </ContentCard>
        </Col>
      </Row>
    </Container>
  )
}
