import React from 'react'

import { s } from '@vega/styled/v2'

import { currentStatusForWarehouse, pendingStatusForWarehouse } from './utils'

import { ReactComponent as ArrowRight } from '@vega/components/src/assets/images/arrow-right.svg'

const WarehouseStatus = ({ warehouse }) => {
  const currentState = currentStatusForWarehouse(warehouse)
  const currentColor = currentState === 'ACTIVE' ? 'bg-green-100' : 'bg-accent-red'

  const pendingState = pendingStatusForWarehouse(warehouse)
  const pendingColor = pendingState === 'ACTIVE' ? 'bg-green-100' : 'bg-accent-red'

  return (
    <div style={s('flex items-center')}>
      <span
        style={s(
          `rounded-2xl px-3 py-1 h-2 flex items-center justify-center font-semibold ${currentColor}`
        )}
      >
        {currentState}
      </span>
      {warehouse.pendingState && (
        <>
          <ArrowRight style={s('h-1')} />
          <span
            style={s(
              `rounded-2xl px-3 py-1 h-2 flex items-center justify-center font-semibold ${pendingColor}`
            )}
          >
            {pendingState}
          </span>
          (Pending approval)
        </>
      )}
    </div>
  )
}

export default WarehouseStatus
