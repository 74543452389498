import React from 'react'
import { s } from '@vega/styled/v2'

const Table = ({ titles, rows }) => {

  const titlesHeaders = titles.map((title) => (
    <th
      key={title}
      style={s('text-grey-600 font-semibold text-left p-3 text-xs tracking-wide')}
    >
      {title}
    </th>
  ))

  const styledRows = rows.map((row) => (
    <tr
      key={row.key}
      style={{
        backgroundColor: 'rgb(255, 255, 255)',
        borderStyle: 'solid',
        borderWidth: '0px 0px 1px',
        borderColor: 'rgb(228, 230, 230)'
      }}
    >
      {row.data.map((datum, index) => (
        <td
          key={index}
          style={s('text-left px-3 text-sm')}
        >
          {datum}
        </td>
      ))}
    </tr>
  ))


  return (
    <table style={s('w-full bg-white rounded-lg')}>
      <thead>
        <tr>
          {titlesHeaders}
        </tr>
      </thead>
      <tbody>
        {styledRows}
      </tbody>
    </table>
  )
}

export default Table
