import React from 'react'
import NumberValueFilterRow from './NumberValueFilterRow'
import BooleanValueFilterRow from './BooleanValueFilterRow'
import GeographyValueFilterRow from './GeographyValueFilterRow'

const FilterRow = ({ filter, index, remove }) => {
  switch (filter.filterType) {
    case 'numberValueFilter':
      return <NumberValueFilterRow index={index} remove= {remove} />
    case 'booleanValueFilter':
      return <BooleanValueFilterRow index={index} remove= {remove} />
    case 'geographyValueFilter':
      return <GeographyValueFilterRow index={index} remove= {remove} />
    default: return null
  }
}

export default FilterRow
