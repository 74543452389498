/* eslint-disable complexity */
import React from 'react'
import { styled, s } from '@vega/styled/v2'
import { poolParametersTypes } from '../warehouse/Parameters/poolParameters/utils'
import { formatCurrency } from 'utils'

const Container = styled.div(
  s('', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '20px',
  })
)

const Label = styled.span(
  s('', {
    fontBold: true,
    marginBottom: '20px',
  })
)

const ParameterBulletGraph = styled.div(
  s('', {
    display: 'flex',
    alignItems: 'center',
    height: '15px',
  }),
  ({ upperLimit, maximumLimit }) => ({
    backgroundColor: maximumLimit > upperLimit ? '#798582' : '#eee',
    position: 'relative',
    width: '100%',
  })
)

const Background = styled.div(
  s('', {
    flex: 1,
    height: '100%',
    position: 'relative',
  })
)

const Threshold = styled.div(
  s('', {
    height: '100%',
    position: 'absolute',
    backgroundColor: '#490B2D',
  }),
  ({ width, left }) => ({
    width,
    left,
  })
)

const CurrentValueLine = styled.div(
  s('', {
    position: 'absolute',
    height: '100%',
    width: '4px',
    backgroundColor: '#F9C2E8',
  }),
  ({ left }) => ({ left })
)

const NewValueLine = styled.div(
  s('', {
    position: 'absolute',
    height: '100%',
    width: 4,
  }),
  ({ left }) => ({ left }),
  ({ newValue }) => ({
    backgroundColor: newValue === 0 ? 'transparent' : '#FAF54F',
  })
)

const CurrentValueLabel = styled.div(
  s('', {
    position: 'absolute',
    top: -20,
    transform: 'translateX(-50%)',
    color: '#333',
    padding: 4,
    borderRadius: 4,
    fontSize: 12,
  }),
  ({ left }) => ({ left })
)

const NewValueLabel = styled.div(
  s('', {
    position: 'absolute',
    bottom: -20,
    transform: 'translateX(-50%)',
    padding: 4,
    borderRadius: 4,
    fontSize: 12,
  }),
  ({ left }) => ({ left }),
  ({ newValue }) => ({
    color: newValue === 0 ? 'transparent' : '#490B2D',
  })
)

const LowerLimitLabel = styled.div(
  s('', {
    position: 'absolute',
    top: -20,
    transform: 'translateX(-50%)',
    color: '#333',
    padding: 4,
    borderRadius: 4,
    fontSize: 12,
    fontWeight: 600,
  }),
  ({ left }) => ({ left })
)

const UpperLimitLabel = styled.div(
  s('', {
    position: 'absolute',
    top: -20,
    transform: 'translateX(-50%)',
    color: '#333',
    padding: 4,
    borderRadius: 4,
    fontSize: 12,
    fontWeight: 600,
  }),
  ({ left }) => ({ left })
)

const isPoolParameterPercentage = (valueToCheck) => {
  return poolParametersTypes
    .filter((item) => item.value === valueToCheck)
    .some((item) => item.suffix === '%')
}

const BulletGraphComponent = ({
  label,
  lowerLimit,
  upperLimit,
  currentValue,
  newValue = 0,
  paremeterTypeDescripition,
}) => {
  const actuallyLowest = lowerLimit
  const actuallyHighest = upperLimit
  const isPercentage = isPoolParameterPercentage(paremeterTypeDescripition)
  const range = actuallyHighest - actuallyLowest
  const buffer = range * 0.1
  const newLowerLimit = actuallyLowest - buffer
  const newUpperLimit = actuallyHighest + buffer
  const newRange = newUpperLimit - newLowerLimit
  const newThreashholdWidth = isPercentage ? 100 : (range / newRange) * 100
  const newThreashholdLeft = isPercentage ? 0 : (buffer / newRange) * 100
  const newThreashholdRight = isPercentage
    ? 100
    : ((newRange - buffer) / newRange) * 100

  let newCurrentValuePosition = currentValue - newLowerLimit

  newCurrentValuePosition =
    currentValue >= newUpperLimit
      ? newUpperLimit - newLowerLimit
      : newCurrentValuePosition

  newCurrentValuePosition =
    currentValue <= newLowerLimit
      ? newLowerLimit - newLowerLimit
      : newCurrentValuePosition

  const newCurrentValuePercentage = isPercentage
    ? currentValue
    : (newCurrentValuePosition / newRange) * 100

  let newValuePosition = newValue - newLowerLimit
  newValuePosition =
    newValue >= newUpperLimit ? newUpperLimit - newLowerLimit : newValuePosition

  newValuePosition =
    newValue <= newLowerLimit ? newLowerLimit - newLowerLimit : newValuePosition

  const newValuePercentage = isPercentage
    ? newValue
    : (newValuePosition / newRange) * 100

  const maximumLimit = isPercentage ? 100 : Math.max(upperLimit, currentValue) * 1.1

  return (
    <Container
      style={{
        border: '2px solid #D2D2B0',
        borderRadius: '10px',
        padding: '20px',
      }}
    >
      <Label>{label}</Label>
      <ParameterBulletGraph
        lowerLimit={newLowerLimit}
        upperLimit={newUpperLimit}
        maximumLimit={maximumLimit}
      >
        <Background>
          <Threshold
            width={`${newThreashholdWidth}%`}
            left={`${newThreashholdLeft}%`}
          />
          <CurrentValueLine left={`${newCurrentValuePercentage}%`} />
          {newValue > 0 && <NewValueLine left={`${newValuePercentage}%`} />}
          <CurrentValueLabel left={`${newCurrentValuePercentage}%`}>
            {isPercentage
              ? `Current - ${Math.round(currentValue)}%`
              : `Current - ${formatCurrency(currentValue)}`}
          </CurrentValueLabel>
          {newValue > 0 && (
            <NewValueLabel left={`${newValuePercentage}%`}>
              {isPercentage
                ? `New - ${Math.round(newValue)}%`
                : `New - ${formatCurrency(newValue)}`}
            </NewValueLabel>
          )}

          <LowerLimitLabel left={`${newThreashholdLeft}%`}>
            {isPercentage ? `${lowerLimit}%` : formatCurrency(lowerLimit)}
          </LowerLimitLabel>

          <UpperLimitLabel left={`${newThreashholdRight}%`}>
            {isPercentage ? `${100}%` : formatCurrency(upperLimit)}
          </UpperLimitLabel>
        </Background>
      </ParameterBulletGraph>
    </Container>
  )
}
export default BulletGraphComponent
