import React from 'react'
import {
  formatCurrency,
  formatPercentage,
  twoDecimalPointsIfNeeded,
} from 'utils/formatter'
import Table from 'components/Table'

const PoolCut = ({ warehouse }) => {
  const rows = warehouse.tranches.map((tranche) => ({
    key: tranche._id,
    data: [
      '-',
      tranche.funder.organisationName,
      formatPercentage(
        twoDecimalPointsIfNeeded(tranche.costOfFunds * 100)
      ),
      formatCurrency(tranche.capacity),
      formatCurrency(tranche.capacity),
    ]
  }))

  return (
    <>
      <h2>Funder details</h2>
      <Table
        titles={[
          'Funder priority',
          'Funder Name',
          'Interest rate',
          'Capacity',
          'Balance',
        ]}
        rows={rows}
      />
    </>
  )
}

export default PoolCut
