import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { updateEligibilityCriteria, deleteEligibilityCriteria } from 'modules/warehouse'
import { s } from '@vega/styled/v2'
import EditEligibilityCriterionModal from './EditEligibilityModal'
import { Button } from '@vega/components'
import { formattedRuleType, descriptionForCriterion } from './common'
import { ContentCard } from '../styles'

export const EligibilityCriteriaTable = ({ eligibilityCriteria, isEditable, eligibilityVerifications }) => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [currentCriterion, setCurrentCriterion] = useState()

  if (eligibilityCriteria.length === 0) {
    return (
      <p style={s('text-sm font-semibold text-grey-600')}>
        No eligibility criteria currently recorded
      </p>
    )
  }

  const rows = eligibilityCriteria.map((criterion) => (
    <tr key={criterion._id}>
      <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
        {formattedRuleType(criterion.ruleType)}
      </td>

      <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
        {descriptionForCriterion(criterion, eligibilityVerifications)}
      </td>

      <td
        style={s('py-2 flex flex-row-reverse space-x-4', {
          borderBottom: '1px solid #F1F5F9',
        })}
      >
        {
          isEditable && (
            <>
              <button
                onClick={() => {
                  dispatch(
                    deleteEligibilityCriteria({
                      warehouseId: id,
                      paremeterId: criterion._id,
                    })
                  )
                }}
                style={s(
                  'bg-grey-200 rounded-lg text-grey-600 border-0 font-bold px-2 py-1',
                  { cursor: 'pointer' }
                )}
              >
                X
              </button>

              <Button
                style={s('mr-2')}
                onClick={() => {
                  setCurrentCriterion(criterion)
                  setIsEditModalOpen(true)
                }}
              >
                Edit
              </Button>
            </>
          )
        }
      </td>
    </tr>
  ))

  return (
    <>
      <ContentCard>
        <table style={s('w-full')}>
          <thead style={s('p-0 text-grey-800 text-left text-xs font-semibold')}>
            <tr>
              <th>Criteria rule type</th>
              <th>Description</th>
              <th />
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </ContentCard>

      <EditEligibilityCriterionModal
        isOpen={isEditModalOpen}
        setIsOpen={setIsEditModalOpen}
        criterion={currentCriterion}
        eligibilityVerifications={eligibilityVerifications}
        handleSubmit={(data) => {
          dispatch(
            updateEligibilityCriteria({
              warehouseId: id,
              parameterId: data._id,
              payload: data
            })
          )
        }}
      />
    </>
  )
}
