import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider, breakpoints } from '@vega/styled'
import { AccessibilityProvider } from '@vega/components'
import './index.css'
import store from './app/store'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { AuthSdk as Wallet } from '@midas/idkit'
import { AccessController } from '@neo/app-access-controller'
import { USER_ROLES } from '@neo/constants'
import { APPS } from '@vega/constants'

const { SUPER_ADMIN, TREASURY_ADMIN } = USER_ROLES

const region = process.env.REACT_APP_AUTH_REGION
const userPoolId = process.env.REACT_APP_AUTH_USER_POOL_ID
const userPoolWebClientId = process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID
const identityPoolId = process.env.REACT_APP_AUTH_IDENTITY_POOL_ID

Wallet.configure({
  Auth: {
    region,
    userPoolId,
    userPoolWebClientId,
    identityPoolId,
  },
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={{ breakpoints }}>
        <BrowserRouter>
          <AccessibilityProvider>
            <AccessController
              targetAppName={APPS.TREASURY}
              permittedRoles={[SUPER_ADMIN, TREASURY_ADMIN]}
            >
              <App />
            </AccessController>
          </AccessibilityProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
