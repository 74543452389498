import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { s } from '@vega/styled/v2'

const WarehouseTab = ({ id, subroute, title }) => {
  const currentLocation = useLocation()
  const currentSubroute = currentLocation.pathname.split('/').at(-1)

  const activeStyle = s(
    'rounded-lg px-16 py-2 font-semibold text-lg no-decoration w-fit',
    { color: '#0F172A', backgroundColor: '#F1F1E8' }
  )

  const inactiveStyle = s(
    'px-16 py-2 font-semibold text-lg no-decoration',
    { color: '#64748B'}
  )

  return (
    <Link
      style={currentSubroute === subroute ? activeStyle : inactiveStyle}
      to={`/warehouses/${id}/${subroute}`}
    >
      {title}
    </Link>
  )
}

export default WarehouseTab
