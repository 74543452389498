import BaseService from '../baseService'
import { QueryBuilder } from '../utils'

export class WarehouseTransfersService extends BaseService {
  getTransfers({ q, start, limit, filters = {} }, signal) {
    const searchParams = QueryBuilder()
      .setQ(q)
      .setPaging({ start, limit })
      .setFilters(filters)
      .build()
    return this.client.get(`warehouse-transfers?${searchParams}`, { signal }).json()
  }

  createTransfer(payload) {
    return this.client.post('warehouse-transfers', { json: payload }).json()
  }

  getTransfer(id) {
    return this.client.get(`warehouse-transfers/${id}`).json()
  }

  deleteTransfer(id) {
    return this.client.delete(`warehouse-transfers/${id}`).json()
  }

  updateTransfer(id, payload) {
    return this.client.put(`warehouse-transfers/${id}`, { json: payload }).json()
  }

  updateTransferStatus(id, status) {
    return this.client.put(`warehouse-transfers/${id}/status?status=${status}`).json()
  }
}
