import { prop } from '@neo/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'
import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'
import { funderAdapter } from './module'

const selectAllFunders = prop('funder')
export const selectTotal = createSelector(selectAllFunders, prop('total'))

export const {
  selectIsPending: selectIsFetchFundersPending,
} = createAsyncThunkSelectors('funders/getFunders')

export const {
  selectIsPending: selectIsFetchFunderPending,
  selectHasFulfilled: selectHasFetchFunderFulfilled,
} = createAsyncThunkSelectors('funders/getFunder')

export const {
  selectAll: selectFunders,
  selectById: selectFunder,
} = funderAdapter.getSelectors((s) => s.funder)

export const selectFunderById = (id) => (state) => selectFunder(state, id)
