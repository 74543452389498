import { prop } from '@neo/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'
import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'
import { warehouseAdapter } from './module'

const selectAllWarehouses = prop('warehouse')
export const selectTotal = createSelector(selectAllWarehouses, prop('total'))

export const {
  selectIsPending: selectIsFetchWarehousePending,
  selectHasFulfilled: selectHasFetchWarehouseFulfilled,
  selectError: selectFetchWarehouseError,
} = createAsyncThunkSelectors('warehouse/getWarehouse')

export const {
  selectIsPending: selectIsFetchWarehousesPending,
} = createAsyncThunkSelectors('warehouse/getWarehouses')

export const {
  selectIsPending: selectIsCreatePoolParametersPending,
} = createAsyncThunkSelectors('warehouse/createPoolParameters')

export const {
  selectIsPending: selectIsValidatePoolParametersPending,
} = createAsyncThunkSelectors('warehouse/validatePoolParameters')

export const {
  selectIsPending: selectIsCreateEligibilityCriteria,
} = createAsyncThunkSelectors('warehouse/createEligibilityCriteria')

export const { selectIsPending: selectIsUpdatingWarehouse } = createAsyncThunkSelectors(
  'warehouse/updateWarehouse'
)

export const {
  selectAll: selectWarehouses,
  selectById: selectWarehouse,
} = warehouseAdapter.getSelectors((s) => s.warehouse)

export const selectWarehouseById = (id) => (state) => selectWarehouse(state, id)
