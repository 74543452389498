import {
  formatPercentage,
  twoDecimalPointsIfNeeded,
  formatCurrency
} from 'utils/formatter'

export const poolParametersTypes = [
  {
    label: 'Percentage of loans',
    value: 'percentageOfLoans',
    prefix: '',
    suffix: '%',
    thousandSeparator: false,
  },
  {
    label: 'Average loan size',
    value: 'averageLoanSize',
    prefix: '$',
    suffix: '',
    thousandSeparator: true,
  },
]

export const numberFieldLabel = (numberField) => {
  const match = poolParametersTypes.find(
    (parameterType) => parameterType.value === numberField
  )

  return match ? match.label : '-'
}

export const thresholdLabel = (poolParameter) => {
  if (poolParameter.numberField === 'percentageOfLoans') {
    const lower = formatPercentage(twoDecimalPointsIfNeeded(poolParameter.lowerLimit))
    const upper = formatPercentage(twoDecimalPointsIfNeeded(poolParameter.upperLimit))

    return `${lower} - ${upper}`
  }

  if (poolParameter.numberField === 'averageLoanSize') {
    const lower = formatCurrency(poolParameter.lowerLimit)
    const upper = formatCurrency(poolParameter.upperLimit)

    return `${lower} - ${upper}`
  }

  return '-'
}

/* eslint-disable complexity */
const numberValueFilterLabel = (filter) => {
  const { direction, numberField, value } = filter.filterInputs

  const directionLabel = direction === 'min' ? 'minimum' : 'maximum'

  let numberFieldLabel = numberField
  switch (numberField) {
    case 'loanSize':
      numberFieldLabel = 'average loan size'
      break
    case 'lvr':
      numberFieldLabel = 'LVR'
      break
    case 'interestOnlyPeriod':
      numberFieldLabel = 'interest only period'
      break
    default:
      numberFieldLabel = numberField
  }

  let valueLabel = value
  switch (numberField) {
    case 'loanSize':
      valueLabel = formatCurrency(value)
      break
    case 'lvr':
      valueLabel = formatPercentage(twoDecimalPointsIfNeeded(value))
      break
    case 'interestOnlyPeriod':
      valueLabel = `${value} ${value > 1 ? 'years': 'year'}`
      break
    default:
      valueLabel = value
  }

  return `Loans with a ${directionLabel} ${numberFieldLabel} of ${valueLabel}`
}

const booleanValueFilterLabel = (filter) => {
  const { booleanField, expectedValue } = filter.filterInputs
  return `Loans where ${booleanField} is marked as ${expectedValue}`
}

const geographyValueFilterLabel = (filter) =>
  `Loans in ${filter.filterInputs.geography}`

export const filterLabel = (filter) => {
  switch (filter.filterType) {
    case 'numberValueFilter':
      return numberValueFilterLabel(filter)
    case 'booleanValueFilter':
      return booleanValueFilterLabel(filter)
    case 'geographyValueFilter':
      return geographyValueFilterLabel(filter)
    default:
      return '-'
  }
}
