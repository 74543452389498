import React from 'react'
import { FieldArray } from 'formik'
import { Col, Row } from '@vega/styled'
import FilterRow from './FilterRow'
import AddFilterButton from './AddFilterButton'

const PoolParameterFilters = ({ values }) => {
  return (
    <Row>
      <Col span="24">
        <p>Filters</p>

        <FieldArray
          name="filters"
          render={(arrayHelpers) => (
            <>
              {
                values.filters && values.filters.length > 0 ?  (
                  <>
                    {
                      values.filters.map((filter, index) =>
                        <FilterRow
                          key={index}
                          filter={filter}
                          index={index}
                          remove={arrayHelpers.remove}
                        />
                      )
                    }

                    <AddFilterButton arrayHelpers={arrayHelpers} />
                  </>

                ) : (
                  <>
                    <p>No filters</p>
                    <AddFilterButton arrayHelpers={arrayHelpers} />
                  </>
                )
              }
            </>
          )}
        />
      </Col>
    </Row>
  )
}

export default PoolParameterFilters
