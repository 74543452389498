import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { normalizeError } from '@vega/services'
import { thunkErrorProcessor } from '@vega/error-standardizer'

import { funderService } from 'apiService'

export const fetchFunders = createAsyncThunk(
  'funder/getFunders',
  async ({ searchParams, pageIndex }, { rejectWithValue, signal }) => {
    try {
      const { searchTerm: q, filters = {}, sorting = {}, limit = 20 } = searchParams
      return await funderService.getFunders(
        {
          q,
          filters,
          limit,
          sorting,
          start: limit * pageIndex,
        },
        signal
      )
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const fetchFunder = createAsyncThunk(
  'funder/getFunder',
  async (id, { rejectWithValue }) => {
    try {
      const funder = await funderService.getFunder(id)
      return funder
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const createFunder = createAsyncThunk(
  'funder/createFunder',
  async (payload, { rejectWithValue }) => {
    try {
      return await funderService.createFunder(payload)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const updateFunder = createAsyncThunk(
  'funder/updateFunder',
  async ({ funderId, data }, { rejectWithValue }) => {
    try {
      const updatedfunder = await funderService.updateFunder(funderId, data)

      return updatedfunder
    } catch (err) {
      const error = await thunkErrorProcessor(err)
      return rejectWithValue(error)
    }
  }
)

export const archiveFunder = createAsyncThunk(
  'funder/archive',
  async (funderId, { rejectWithValue }) => {
    try {
      const archivedFunder = await funderService.archiveFunder(funderId)

      return archivedFunder
    } catch (err) {
      const error = await thunkErrorProcessor(err)
      return rejectWithValue(error)
    }
  }
)

export const funderAdapter = createEntityAdapter({
  selectId: (funder) => funder._id,
})

const initialState = funderAdapter.getInitialState()

const funderSlice = createSlice({
  name: 'funder',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFunders.fulfilled, (state, action) => {
        const { items: funders, pagination } = action.payload
        funderAdapter.setAll(state, funders)
        state.total = pagination.total
      })
      .addCase(createFunder.fulfilled, (state, action) => {
        funderAdapter.upsertOne(state, action.payload)
      })
      .addCase(fetchFunder.fulfilled, (state, action) => {
        funderAdapter.upsertOne(state, action.payload)
      })
      .addCase(updateFunder.fulfilled, (state, action) => {
        funderAdapter.upsertOne(state, action.payload)
      })
  },
})

const { reducer: funderReducer } = funderSlice

export { funderReducer }
