import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { isActive } from '../warehouse/utils'

import {
  fetchWarehouse,
  selectWarehouseById,
  selectIsFetchWarehousePending,
  updateWarehouse,
} from 'modules/warehouse'

import { Loading } from '@vega/components'
import WarehouseForm from 'components/WarehouseForm'
import BackLink from 'components/BackLink'

const EditWarehouse = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const warehouse = useSelector(selectWarehouseById(id))
  const isFetchingWarehouse = useSelector(selectIsFetchWarehousePending)
  const history = useHistory()

  useEffect(() => {
    dispatch(fetchWarehouse(id))
  }, [dispatch, id])

  const handleSubmit = (data) => {
    dispatch(updateWarehouse({ warehouseId: id, data }))
    history.push(`/warehouses/${id}`)
  }

  // TODO: see if the server can handle the same types with just _id
  const uninflateFunders = (warehouse) => {
    const uninflatedTranches = warehouse.tranches.map((tranche) => ({
      funder: tranche.funder._id,
      capacity: tranche.capacity,
      costOfFunds: tranche.costOfFunds,
    }))

    return { ...warehouse, tranches: uninflatedTranches }
  }

  switch (true) {
    case isFetchingWarehouse:
      return <Loading />
    case !warehouse:
      return <Loading />
    case !isActive(warehouse):
      return (
        <WarehouseForm
          formTitle={`Update ${warehouse.name}`}
          initialValues={uninflateFunders(warehouse)}
          onSubmit={handleSubmit}
          submitTitle="Update"
          cancelUrl={`/warehouses/${id}`}
        />
      )
    case isActive(warehouse):
      return (
        <>
          <BackLink />
          <h1>Warehouse no longer be updated</h1>
          <p>Warehouses cannot be updated once they have been activated</p>
        </>
      )
    default:
      return <p>TODO: error?</p>
  }
}

export default EditWarehouse
