import React from 'react'

import { s } from '@vega/styled/v2'

const WarehouseStatus = ({ warehouse }) => {
  return (
    <>
      <span
        style={s(
          'rounded-2xl px-3 py-1 h-2 flex items-center justify-center font-semibold ml-4',
          {
            backgroundColor: '#6699CC',
            color: 'white',
          }
        )}
      >
        {'DEFAULT'}
      </span>
      {warehouse?.default?.isPending && (
        <>
          <span>(Pending approval)</span>
        </>
      )}
    </>
  )
}

export default WarehouseStatus
