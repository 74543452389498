import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'
import { eligibilityVerificationAdapter } from './module'

export const {
  selectIsPending: selectIsFetchEligibilityVerficationsPending,
} = createAsyncThunkSelectors('eligibilityVerification/getEligibilityVerifications')

export const {
  selectAll: selectVerifications,
} = eligibilityVerificationAdapter.getSelectors((s) => s.eligibilityVerification)
