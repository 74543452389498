/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import { map } from '@neo/ramda-extra'
import { s, styled } from '@vega/styled/v2'
import { Tooltip } from '@mui/material'
import { formatDateString } from 'utils/date'
import { formatCurrency } from 'utils/formatter'
import TransferStatus from './TransferStatus'

const headerStyleBase = (customStyles) =>
  s(' inline-block text-grey-600 text-left text-xs font-semibold', {
    ...customStyles,
  })

const cellStyleBase = (customStyles) =>
  s(' inline-block text-grey-800 text-sm text-left flex items-center', {
    ...customStyles,
  })

const TextOverflowEllipsis = styled.span({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

export const Columns = () => {
  return React.useMemo(
    () => [
      {
        Header: 'Created',
        accessor: (row) => formatDateString(row.createdAt),
        tableHeaderStyles: headerStyleBase({ width: '10%' }),
        tableCellStyles: cellStyleBase({ width: '10%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Transfer ID',
        accessor: 'transferNumber',
        tableHeaderStyles: headerStyleBase({ width: '20%' }),
        tableCellStyles: cellStyleBase(s('font-semibold', { width: '20%' })),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Warehouse',
        accessor: (row) =>
          `${row.originatingWarehouse.name} -> ${row.destinationWarehouse.name}`,
        tableHeaderStyles: headerStyleBase({ width: '25%' }),
        tableCellStyles: cellStyleBase({ width: '25%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Transfer Balance',
        accessor: (row) => formatCurrency(row.totalLoanBalance),
        tableHeaderStyles: headerStyleBase({ width: '20%' }),
        tableCellStyles: cellStyleBase({ width: '20%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Number of loans',
        accessor: (row) => row.loans.length || 0,
        tableHeaderStyles: headerStyleBase({ width: '10%' }),
        tableCellStyles: cellStyleBase({ width: '10%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Status',
        accessor: (row) => row.currentState.state,
        tableHeaderStyles: headerStyleBase({ width: '15%' }),
        tableCellStyles: cellStyleBase({ width: '15%' }),
        Cell: ({ value }) => <TransferStatus status={value} />,
      },
    ],
    []
  )
}

export const TableData = ({ transfers }) => {
  return React.useMemo(
    () =>
      map((transfer) => {
        return {
          ...transfer,
          id: transfer._id
        }
      }, transfers),
    [transfers]
  )
}
