/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { Tooltip } from '@mui/material'
import { formatCurrency } from 'utils/formatters'
import { twoDecimalPointsIfNeeded, formatPercentage } from 'utils/formatter'

const headerStyleBase = (customStyles) =>
  s(' inline-block text-grey-600 text-left text-xs font-semibold', {
    ...customStyles,
  })

const cellStyleBase = (customStyles) =>
  s(' inline-block text-grey-800 text-sm text-left flex items-center', {
    ...customStyles,
  })

const TextOverflowEllipsis = styled.span({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

export const Columns = () => {
  return React.useMemo(
    () => [
      {
        Header: 'Loan group #',
        accessor: (row) => row.loanGroupNumber,
        tableHeaderStyles: headerStyleBase({ width: '20%' }),
        tableCellStyles: cellStyleBase({ width: '20%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Loan #',
        accessor: (row) => row.loanNumber,
        tableHeaderStyles: headerStyleBase({ width: '20%' }),
        tableCellStyles: cellStyleBase({ width: '20%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Location',
        accessor: (row) => row.city,
        tableHeaderStyles: headerStyleBase({ width: '30%' }),
        tableCellStyles: cellStyleBase({ width: '30%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Product',
        accessor: (row) => row.productName,
        tableHeaderStyles: headerStyleBase({ width: '20%' }),
        tableCellStyles: cellStyleBase({ width: '20%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'LVR',
        accessor: (row) => formatPercentage(twoDecimalPointsIfNeeded(row.lvr)),
        tableHeaderStyles: headerStyleBase({ width: '10%' }),
        tableCellStyles: cellStyleBase({ width: '10%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Balance',
        accessor: (row) => formatCurrency(row.loanBalance),
        tableHeaderStyles: headerStyleBase({ width: '10%' }),
        tableCellStyles: cellStyleBase({ width: '10%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Term',
        accessor: (row) => `${Math.round(row.termInMonths / 12)} years`,
        tableHeaderStyles: headerStyleBase({ width: '10%' }),
        tableCellStyles: cellStyleBase({ width: '10%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
    ],
    []
  )
}

