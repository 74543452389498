import React from 'react'

import { styled, s } from '@vega/styled/v2'
import { Button as VegaButton } from '@vega/components'

const CancelButton = styled(VegaButton)(
  s('bg-white border-1 border-primary rounded-lg', {
    '&:hover': { cursor: 'pointer' },
  })
)

const Button = ({ type, onClick, children, customStyle = {} }) => {
  if (type === 'cancel') {
    return (
      <CancelButton onClick={onClick} style={{ ...customStyle }}>
        {children}
      </CancelButton>
    )
  }

  if (type === 'submit') {
    return (
      <VegaButton type="submit" style={{ ...customStyle }}>
        {children}
      </VegaButton>
    )
  }

  if (type === 'outline') {
    return (
      <CancelButton onClick={onClick} style={{ ...customStyle }}>
        {children}
      </CancelButton>
    )
  }

  return (
    <VegaButton onClick={onClick} style={{ ...customStyle }}>
      {children}
    </VegaButton>
  )
}

export default Button
