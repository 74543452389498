import { s, styled } from '@vega/styled/v2'
import { ReactComponent as XBase } from '@vega/components/src/assets/images/x.svg'

export const Label = styled.span(
  s('inline-block text-sm font-normal text-grey-800 font-normal mb-2', {
    letterSpacing: '0.015em',
    lineHeight: 1.5,
  })
)

export const ContentCard = styled.div(
  s('mb-5 mt-10 px-5 rounded-lg bg-white p-8 w-full shadow-lg')
)

export const Container = styled.div(
  s('p-6 bg-white rounded-xl shadow-xl', { width: '64rem' })
)

export const Header = styled.div(s('flex justify-between'))

export const Title = styled.div(
  s('text-xl text-grey-900 font-semibold', {
    letterSpacing: '-0.01em',
    lineHeight: 1.2,
    marginBottom: 28,
  })
)

export const X = styled(XBase)(s('text-primary', { cursor: 'pointer' }))
