import React from 'react'
import { s } from '@vega/styled/v2'
import { Formik, Form } from 'formik'
import { Modal, Button } from '@vega/components'
import {
  loanSizeByGeographyInputs,
  numericValueInputs,
  booleanValueInputs,
  formattedRuleType,
  verificationValueInputs,
} from './common'
import { Container, Header, Title, X } from '../styles'

const EditEligibilityCriterionModal = ({
  isOpen,
  setIsOpen,
  criterion,
  eligibilityVerifications,
  handleSubmit
}) => {
  const close = () => setIsOpen(false)

  const handleSubmitAndClose = (data) => {
    handleSubmit(data)
    close()
  }

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <Container>
        <Header>
          <Title>
            Edit eligibility criterion
          </Title>
          <X onClick={close} />
        </Header>

        <Formik initialValues={criterion} onSubmit={handleSubmitAndClose}>
          {({ values }) =>
            (
              <Form>
                <h4>
                  {formattedRuleType(criterion.ruleType)}
                </h4>

                { values.ruleType === 'loanSizeByGeographyValue' && loanSizeByGeographyInputs }
                { values.ruleType === 'numericValue' && numericValueInputs }
                { values.ruleType === 'booleanValue' && booleanValueInputs }
                { values.ruleType === 'verificationValue' && verificationValueInputs(eligibilityVerifications, values) }

                <div style={s('flex', { flexDirection: 'row-reverse' })}>
                  <Button type="submit" style={s('ml-2')} loading={false}>
                    Save
                  </Button>

                  <Button variant="outlined" onClick={() => close()}>
                    Cancel
                  </Button>
                </div>
              </Form>
            )
          }
        </Formik>
      </Container>
    </Modal>
  )
}

export default EditEligibilityCriterionModal
