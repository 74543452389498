import {
  FunderService,
  WarehouseService,
  ActivityLogService,
  EligibilityVerificationService,
  WarehouseTransfersService,
  UserService,
} from '@vega/services'

const treasuryApiUri = process.env.REACT_APP_TREASURY_API_URI
const profileApiUri = process.env.REACT_APP_PROFILE_API_URI

const getOptions = (baseUri) => ({ baseUri })

export const activityLogService = new ActivityLogService(getOptions(treasuryApiUri))
export const warehouseService = new WarehouseService(getOptions(treasuryApiUri))
export const funderService = new FunderService(getOptions(treasuryApiUri))
export const eligibilityVerificationService = new EligibilityVerificationService(
  getOptions(treasuryApiUri)
)
export const warehouseTransfersService = new WarehouseTransfersService(
  getOptions(treasuryApiUri)
)
export const userService = new UserService(getOptions(profileApiUri))
