export const searchOptions = [
  {
    key: 'name',
    label: 'Warehouse Name',
    searchLabel: 'Search  warehouse name',
  },
  {
    key: 'tranches.funder.organisationName',
    label: 'Funder',
    searchLabel: 'Search funder',
  },
]

export const warehousesOptions = [
  {
    key: '',
    label: 'All',
  },
  {
    key: 'ACTIVE',
    label: 'Active',
  },
  {
    key: 'ON_HOLD',
    label: 'On Hold',
  },
  {
    key: 'DRAFT',
    label: 'Draft',
  },
  {
    key: 'DEFAULT',
    label: 'Default',
  },
  {
    key: 'ARCHIVED',
    label: 'Archived',
  },
]
