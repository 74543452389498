/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import { map } from '@neo/ramda-extra'
import { s, styled } from '@vega/styled/v2'
import { formatDateString } from 'utils/date'
import { Tooltip } from '@mui/material'
import { formatCurrency } from 'utils/formatter'
import WarehouseStatus from 'features/warehouse/WarehouseStatus'
import WarehouseDefaultStatus from 'features/warehouse/WarehouseDefaultStatus'

const headerStyleBase = (customStyles) =>
  s(' inline-block text-grey-600 text-left text-xs font-semibold', {
    ...customStyles,
  })

const cellStyleBase = (customStyles) =>
  s(' inline-block text-grey-800 text-sm text-left flex items-center', {
    ...customStyles,
  })

const TextOverflowEllipsis = styled.span({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

export const Columns = () => {
  return React.useMemo(
    () => [
      {
        Header: 'Created',
        accessor: (row) => formatDateString(row.createdAt),
        tableHeaderStyles: headerStyleBase({ width: '10%' }),
        tableCellStyles: cellStyleBase({ width: '10%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Warehouse',
        accessor: 'name',
        tableHeaderStyles: headerStyleBase({ width: '15%' }),
        tableCellStyles: cellStyleBase(s('font-semibold', { width: '15%' })),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Funders',
        accessor: 'funders',
        tableHeaderStyles: headerStyleBase({ width: '20%' }),
        tableCellStyles: cellStyleBase({ width: '20%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Total Loans',
        accessor: 'numberOfLoans',
        tableHeaderStyles: headerStyleBase({ width: '10%' }),
        tableCellStyles: cellStyleBase({ width: '10%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Balance',
        accessor: (row) => formatCurrency(row.totalBalance),
        tableHeaderStyles: headerStyleBase({ width: '10%' }),
        tableCellStyles: cellStyleBase({ width: '10%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Total Capacity',
        accessor: (row) => formatCurrency(row.totalCapacity),
        tableHeaderStyles: headerStyleBase({ width: '10%' }),
        tableCellStyles: cellStyleBase({ width: '10%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Status',
        accessor: (row) => row,
        tableHeaderStyles: headerStyleBase({ width: '25%' }),
        tableCellStyles: cellStyleBase({ width: '25%' }),
        Cell: ({ value }) => (
          <>
            <WarehouseStatus warehouse={value} />
            {(value?.default?.isDefault || value?.default?.isPending) && (
              <WarehouseDefaultStatus warehouse={value} />
            )}
          </>
        ),
      },
    ],
    []
  )
}

export const TableData = ({ warehouses }) => {
  return React.useMemo(
    () =>
      map((warehouse) => {
        const totalCapacity = warehouse.tranches.reduce(
          (acc, curr) => acc + curr.capacity,
          0
        )

        const funders = warehouse.tranches
          .map((tranche) => tranche?.funder?.organisationName)
          .join(', ')

        return {
          ...warehouse,
          id: warehouse._id,
          totalCapacity,
          funders,
        }
      }, warehouses),
    [warehouses]
  )
}
