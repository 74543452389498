import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { updatePoolParameters, deletePoolParameter } from 'modules/warehouse'
import { s } from '@vega/styled/v2'
import { Button } from '@vega/components'
import EditPoolParameterModal from './EditPoolParameterModal'
import { ContentCard } from '../styles'
import {
  numberFieldLabel,
  filterLabel,
  thresholdLabel,
} from './utils'

export const PoolParametersTable = ({ poolParameters, isEditable = true }) => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [currentPoolParameter, setCurrentPoolParameter] = useState()

  if (poolParameters.length === 0) {
    return (
      <p style={s('text-sm font-semibold text-grey-600')}>
        No pool parameters currently recorded
      </p>
    )
  }

  const rows = poolParameters.map((poolParameter) => (
    <tr key={poolParameter._id}>
      <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
        {numberFieldLabel(poolParameter.numberField)}
      </td>
      <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
        {thresholdLabel(poolParameter)}
      </td>
      <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
        <ul>
          {
            poolParameter.filters.map((filter) => (
              <li key={filter._id}>
                {filterLabel(filter)}
              </li>
            ))
          }
        </ul>
      </td>

      <td
        style={{ borderBottom: '1px solid #F1F5F9' }}
      >
        {
          isEditable && (
            <div style={s('py-2 flex flex-row-reverse space-x-4')}>
              <button
                onClick={async () => {
                  await dispatch(
                    deletePoolParameter({
                      warehouseId: id,
                      paremeterId: poolParameter._id,
                    })
                  )
                }}
                style={s(
                  'bg-grey-200 rounded-lg text-grey-600 border-0 font-bold px-2 py-1',
                  { cursor: 'pointer' }
                )}
              >
                X
              </button>

              <Button
                style={s('mr-2')}
                onClick={() => {
                  setCurrentPoolParameter(poolParameter)
                  setIsEditModalOpen(true)
                }}
              >
                Edit
              </Button>
            </div>
          )
        }
      </td>
    </tr>
  ))

  return (
    <>
      <ContentCard>
        <table style={s('w-full')}>
          <thead style={s('p-0 text-grey-800 text-left text-xs font-semibold')}>
            <tr>
              <th>Parameter</th>
              <th>Threshold</th>
              <th>For loans</th>
              <th />
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </ContentCard>

      <EditPoolParameterModal
        isOpen={isEditModalOpen}
        setIsOpen={setIsEditModalOpen}
        poolParameter={currentPoolParameter}
        handleSubmit={(data) => {
          dispatch(
            updatePoolParameters({
              warehouseId: id,
              parameterId: data._id,
              payload: data
            })
          )
        }}
      />
    </>
  )
}
