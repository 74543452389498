export const searchOptions = [
  {
    key: 'warehouseName',
    label: 'Warehouse Name',
    searchLabel: 'Search warehouse name',
  },
  {
    key: 'targetTransferAmount',
    label: 'Transfer Balance',
    searchLabel: 'Search transfer balance',
  },
]

export const loanOptions = [
  {
    key: '',
    label: 'All',
  },
  {
    key: 'DRAFT',
    label: 'Draft',
  },
  {
    key: 'PENDING_APPROVAL',
    label: 'Pending Approval',
  },
  {
    key: 'APPROVED',
    label: 'Approved',
  },
  {
    key: 'IN_FLIGHT',
    label: 'In_Flight',
  },
  {
    key: 'CANCELLED',
    label: 'Cancelled',
  },
  {
    key: 'SETTLED/COMPLETE',
    label: 'Settled/Complete',
  },
]
