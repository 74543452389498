import React from 'react'
import { Button, SelectField } from '@vega/components'
import { s } from '@vega/styled/v2'
import { Col, Row } from '@vega/styled'

const BooleanValueFilterRow = ({ index, remove }) => {
  const poolParameterFilterTypes = [
    { label: 'Loans with number', value: 'numberValueFilter' },
    { label: 'Loans with yes or no', value: 'booleanValueFilter' },
    { label: 'Loans with geography', value: 'geographyValueFilter' },
  ]

  const booleanValues = [
    { label: 'Expat', value: 'expat' },
    { label: 'Investment loan', value: 'investmentLoan' },
    { label: 'Fixed rate loan', value: 'fixedRateLoan' },
  ]

  const yesOrNo = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]

  return (
    <Row style={s('bg-grey-lightest p-4 rounded-lg mb-4')}>
      <Col span="5">
        <SelectField
          name={`filters.${index}.filterType`}
          label="Filter type"
          options={poolParameterFilterTypes}
          style={s('bg-white')}
          labelContainerStyle={s('bg-grey-lightest')}
        />
      </Col>

      <Col span="5">
        <SelectField
          name={`filters.${index}.filterInputs.booleanField`}
          label="Field"
          options={booleanValues}
          style={s('bg-white')}
          labelContainerStyle={s('bg-grey-lightest')}
        />
      </Col>

      <Col span="5">
        <SelectField
          name={`filters.${index}.filterInputs.expectedValue`}
          label="Expected value"
          options={yesOrNo}
          style={s('bg-white')}
          labelContainerStyle={s('bg-grey-lightest')}
        />
      </Col>

      <Col span="9" style={s('flex')}>
        <Button
          variant="outlined"
          onClick={() => remove(index)}
          style={s('mt-8 ml-16')}
        >
          Remove filter
        </Button>
      </Col>
    </Row>
  )
}

export default BooleanValueFilterRow
