import React from 'react'
import { s } from '@vega/styled/v2'

const WarehouseDetails = () => {
  return (
    <div style={s('mb-4')}>
      <h2>Details</h2>
      <div style={s('p-4 bg-white rounded-lg shadow')}/>
    </div>
  )
}


export default WarehouseDetails
