import React from 'react'
import { s } from '@vega/styled/v2'

import WarehouseDetails from './WarehouseDetails'
import WarehouseCapacity from './WarehouseCapacity'
import FundersDistribution from './FundersDistribution'
import FundersCapacity from './FundersCapacity'
import WarehouseMap from './WarehouseMap'
import { PoolParametersGraph } from './PoolParametersGraph'

const Overview = ({ warehouse }) => (
  <>
    <WarehouseDetails />

    <div
      style={s('grid', {
        gap: '1rem',
        'grid-template-columns': 'repeat(3, minmax(0, 1fr))',
      })}
    >
      <h2 style={s('mb-0', { flexGrow: 1 })}>Warehouse Capacity</h2>
      <h2 style={s('mb-0', { flexGrow: 1 })}>Funder Distribution</h2>
      <h2 style={s('mb-0', { flexGrow: 1 })}>Funder Capacity</h2>

      <WarehouseCapacity warehouse={warehouse} />
      <FundersDistribution warehouse={warehouse} />
      <FundersCapacity warehouse={warehouse} />
    </div>
    <div
      style={s('grid', {
        gap: '1rem',
        'grid-template-columns': 'repeat(2, minmax(0, 1fr))',
      })}
    >
      <WarehouseMap warehouse={warehouse} />
      <PoolParametersGraph warehouse={warehouse} />
    </div>
  </>
)

export default Overview
