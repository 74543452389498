import React from 'react'
import { styled, s } from '@vega/styled/v2'
import { TERRITORIAL_AUTHORITY } from '@vega/constants'

import { MapChart } from 'components/MapChart'
import { useMortgageMapData } from 'hooks/useMortgageMapData'
import { LocationItem } from 'components/MapChart/LocationItem'

const LocationListContainer = styled.div(
  s('', {
    overflowY: 'auto',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)

const Row = styled.div(s('flex justify-between'))

const citiesAndDistricts = Object.keys(TERRITORIAL_AUTHORITY)
const descendingComparer = (a, b) => b.percentage - a.percentage

const WarehouseMap = ({ warehouse }) => {
  const formattedLocations = (warehouse?.locationBreakdowns || []).reduce(
    (acc, curr) => {
      acc[curr.location] = curr.loanCount
      return acc
    },
    {}
  )

  const { data, minValue, maxValue } = useMortgageMapData(
    formattedLocations,
    citiesAndDistricts
  )

  return (
    <div>
      <h2>Warehouse capacity location</h2>
      <div style={s('bg-white rounded-lg p-4 shadow-lg')}>
        <Row style={s('w-full px-10', { height: 420 })}>
          <MapChart
            data={data}
            minValue={minValue}
            maxValue={maxValue}
            containerStyle={s('h-full', { width: '60%' })}
            centerOffsetY={-120}
            centerOffsetX={20}
            scale={1100}
          />

          <LocationListContainer>
            {data.sort(descendingComparer).map((props) => (
              <LocationItem key={props.location} style={s('mb-4')} {...props} />
            ))}
          </LocationListContainer>
        </Row>
      </div>
    </div>
  )
}

export default WarehouseMap
