/* eslint-disable complexity */
import React, { useEffect } from 'react'

import {
  fetchWarehouse,
  selectIsFetchWarehousePending,
  selectWarehouseById,
} from 'modules/warehouse'

import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Loading } from '@vega/components'
import { s } from '@vega/styled/v2'

import WarehouseTabs from './WarehouseTabs'

import BackLink from 'components/BackLink'
import WarehouseStatus from './WarehouseStatus'
import WarehouseActions from './WarehouseActions'
import WarehouseDefaultStatus from './WarehouseDefaultStatus'

const Warehouse = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const warehouse = useSelector(selectWarehouseById(id))
  const isFetchingWarehouse = useSelector(selectIsFetchWarehousePending)

  useEffect(() => {
    dispatch(fetchWarehouse(id))
  }, [dispatch, id])

  if (isFetchingWarehouse) return <Loading />

  if (warehouse) {
    return (
      <>
        <BackLink toLink={'/warehouses'} />

        <div style={s('flex items-center', { gap: '0.5rem' })}>
          <div style={{ flexGrow: 1 }}>
            <h1 style={s('mb-0')}>{warehouse.name}</h1>

            <div>
              Warehouse ID:
              <span style={s('font-semibold ml-2')}>
                {warehouse?.warehouseNumber
                  ? warehouse?.warehouseNumber
                  : warehouse._id}
              </span>
            </div>

            <div style={s('flex items-center py-2 mb-6')}>
              <WarehouseStatus warehouse={warehouse} />
              {(warehouse.default.isDefault || warehouse.default.isPending) && (
                <WarehouseDefaultStatus warehouse={warehouse} />
              )}
            </div>
          </div>

          <WarehouseActions warehouse={warehouse} />
        </div>
        <WarehouseTabs warehouse={warehouse} />
      </>
    )
  }

  return <p>Error case</p>
}

export default Warehouse
