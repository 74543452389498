import React from 'react'
import { trim, pipe, toLower, replaceAll } from '@neo/ramda-extra'
import PropTypes from 'prop-types'
import { scaleQuantize } from '@visx/scale'
import { Mercator } from '@visx/geo'
import * as topojson from 'topojson-client'
import nzTopoJson from './nzTopo.json'
import { s } from '@vega/styled/v2'

const background = 'transparent'
const NZ_LONG_LAT = [174.7645, -36.8509]

const createColorGenerator = (min, max) =>
  scaleQuantize({
    domain: [min, max],
    range: [
      s('text-green-100').color,
      s('text-green-200').color,
      s('text-green-300').color,
      s('text-green-400').color,
      s('text-green-500').color,
      s('text-green-600').color,
      s('text-green-700').color,
    ],
  })


const nzGeoJson = topojson.feature(nzTopoJson, nzTopoJson.objects.NZL_adm2)

/**
 * trim space, lowercase and replace non-char with space
 */
const normalize = pipe(trim, toLower, replaceAll(new RegExp(/(_|\W)/, 'gi'), ' '))

export const MapChartBase = ({
  data,
  minValue,
  maxValue,
  width,
  height,
  centerOffsetX = 0,
  centerOffsetY = 0,
  centerLongAndLat = NZ_LONG_LAT,
  scale = 1200,
}) => {
  const centerX = width / 2 + centerOffsetX
  const centerY = height / 2 + centerOffsetY

  const getColorByValue = (value) => {
    if (value === 0) return s('text-green-100').color

    return createColorGenerator(minValue, maxValue)(value)
  }

  return width < 10 ? null : (
    <svg width={width} height={height}>
      <rect x={0} y={0} width={width} height={height} fill={background} rx={14} />
      <Mercator
        data={nzGeoJson.features}
        scale={scale}
        translate={[centerX, centerY]}
        center={centerLongAndLat}
      >
        {(mercator) => (
          <g>
            {mercator.features.map(({ feature, path }) => {
              const matched = data.find(
                (d) =>
                  normalize(d.location) === normalize(feature.properties.NAME_1) ||
                  normalize(d.location) === normalize(feature.properties.NAME_2)
              )

              const color = getColorByValue(matched?.percentage || 0)

              return (
                <path
                  key={`${feature.properties.NAME_1}-${feature.properties.NAME_2}`}
                  d={path || ''}
                  fill={color}
                />
              )
            })}
          </g>
        )}
      </Mercator>
    </svg>
  )
}

const { number, string, arrayOf, shape } = PropTypes

MapChartBase.propTypes = {
  data: arrayOf(
    shape({
      location: string,
      value: number,
    })
  ),
  height: number.isRequired,
  width: number.isRequired,
  minValue: number.isRequired,
  maxValue: number.isRequired,
  centerOffsetX: number,
  centerOffsetY: number,
  scale: number,
}
