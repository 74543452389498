import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import { activityLogService } from 'apiService'

/* eslint-disable complexity */
export const searchActivityLogs = createAsyncThunk(
  'activityLog/searchActivityLogs',
  async ({ searchParams = {}, refId, pageIndex = 0 }, { rejectWithValue, signal }) => {
    try {
      const { limit = 20 } = searchParams
      const { description, userId, startDate, endDate } = searchParams?.filters

      const filters = { refId, start: limit * pageIndex, limit }

      if (userId) {
        filters.userId = userId
      }

      if (description) {
        filters.description = description
      }

      if (startDate && endDate) {
        filters.dateRange = { start: startDate, end: endDate }
      }

      const { items, total } = await activityLogService.filteredSearch(
        filters,
        signal
      )

      return { activityLogs: items, total }
    } catch (err) {
      const error = await err.response.json()
      return rejectWithValue(error)
    }
  }
)

export const activityLogsAdapter = createEntityAdapter()

const {
  selectAll: selectActivityLogs,
  selectById: selectActivityLogById,
} = activityLogsAdapter.getSelectors((s) => s.activityLog)

const initialState = activityLogsAdapter.getInitialState({
  entities: {},
  ids: [],
  total: undefined,
  inspectedActivityLogId: undefined,
})

const activityLogsSlice = createSlice({
  name: 'activityLogs',
  initialState,
  extraReducers: {
    [searchActivityLogs.fulfilled]: (state, action) => {
      const { activityLogs, total } = action.payload

      activityLogsAdapter.setAll(state, activityLogs)
      state.total = total
    },
  },
})

const { reducer: activityLogsReducer } = activityLogsSlice
export { activityLogsReducer, selectActivityLogs, selectActivityLogById }
