import React, { useEffect } from 'react'

import { Formik as FormProvider, Form, FieldArray } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchFunders, selectFunders } from 'modules/funder'

import { s, styled } from '@vega/styled/v2'

import { TextField, SelectField, NumberField } from '@vega/components'

import Button from './Button'

const Label = styled.span(
  s('inline-block text-sm font-normal text-grey-800 font-normal mb-2', {
    letterSpacing: '0.015em',
    lineHeight: 1.5,
  })
)

const trancheValadiationSchema = Yup.object().shape({
  funder: Yup.string()
    .required('Funder is required'),
  capacity: Yup.number()
    .integer()
    .positive()
    .required('Capacity is required'),
  costOfFunds: Yup
    .number()
    .required('Cost of funds is required'),
})

const warehouseValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  tranches: Yup.array()
    .min(1, 'At least one tranch is required')
    .of(trancheValadiationSchema),
})

const WarehouseForm = ({
  formTitle,
  initialValues,
  onSubmit,
  submitTitle,
  cancelUrl,
}) => {
  const dispatch = useDispatch()
  const funders = useSelector(selectFunders)

  useEffect(() => {
    dispatch(fetchFunders({ searchParams: {}, pageIndex: 0 }))
  }, [dispatch])

  if (funders) {
    const activeFunders = funders.filter((funder) => !funder.archivedAt)
    return (
      <FormProvider
        initialValues={initialValues}
        validationSchema={warehouseValidationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form style={s('p-12 bg-white rounded-lg')}>
            <h1>{formTitle}</h1>

            <Label>Warehouse name</Label>
            <TextField
              name="name"
              placeholder="Warehouse name"
              aria-label="Warehouse name"
            />

            <FieldArray name="tranches">
              {({ remove, push }) => (
                <div>
                  {values.tranches.length > 0 &&
                    values.tranches.map((_tranche, index) => (
                      <>
                        <div style={s('flex my-2', { gap: '0.5rem' })}>
                          <h2>Tranche {index + 1}</h2>

                          {
                            values.tranches.length > 1 && (
                              <Button
                                type="outline"
                                onClick={() => remove(index)}
                                customStyle={s('mt-4 ml-4', {
                                  width: '150px',
                                  height: '45px',
                                })}
                              >
                                Remove tranche
                              </Button>
                            )
                          }
                        </div>

                        <Label>Funder</Label>
                        <SelectField
                          name={`tranches.${index}.funder`}
                          options={activeFunders.map((funder) => ({
                            label: funder.organisationName,
                            value: funder._id,
                            key: funder._id,
                          }))}
                          placeholder={'Select a funder'}
                          style={s('mb-6')}
                          aria-label="Funder"
                        />

                        <Label>Capacity ($)</Label>
                        <NumberField
                          name={`tranches.${index}.capacity`}
                          style={s('mb-6')}
                          aria-label="Capacity"
                          prefix="$"
                          thousandSeparator
                          removeCommasFromValue
                        />

                        <Label>Cost of funds (BPS)</Label>
                        <NumberField
                          name={`tranches.${index}.costOfFunds`}
                          style={s('mb-6')}
                          aria-label="Cost of funds"
                          suffix="BPS"
                        />
                      </>
                    ))}

                  <Button
                    type="outline"
                    onClick={() => push({ funder: '', capacity: 0, costOfFunds: 0 })}
                    customStyle={s('my-6')}
                  >
                    Add tranche
                  </Button>
                </div>
              )}
            </FieldArray>

            <div style={s('flex my-2', { gap: '0.5rem' })}>
              <Button type="submit">{submitTitle}</Button>

              <Link to={cancelUrl} style={s('no-decoration')}>
                <Button type="cancel">Cancel</Button>
              </Link>
            </div>
          </Form>
        )}
      </FormProvider>
    )
  }

  return <p>TODO: error?</p>
}

export default WarehouseForm
