import React, { useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { styled, s } from '@vega/styled/v2'
import { Formik } from 'formik'
import { Button as ButtonBase, DottedCircleLoader, Menu } from '@vega/components'
import { Table } from '@vega/components/src/v2'
import { useHistory, Link } from 'react-router-dom'
import { useTableFetching } from 'hooks'
import { Columns, TableData } from './TableFormat'
import { searchOptions } from './filterOptions'
import { Item } from '@react-stately/collections'
import { ReactComponent as ResetIcon } from '@vega/components/src/assets/images/refresh.svg'
import { ReactComponent as Search } from '@vega/components/src/assets/images/search.svg'
import { fetchWarehouses, selectWarehouses, selectTotal } from 'modules/warehouse'
import FiltersBar from './FiltersBar'

const Root = styled.div(s('flex flex-column flex-1 mx-8'))
const Row = styled.div(s('flex flex-row pb-4 pt-8 justify-between'))
const Title = styled.h3(s('p-0 m-0 font-semibold text-primary', { fontSize: 28 }))
const Button = styled(ButtonBase)(
  s(
    'bg-white border-solid border-1 border-grey-200 py-3 px-4 text-sm text-grey-600 rounded-lg'
  )
)
const SearchAndFiltersContainer = styled.div(
  s('flex my-4 justify-between', { gap: 16 })
)

const Container = styled.div(s('flex justify-between w-full h-full', { gap: 10 }))

const SearchFilter = styled(Menu)(
  s('', {
    minWidth: '215px',
    maxWidth: '215px',
  })
)

const SearchBarContainer = styled.div(
  s('relative flex items-center w-full h-full rounded-lg rounded-tr-none bg-green-50', {
    minHeight: '52px',
    whiteSpace: 'nowrap',
    outline: 'none',
  })
)
const SearchIcon = styled(Search)(s('absolute ml-4'))

const Input = styled.input(
  s(
    'pl-12 pr-4 pt-3 pb-3 w-full h-full border-solid border-1 border-grey-400 text-base rounded-lg text-grey-500',
    {
      backgroundcolor: 'white',
      '&:hover': s('border-1 border-primary', { cursor: 'pointer' }),
      ':focus-within': s('border-1 border-primary', {
        outline: '2px solid',
        outlineColor: s('text-primary').color,
      }),
    }
  )
)

const TableContainer = styled.div(s('h-full mt-4', { overflow: 'hidden' }))

function Overview() {
  const history = useHistory()
  const dispatch = useDispatch()
  const [hasFirstFetchFulfilled, setHasFirstFetchFulfilled] = useState(false)
  const total = useSelector(selectTotal)
  const warehouses = useSelector(selectWarehouses).filter((w) => w)

  const [searchFilter, setSearchFilter] = useState({
    key: 'name',
    label: 'Warehouse Name',
    searchLabel: 'Search  warehouse name',
  })
  const [statusFilterLabel, setStatusFilterLabel] = useState({
    key: '',
    label: 'Status',
  })

  const columns = Columns()
  const data = TableData({ warehouses })

  const handleRowClick = (id) => history.push(`/warehouses/${id}/overview`)

  const fetchData = useCallback(
    ({ searchParams = {}, pageIndex = 0 }) =>
      dispatch(fetchWarehouses({ searchParams, pageIndex })),
    [dispatch]
  )

  const {
    searchProps: { searchParams },
    updateSearchProps,
    paginationProps,
    isFetchingData,
  } = useTableFetching(total, fetchData)

  React.useEffect(() => {
    async function fetchWarehouses() {
      await fetchData({
        searchParams: { limit: searchParams.limit },
        pageIndex: 0,
      })
      setHasFirstFetchFulfilled(true)
    }

    fetchWarehouses()
  }, [dispatch, fetchData, searchParams.limit])

  const onTextSearch = ({ searchTerm }, filters) => {
    updateSearchProps({
      searchTerm,
      filters,
    })
  }

  function handleResetFilters() {
    updateSearchProps({
      filters: {
        name: '',
        funder: [],
        status: '',
        searchFilter: '',
      },
      searchTerm: null,
    })
    setStatusFilterLabel({
      key: '',
      label: 'Status',
    })
    setSearchFilter({
      key: 'name',
      label: 'Warehouse Name',
      searchLabel: 'Search  warehouse name',
    })
    document.getElementById('searchTerm').value = ''
  }

  if (!hasFirstFetchFulfilled) {
    return (
      <div style={s('flex justify-center items-center h-full w-full')}>
        <DottedCircleLoader size="lg" />
      </div>
    )
  }

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value

    // regex to validate search text input - not allowing some special characters

    if (/[!"`'#%&,:;<>={}~$()*/\\?[\]^|]+/i.test(searchTerm) === true) {
      document.getElementById('searchTerm').style.outlineColor = 'red'
      return
    }
    document.getElementById('searchTerm').style.outlineColor = '#490B2D'

    if (!searchTerm || searchTerm.length === 0)
      onTextSearch({ searchTerm: '' }, { searchFilter: searchFilter.key })
    if (searchTerm.length > 2)
      onTextSearch({ searchTerm }, { searchFilter: searchFilter.key })
  }

  const handleSearchFilterAction = (searchFilterValue) => {
    if (!searchFilterValue || searchFilterValue.length === 0) searchFilterValue = 'name'
    const updateFilterObject = searchOptions.find(
      (filter) => filter.key === searchFilterValue
    )
    setSearchFilter(updateFilterObject)
  }

  return (
    <Root>
      <Row>
        <Title className="sohne">Warehouses</Title>
        <Link to={'/warehouses/new'} style={s('no-decoration')}>
          <ButtonBase>Add a warehouse</ButtonBase>
        </Link>
      </Row>

      <SearchAndFiltersContainer>
        <Container>
          <SearchFilter
            label={searchFilter.label || 'Search wahouse name'}
            aria-label="warehouse search filter"
            onAction={handleSearchFilterAction}
          >
            {searchOptions.map((option) => (
              <Item key={option.key} value={option.key}>
                {option.label}
              </Item>
            ))}
          </SearchFilter>
          <SearchBarContainer>
            <SearchIcon height={20} width={20} />
            <Input
              placeholder={searchFilter.searchLabel || 'Search'}
              onChange={handleSearchChange}
              name="searchTerm"
              id="searchTerm"
              type="search"
            />
          </SearchBarContainer>
        </Container>

        <Formik>
          <FiltersBar
            updateSearchProps={updateSearchProps}
            filters={searchParams.filters}
            statusFilterLabel={statusFilterLabel}
            setStatusFilterLabel={setStatusFilterLabel}
          />
        </Formik>

        <Button
          StartIcon={<ResetIcon style={s('w-1 h-1 text-grey-400 mr-1')} />}
          onClick={handleResetFilters}
        >
          Reset
        </Button>
      </SearchAndFiltersContainer>

      <TableContainer>
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          tBodyStyle={s('bg-white')}
          containerStyle={s('bg-white rounded-lg shadow-md flex-1')}
          paginationProps={paginationProps}
          style={s('pb-0 m-0')}
          tableHeaderRowStyles={s(
            'bg-white border-solid border-0 border-b-1 border-grey-200'
          )}
          loaderLabel="Fetching accounts"
          loading={isFetchingData}
          onClick={handleRowClick}
        />
      </TableContainer>
    </Root>
  )
}

export { Overview }
