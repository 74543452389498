/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable complexity */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Loading, Button, NumberField, FormMessage } from '@vega/components'
import {
  selectTransferById,
  fetchTransfer,
  deleteTransfer,
  selectIsUpdatingPending,
  updateTransfer,
  updateTransferStatus,
  selectIsUpdatingStatusPending,
} from 'modules/warehouseTransfers'
import { s, styled } from '@vega/styled/v2'
import {
  ContentCard,
  SectionTitle as SectionTitleBase,
  Table,
} from '@vega/components/src/v2'
import { Container, Row, Col } from '@vega/styled'
import BackLink from 'components/BackLink'
import { formatCurrency } from 'utils/formatters'
import TransferStatus from '../Overview/TransferStatus'
import { Columns } from './TableFormat'
import { Formik as FormProvider, Form } from 'formik'
import ErrorModal from './ErrorModal'
import ParameterBulletGraph from '../../common/ParameterBulletGraph'
import {
  fetchWarehouse,
  validatePoolParameters,
  selectIsValidatePoolParametersPending,
  selectIsFetchWarehousePending,
} from 'modules/warehouse'
import { poolParametersTypes } from '../../warehouse/Parameters/poolParameters/utils'

const FormContainer = styled.div(s('bg-primary-background rounded-lg p-6'))

const Title = styled.h4(s('m-0 text-primary font-bold'))

const Label = styled.div(
  s('text-sm font-normal', {
    color: '#515856',
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)

const Value = styled.div(
  s('text-base font-semibold', {
    color: '#282C2B',
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)

const statusText = {
  DRAFT: 'Confirm',
  PENDING_APPROVAL: 'Approve',
  APPROVED: 'In flight',
  IN_FLIGHT: 'Settle',
}

const TransferDetails = () => {
  const { id } = useParams()
  const transfer = useSelector(selectTransferById(id))
  const isUpdatingPending = useSelector(selectIsUpdatingPending)
  const isUpdatingStatusPending = useSelector(selectIsUpdatingStatusPending)
  const isValidatingParemetersPending = useSelector(
    selectIsValidatePoolParametersPending
  )
  const isFetchingWarehouse = useSelector(selectIsFetchWarehousePending)
  const history = useHistory()
  const dispatch = useDispatch()
  const [hasFirstFetchFulfilled, setHasFirstFetchFulfilled] = useState(false)
  const [isCanceled, setIsCanceled] = useState({
    actionButtonHidden: '',
    cancelButtonHidden: '',
    disableForm: '',
    disableButton: false,
  })
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [parameterBulletGraphValues, setParameterBulletGraphValues] = useState([])

  const allLoans = (transfer?.loans || []).reduce((acc, curr) => {
    const loansWithGroupNumber = (curr.loans || []).map((loan) => ({
      ...loan,
      loanGroupNumber: curr.loanGroupNumber,
    }))

    return acc.concat(loansWithGroupNumber)
  }, [])

  let currentPoolParemetersValidation = {}
  let newtPoolParemetersValidation = {}

  const allLoansBalance = allLoans.reduce((acc, curr) => acc + curr.loanBalance, 0)

  const columns = Columns()

  useEffect(() => {
    dispatch(fetchTransfer(id))
  }, [dispatch, id])

  useEffect(async () => {
    if (hasFirstFetchFulfilled) {
      const result = await dispatch(fetchWarehouse(transfer?.destinationWarehouse._id))
      const loanIds = result?.payload?.loans.flatMap((loanGroup) =>
        loanGroup?.loans?.map((loan) => loan?.loanId)
      )
      currentPoolParemetersValidation = await dispatch(
        validatePoolParameters({
          warehouseId: transfer.destinationWarehouse._id,
          loanIds,
        })
      )

      let transferLoanIds = transfer?.loans.flatMap((loanGroup) =>
        loanGroup?.loans?.map((loan) => loan?.loanId)
      )

      if (transferLoanIds.some((loanId) => loanId === undefined)) {
        transferLoanIds = transfer?.loans
      }

      newtPoolParemetersValidation = await dispatch(
        validatePoolParameters({
          warehouseId: transfer.destinationWarehouse._id,
          loanIds: [...loanIds, ...transferLoanIds],
        })
      )

      setParameterBulletGraphValues(
        currentPoolParemetersValidation?.payload?.poolParameterSummaries?.map(
          (parameter) => {
            const newValue = newtPoolParemetersValidation?.payload?.poolParameterSummaries.find(
              (newParameter) =>
                parameter?.poolParameterId === newParameter.poolParameterId
            )?.value
            return {
              ...parameter,
              newValue,
            }
          }
        )
      )
    }
  }, [dispatch, hasFirstFetchFulfilled])

  useEffect(() => {
    if (transfer) {
      setHasFirstFetchFulfilled(true)
    }
    if (transfer && transfer?.currentState.state === 'CANCELLED') {
      setIsCanceled({ hidden: 'hidden' })
    }
    if (transfer && transfer?.currentState.state !== 'DRAFT') {
      setIsCanceled({ disableForm: 'none', disableButton: true })
    }
  }, [dispatch, transfer])

  if (!hasFirstFetchFulfilled) return <Loading />

  const onDeleteTransfer = (id) => (dispatch) => {
    dispatch(deleteTransfer(id)).then(() => {
      history.push(`/transfers`)
    })
  }

  const statusButtonText = statusText[transfer?.currentState.state]

  const updateStatus = async (id, currentStatus) => {
    let newStatus = 'DRAFT'
    switch (currentStatus) {
      case 'DRAFT':
        newStatus = 'PENDING_APPROVAL'
        break
      case 'PENDING_APPROVAL':
        newStatus = 'APPROVED'
        break
      case 'APPROVED':
        newStatus = 'IN_FLIGHT'
        break
      case 'IN_FLIGHT':
        newStatus = 'SETTLED/COMPLETE'
        break
      default:
        return 'DRAFT'
    }

    const result = await dispatch(updateTransferStatus({ id, status: newStatus }))
    if (result && result.error) {
      setErrorMsg(result.payload.message)
      setIsCanceled({ cancelButtonHidden: '' })
      setIsErrorModalOpen(true)
      return true
    }
    dispatch(fetchTransfer(id))
    return newStatus
  }

  const findAllocationPercentage = (id) => {
    if (transfer?.funderAllocations?.length === 0) return 0
    const percentage =
      transfer?.funderAllocations?.find((allocation) => allocation?.funder === id)
        ?.percentage || 0

    return percentage
  }

  const initialTranchValues = transfer?.destinationWarehouse?.tranches?.map(
    (tranch) => ({
      ...tranch,
      allocation: findAllocationPercentage(tranch.funder._id) || '',
    })
  )

  const isPoolParameterPercentage = (valueToCheck) => {
    return poolParametersTypes
      .filter((item) => item.value === valueToCheck)
      .some((item) => item.suffix === '%')
  }

  const handleSubmit = async (values) => {
    const payload = values.tranches
      .filter(
        (tranche) =>
          tranche?.allocation !== null &&
          tranche?.allocation !== '0' &&
          tranche?.allocation !== '-' &&
          tranche?.allocation !== ''
      )
      .map((tranche) => ({
        funder: tranche.funder._id,
        percentage: parseFloat(tranche.allocation),
      }))
    await dispatch(updateTransfer({ id: transfer._id, payload }))
    dispatch(fetchTransfer(id))
  }

  return (
    <>
      <ErrorModal
        isOpen={isErrorModalOpen}
        setIsOpen={setIsErrorModalOpen}
        errorMsg={errorMsg}
      />
      <Container>
        <div style={s('mb-2')}>
          <BackLink toLink={'/transfers'} />
        </div>

        <div style={s('flex items-center', { gap: '0.5rem' })}>
          <div style={{ flexGrow: 1 }}>
            <h1 style={s('mb-0')}>{transfer.originatingWarehouse?.name}</h1>

            <div style={s('flex justify-between ')}>
              <div>
                Tranfer ID:
                <span style={s('font-semibold ml-2')}>{transfer.transferNumber}</span>
              </div>
              <div style={s('flex items-center', { gap: '0.5rem' })}>
                {transfer.currentState.state === 'DRAFT' && (
                  <div style={s('flex items-center')}>
                    <Button
                      status={transfer.currentState.state}
                      onClick={() => dispatch(onDeleteTransfer(transfer._id))}
                    >
                      Delete
                    </Button>
                  </div>
                )}
                {transfer.currentState.state !== 'DRAFT' &&
                  transfer.currentState.state !== 'CANCELLED' &&
                  transfer.currentState.state !== 'SETTLED/COMPLETE' && (
                    <div style={s('flex items-center')}>
                      <Button
                        status={transfer.currentState.state}
                        onClick={async () => {
                          setIsCanceled({
                            actionButtonHidden: 'hidden',
                            disableForm: 'none',
                          })
                          await dispatch(
                            updateTransferStatus({
                              id: transfer._id,
                              status: 'CANCELLED',
                            })
                          )
                          dispatch(fetchTransfer(id))
                        }}
                        loading={isUpdatingStatusPending}
                        variant="outlined"
                        style={{ visibility: isCanceled.cancelButtonHidden }}
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
                {transfer.currentState.state !== 'SETTLED/COMPLETE' &&
                  transfer.currentState.state !== 'CANCELLED' && (
                    <div style={s('flex items-center')}>
                      <Button
                        status={transfer.currentState.state}
                        onClick={() => {
                          setIsCanceled({ cancelButtonHidden: 'hidden' })
                          updateStatus(transfer._id, transfer.currentState.state)
                        }}
                        loading={isUpdatingStatusPending}
                        style={{ visibility: isCanceled.actionButtonHidden }}
                      >
                        {statusButtonText}
                      </Button>
                    </div>
                  )}
              </div>
            </div>
            <div style={s('flex items-center py-2 mb-6')}>
              <TransferStatus status={transfer.currentState.state} />
            </div>
          </div>
        </div>

        <ContentCard style={s('mb-4')}>
          <SectionTitleBase title="Transfer summary" style={s('mb-4')} />
          <Row style={s('mb-4')}>
            <Col span={8}>
              <div style={s('flex flex-row')}>
                <Label>Originating warehouse</Label>
              </div>
              <Value>{transfer.originatingWarehouse?.name}</Value>
            </Col>

            <Col span={8}>
              <div style={s('flex flex-row')}>
                <Label>Destination warehouse</Label>
              </div>
              <Value>{transfer.destinationWarehouse?.name}</Value>
            </Col>

            <Col span={8}>
              <div style={s('flex flex-row')}>
                <Label>Target transfer date</Label>
              </div>
              <Value>{transfer.targetTransferDate}</Value>
            </Col>
          </Row>

          <Row>
            <Col span={8}>
              <div style={s('flex flex-row')}>
                <Label>Target transfer balance</Label>
              </div>
              <Value>{formatCurrency(transfer.targetTransferAmount)}</Value>
            </Col>

            <Col span={8}>
              <div style={s('flex flex-row')}>
                <Label>Actual transfer balance</Label>
              </div>
              <Value>{formatCurrency(allLoansBalance)}</Value>
            </Col>

            <Col span={8}>
              <div style={s('flex flex-row')}>
                <Label>Number of loans selected</Label>
              </div>
              <Value>{allLoans.length}</Value>
            </Col>
          </Row>
        </ContentCard>

        <ContentCard style={s('mb-4')}>
          <SectionTitleBase title="Pool Parameters Preview" style={s('mb-4')} />
          {isValidatingParemetersPending || isFetchingWarehouse ? (
            <div style={s('mt-12 mb-12')}>
              <Loading />
            </div>
          ) : (
            <div
              style={{
                display: 'grid',
                gridTemplateColumns:
                  parameterBulletGraphValues?.length > 1 ? '1fr 1fr' : '1fr',
                gap: '20px',
              }}
            >
              {parameterBulletGraphValues.length > 0 &&
                parameterBulletGraphValues.map((parameter) => (
                  <ParameterBulletGraph
                    key={parameter.label}
                    label={parameter.filtersDescription}
                    lowerLimit={
                      isPoolParameterPercentage(parameter.parameterTypeDescription)
                        ? 0
                        : parameter.lowerLimit
                    }
                    upperLimit={parameter.upperLimit}
                    currentValue={parameter.value}
                    newValue={parameter.newValue}
                    paremeterTypeDescripition={parameter.parameterTypeDescription}
                  />
                ))}
            </div>
          )}
        </ContentCard>

        <ContentCard style={s('mb-4')}>
          <SectionTitleBase title="Funder allocation" style={s('mb-4')} />

          <FormContainer>
            <FormProvider
              enableReinitialize
              initialValues={{ tranches: initialTranchValues }}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange, handleBlur, resetForm }) => {
                const totalAllocatedPercentage = values?.tranches?.reduce(
                  (sum, tranche) => {
                    const allocation = parseInt(tranche?.allocation, 10) || 0
                    return sum + allocation
                  },
                  0
                )

                const isValidPercentage = totalAllocatedPercentage !== 100

                return (
                  <Form style={{ pointerEvents: isCanceled.disableForm }}>
                    <Row>
                      <Col>
                        <Title>Allocation Configuration</Title>
                      </Col>
                    </Row>

                    <Row style={s('mb-8')}>
                      <Col span={18} style={s('flex justify-end')}>
                        <Label>Value</Label>
                      </Col>

                      <Col span={6} style={s('flex justify-end')}>
                        <Label>Allocation (%)</Label>
                      </Col>
                    </Row>

                    {values?.tranches?.map(
                      ({ _id, funder, allocation = 0 }, funderIdx) => {
                        return (
                          <Row
                            key={_id}
                            style={s('py-4 flex items-center', {
                              borderBottom: '1px solid #E4E6E6',
                            })}
                          >
                            <Col span={12}>
                              <Value>{funder?.organisationName}</Value>
                            </Col>

                            <Col span={6} style={s('flex justify-end')}>
                              <Value>
                                {allocation !== '-' && allocation > 0
                                  ? formatCurrency((allLoansBalance * allocation) / 100)
                                  : formatCurrency(0)}
                              </Value>
                            </Col>

                            <Col span={6} style={s('flex justify-end items-center')}>
                              <NumberField
                                name={`tranches[${funderIdx}].allocation`}
                                placeholder={0}
                                containerStyle={s('w-6 mr-2')}
                                inputContainerStyle={s('bg-white')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={allocation}
                                type="number"
                              />{' '}
                              <span style={s('text-grey-900')}>%</span>
                            </Col>
                          </Row>
                        )
                      }
                    )}

                    <Row
                      style={s('py-6 flex items-center', {
                        borderBottom: '1px solid #E4E6E6',
                      })}
                    >
                      <Col span={20} style={s('flex')}>
                        <Value>Total</Value>
                      </Col>

                      <Col span={4} style={s('flex justify-end')}>
                        {/* <Value style={s('mr-4')}>{totalAllocatedPercentage}</Value> */}
                        <Value style={s('mr-4')}>{totalAllocatedPercentage}</Value>
                        <span style={s('text-grey-900')}>%</span>
                      </Col>
                    </Row>

                    <Row style={s('flex items-center')}>
                      <Col span={24} style={s('flex justify-end ')}>
                        <Button
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onClick={() => {
                            resetForm()
                          }}
                          disabled={isCanceled.disableButton}
                        >
                          Reset
                        </Button>

                        <Button
                          type="submit"
                          style={s('ml-4')}
                          disabled={isValidPercentage || isCanceled.disableButton}
                          loading={isUpdatingPending}
                        >
                          {/* <Button type="submit" style={s('ml-4')} onClick={() => {}}> */}
                          Save
                        </Button>
                      </Col>
                    </Row>
                    <Row style={s('mt-2')}>
                      <Col span={24} style={s('flex justify-end')}>
                        <FormMessage
                          message={'Allocation Total must be 100% !'}
                          visible={isValidPercentage}
                        />
                      </Col>
                    </Row>
                  </Form>
                )
              }}
            </FormProvider>
          </FormContainer>
        </ContentCard>
        <ContentCard style={s('mb-4')}>
          <SectionTitleBase title="Loans for transfer" style={s('mb-4')} />
          <Table
            columns={columns}
            data={allLoans}
            containerStyle={s('rounded-lg flex-1')}
            style={s('pb-0 m-0 bg-grey-lightest rounded-lg')}
            tableHeaderRowStyles={s(
              'border-solid border-0 border-b-1 border-grey-200 bg-grey-lightest'
            )}
          />
        </ContentCard>
      </Container>
    </>
  )
}

export default TransferDetails
