import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { normalizeError } from '@vega/services'

import { warehouseTransfersService } from 'apiService'

export const fetchWarehouseTransfers = createAsyncThunk(
  'warehouseTransfers/getTransfers',
  async ({ searchParams, pageIndex }, { rejectWithValue, signal }) => {
    try {
      const { searchTerm: q, filters = {}, sorting = {}, limit = 20 } = searchParams
      return await warehouseTransfersService.getTransfers(
        {
          q,
          filters,
          limit,
          sorting,
          start: limit * pageIndex,
        },
        signal
      )
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const fetchTransfer = createAsyncThunk(
  'warehouseTransfers/getTransfer',
  async (id, { rejectWithValue }) => {
    try {
      const funder = await warehouseTransfersService.getTransfer(id)
      return funder
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const createTransfer = createAsyncThunk(
  'warehouseTransfers/createTransfer',
  async (payload, { rejectWithValue }) => {
    try {
      return await warehouseTransfersService.createTransfer(payload)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const deleteTransfer = createAsyncThunk(
  'warehouseTransfers/deleteTransfer',
  async (id, { rejectWithValue }) => {
    try {
      await warehouseTransfersService.deleteTransfer(id)
      return {}
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const updateTransfer = createAsyncThunk(
  'warehouseTransfers/updateTransfer',
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      return await warehouseTransfersService.updateTransfer(id, payload)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const updateTransferStatus = createAsyncThunk(
  'warehouseTransfers/updateTransferStatus',
  async ({ id, status }, { rejectWithValue }) => {
    try {
      return await warehouseTransfersService.updateTransferStatus(id, status)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const warehouseTransfersAdapter = createEntityAdapter({
  selectId: (transfer) => transfer._id,
})

const initialState = warehouseTransfersAdapter.getInitialState()

const warehouseTransfersSlice = createSlice({
  name: 'warehouseTransfers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWarehouseTransfers.fulfilled, (state, action) => {
        const { items: funders, pagination } = action.payload
        warehouseTransfersAdapter.setAll(state, funders)
        state.total = pagination.total
      })
      .addCase(createTransfer.fulfilled, (state, action) => {
        warehouseTransfersAdapter.upsertOne(state, action.payload)
      })
      .addCase(fetchTransfer.fulfilled, (state, action) => {
        warehouseTransfersAdapter.upsertOne(state, action.payload)
      })
      .addCase(deleteTransfer.fulfilled, (state, action) => {
        warehouseTransfersAdapter.removeOne(state, action.payload)
      })
      .addCase(updateTransfer.fulfilled, (state, action) => {
        warehouseTransfersAdapter.upsertOne(state, action.payload)
      })
      .addCase(updateTransferStatus.fulfilled, (state, action) => {
        warehouseTransfersAdapter.upsertOne(state, action.payload)
      })
  },
})

const { reducer: warehouseTransfersReducer } = warehouseTransfersSlice

export { warehouseTransfersReducer }
