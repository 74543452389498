/* eslint-disable camelcase */
import { Switch, Route } from 'react-router-dom'
import React, { useState } from 'react'
import NavigationDrawer from 'features/NavigationDrawer'
import AppSwitcher from 'features/common/AppSwitcher'
import InactivityModal from '@vega/components/src/InactivityModal/InactivityModal'

import { ErrorPage, Toaster, useActivityTracker } from '@vega/components'
import { styled, s } from '@vega/styled'
import Warehouse from 'features/warehouse'
import CreateWarehouse from 'features/CreateWarehouse'
import EditWarehouse from 'features/EditWarehouse'

import { FunderOverview } from 'features/funder/Overview'
import { TransfersOverview } from 'features/warehouseTransfers/Overview'
import Create from 'features/funder/Create'
import Edit from 'features/funder/Edit'
import Details from 'features/funder/Details'
import { Overview } from 'features/homePage/Overview'
import { TransferNew } from 'features/warehouseTransfers/New'
import TransferDetails from 'features/warehouseTransfers/Details'

const Root = styled.div(s('flex flex-column h-auto'), s('bg-green-50'))
const AppContainer = styled.div(
  s('flex flex-row p-0 '),
  {
    'overflow-y': 'scroll',
    scrollbarWidth: 'none',
  },
  `
    ::-webkit-scrollbar {
      display: none;
    }
  `
)

const App = () => {
  const [userIsInactive, setUserIsInactive] = useState(false)

  useActivityTracker(() => {
    if (!userIsInactive) setUserIsInactive(true)
  })

  return (
    <Root>
      <AppSwitcher />

      <AppContainer>
        <NavigationDrawer />
        <div
          style={s('p-12 w-full', {
            height: '100vh',
            'overflow-y': 'scroll',
            backgroundColor: '#F1F1E8',
          })}
        >
          <Switch>
            <Route exact path="/" component={Overview} />

            <Route exact path="/warehouses" component={Overview} />
            <Route exact path="/warehouses/new" component={CreateWarehouse} />
            <Route path="/warehouses/:id/edit" component={EditWarehouse} />
            <Route path="/warehouses/:id" component={Warehouse} />

            <Route exact path="/funders" component={FunderOverview} />
            <Route exact path="/funders/new" component={Create} />
            <Route exact path="/funders/:id/edit" component={Edit} />
            <Route exact path="/funders/:id" component={Details} />
            <Route exact path="/transfers" component={TransfersOverview} />
            <Route exact path="/transfers/new" component={TransferNew} />
            <Route exact path="/transfers/:id" component={TransferDetails} />

            <Route path="/" component={ErrorPage} />
          </Switch>
        </div>
      </AppContainer>
      <Toaster />
      <InactivityModal isOpen={userIsInactive} setIsOpen={setUserIsInactive} />
    </Root>
  )
}

export default App
