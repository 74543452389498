import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTableFetching } from 'hooks'
import { styled, s } from '@vega/styled/v2'
import { Table } from '@vega/components/src/v2'
import { Pagination } from '@vega/components/src/v2/Table/Pagination'
import { SectionTitle as SectionTitleBase } from 'components/SectionTitle'
import { ContentCard as ContentCardBase } from 'components/ContentCard'
import { Rows, Columns } from './TableFormat'
import { ReactComponent as Search } from '@vega/components/src/assets/images/search.svg'
import OverlayDateRangePicker from 'components/OverlayDateRangePicker'
import { fetchUsers, selectAllUsers } from 'modules/user'
import { USER_ROLES } from '@vega/constants'
import { isNilOrEmpty } from '@solta/ramda-extra'
import { formatFullName } from 'utils/formatters'
import { Item } from '@react-stately/collections'
import { ReactComponent as ResetIcon } from '@vega/components/src/assets/images/refresh.svg'
import { Button as ButtonBase, Menu } from '@vega/components'
import {
  searchActivityLogs,
  selectTotal,
  selectActivityLogs,
  selectIsSearchingActivityLogs,
} from 'modules/activityLog'

const Button = styled(ButtonBase)(
  s(
    'bg-white border-solid border-1 border-grey-200 py-3 px-4 text-sm text-grey-600 rounded-lg'
  )
)

const ACTIVITY_LOG = 'activity-log'

const Container = styled.div({ marginBottom: 52 })
const ContentCard = styled(ContentCardBase)(s('p-0', { overflow: 'hidden' }))
const SectionTitle = styled(SectionTitleBase)(s('mb-4'))
const SearchIcon = styled(Search)(s('absolute ml-4'))

const SearchBarContainer = styled.div(
  s('relative flex items-center w-full h-full rounded-lg rounded-tr-none bg-green-50', {
    minHeight: '52px',
    whiteSpace: 'nowrap',
    outline: 'none',
  })
)

const SearchAndFiltersContainer = styled.div(
  s('flex my-4 justify-between', { gap: 16 })
)

const Input = styled.input(
  s(
    'pl-12 pr-4 pt-3 pb-3 w-full h-full border-solid border-1 border-grey-400 text-base rounded-lg text-grey-500',
    {
      backgroundcolor: 'white',
      '&:hover': s('border-1 border-primary', { cursor: 'pointer' }),
      ':focus-within': s('border-1 border-primary', {
        outline: '2px solid',
        outlineColor: s('text-primary').color,
      }),
    }
  )
)

const {
  BANKING_PORTFOLIO_ADMIN,
  CREDIT_ASSESSOR,
  LEAD_ASSESSOR,
  SUPER_ADMIN,
  TREASURY_ADMIN,
} = USER_ROLES

const filteredRoles = [
  BANKING_PORTFOLIO_ADMIN,
  CREDIT_ASSESSOR,
  LEAD_ASSESSOR,
  SUPER_ADMIN,
  TREASURY_ADMIN,
]

const mapUsersToDropdownFormat = (users = []) => {
  const userDropdownOptions = {}

  // eslint-disable-next-line no-return-assign
  users.forEach(({ id, fullName }) => (userDropdownOptions[id] = fullName))

  return userDropdownOptions
}

/* eslint-disable complexity */
const ActivityLog = ({ warehouseId = '', ...props }) => {
  const dispatch = useDispatch()
  const activityLogs = useSelector(selectActivityLogs) || []
  const isSearchingActivityLogs = useSelector(selectIsSearchingActivityLogs)
  const totalLogs = useSelector(selectTotal) || activityLogs.length
  const users = useSelector(selectAllUsers) ?? []

  const USERS = mapUsersToDropdownFormat(users)

  const fetchLogs = useCallback(
    ({ searchParams = {}, pageIndex = 0 }) => {
      dispatch(
        searchActivityLogs({
          searchParams,
          pageIndex,
          refId: warehouseId,
        })
      )
    },
    [dispatch, warehouseId]
  )

  const {
    searchProps: { searchParams },
    updateSearchProps,
    paginationProps,
    isFetchingData,
  } = useTableFetching(totalLogs, fetchLogs)

  const onTextSearch = ({ searchTerm }) => {
    updateSearchProps({
      filters: {
        description: searchTerm
      },
    })
  }

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value

    // regex to validate search text input - not allowing some special characters

    if (/[!"`'#%&,:;<>={}~$()*/\\?[\]^|]+/i.test(searchTerm) === true) {
      document.getElementById('searchTerm').style.outlineColor = 'red'
      return
    }
    document.getElementById('searchTerm').style.outlineColor = '#490B2D'

    if (!searchTerm || searchTerm.length === 0)
      onTextSearch({ searchTerm: '' })
    if (searchTerm.length > 2)
      onTextSearch({ searchTerm })
  }

  const onDatesChange = ([startDate, endDate]) => {
    if (!(startDate && endDate)) return
    updateSearchProps({
      filters: {
        startDate,
        endDate,
      },
    })
  }

  const onUserChange = (userId) => {
    updateSearchProps({
      filters: {
        userId
      },
    })
  }

  function handleResetFilters() {
    updateSearchProps({
      filters: {
        description: null,
        startDate: null,
        endDate: null,
        userId: null,
      },
      searchTerm: null,
    })
    document.getElementById('searchTerm').value = ''
  }

  useEffect(() => {
    fetchLogs({ searchParams, pageIndex: 0 })
  }, [dispatch, fetchLogs, searchParams])

  useEffect(() => {
    dispatch(fetchUsers({ searchParams: { filters: { role: filteredRoles } } }))
  }, [dispatch])

  const data = Rows({
    activityLogs,
  })
  const columns = Columns()

  // TODO: extract out a user select component
  const displayUserFullName = (newValue) => {
    if (isNilOrEmpty(newValue)) return null
    const { firstName, lastName } = users.find(({ id }) => id === newValue) || {}
    return formatFullName({ firstName, lastName })
  }

  return (
    <Container id={ACTIVITY_LOG} {...props} style={s('mt-12')}>
      <SectionTitle title="Activity Log" />

      <SearchAndFiltersContainer>
        <SearchBarContainer>
          <SearchIcon height={20} width={20} />
          <Input
            placeholder="Search action"
            onChange={handleSearchChange}
            name="searchTerm"
            id="searchTerm"
            type="search"
          />
        </SearchBarContainer>

        <OverlayDateRangePicker
          onChange={onDatesChange}
        />

        <Menu
          aria-label="Associated with"
          displayTextSelector={displayUserFullName}
          label="Whom?"
          onAction={(userId) => onUserChange(userId)}
          placeholder="Whom?"
          selectedValue={searchParams?.filters?.userId}
          style={s('w-16')}
        >
          {Object.entries(USERS).map(([key, value]) => (
            <Item key={key}>{value}</Item>
          ))}
        </Menu>

        <Button
          StartIcon={<ResetIcon style={s('w-1 h-1 text-grey-400 mr-1')} />}
          onClick={handleResetFilters}
        >
          Reset
        </Button>
      </SearchAndFiltersContainer>


      <ContentCard>
        <Table
          style={s('m-0')}
          tableHeight={550}
          columns={columns}
          data={data}
          loading={isSearchingActivityLogs || isFetchingData}
          fetchData={fetchLogs}
          loaderLabel="Fetching Activity Logs"
          useDefaultPagination={false}
        />
      </ContentCard>

      <Pagination {...paginationProps} />
    </Container>
  )
}

export { ActivityLog }
