export const statusForWarehouse = (warehouse) => {
  return warehouse.currentState.state
}

export const totalCapacityForWarehouse = (warehouse) =>
  warehouse.tranches.reduce((acc, curr) => acc + curr.capacity, 0)

export const isActive = (warehouse) => {
  if (!warehouse) {
    return false
  }

  return warehouse.activeFrom !== undefined
}

export const currentStatusForWarehouse = (warehouse) => {
  return warehouse.currentState.state
}

export const pendingStatusForWarehouse = (warehouse) => {
  return warehouse.pendingState?.changeTo
}
